import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './LeadLandlord.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Table, Row, Col } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'
import { saveAs } from 'file-saver';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'


function LeadLandlord() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});
    const [selectedLead, setselectedLead] = useState(null);
    const handleOpenModal = leadId => {
        setselectedLead(leadId);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleOpenModalEdit = leadId => {
        setselectedLead(leadId);
        setShowModalEdit(true);
    };
    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    };

    // get the current month so that i can filter out the total income
    const months = Array.from({ length: 12 }, (_, i) => {
        const month = new Date(new Date().getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });
    // Get the current month automatically
    const currentDate = new Date();
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];

    // get the months till today
    const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
        const month = new Date(currentDate.getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });

    // get the years
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;

    const years = Array.from({ length: 15 }, (_, i) => startYear - i);

    const [selectedMonth, setSelectedMonth] = useState('')
    const [selectedYear, setSelectedYear] = useState('')

    const [currentLead, setCurrentLead] = useState([])
    const handleOpenModalView = leadId => {
        setselectedLead(leadId);
        serverUrl.get(`/landlord/landlord-leads/?id=${leadId}`, config)
            .then((res) => {
                setCurrentLead(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
        setShowModalView(true);
    };
    const handleCloseModalView = () => {
        setShowModalView(false);
    };
    const [landlordApartment, setLandlordApartment] = useState([])
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};
    useEffect(() => {
        setIsLoading(true)
        serverUrl.get(`/landlord/landlord-leads/`, config)
            .then((res) => {
                setLandlordApartment(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    function handleEditApartment(e) {
        e.preventDefault();
        const accessToken = localStorage.getItem("authTokens");
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        const dataHouse = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            location: formData.location,
            service: formData.service,
            status: formData.status,
            feedback: formData.feedback,
            feedback_commentary: formData.feedback_commentary,

        };
        try {
            serverUrl.patch(`/landlord/landlord-leads/${selectedLead}/`, dataHouse, config)
                .then((res) => {
                    setShowModalEdit(false);
                    serverUrl.get(`/landlord/landlord-leads/`, config)
                        .then((res) => {
                            setLandlordApartment(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Successfully Updated The Lead!');
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        toast.error('Failed to create unit kindly try again')
                        console.log('Error while adding a new house' + error.message);
                    } else {
                        toast.error('Failed to create unit kindly try again')
                        console.log('Unexpected error: ' + error.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        location: "",
        service: "",
        status: "",
        feedback: "",
        feedback_commentary: "",
    });
    const [showDateFilterModal, setShowDateFilterModal] = useState(false);

    const [selectedDateFilter, setSelectedDateFilter] = useState("");

    // modal for date range
    const handleShowDateFilterModal = () => {
        setShowDateFilterModal(true);
    };
    const handleCloseDateFilterModal = () => {
        setShowDateFilterModal(false);
    };

    const [specificDate, setSpecificDate] = useState('')
    const [todayDateSpecific, setTodayDateSpecific] = useState('')

    // modal for specific date
    const [showDateFilterModalSpecific, setShowDateFilterModalSpecific] = useState(false);

    const handleShowDateFilterModalSpecific = () => {
        setShowDateFilterModalSpecific(true);
    };
    const handleCloseDateFilterModalSpecific = () => {
        setShowDateFilterModalSpecific(false);
    };

    // modal for month
    const [showMonthFilter, setShowMonthFilter] = useState(false);

    const handleShowMonthFilterOpen = () => {
        setShowMonthFilter(true);
    };
    const handleShowMonthFilterClose = () => {
        setShowMonthFilter(false);
    };

    // modal for year
    const [showYearFilter, setShowYearFilter] = useState(false);

    const handleShowYearFilterOpen = () => {
        setShowYearFilter(true);
    };
    const handleShowYearFilterClose = () => {
        setShowYearFilter(false);
    };
    const handleDateFilterChange = (selectedOption) => {
        setSelectedDateFilter(selectedOption);
        if (selectedOption === "today") {
            const today = new Date();
            setSpecificDate(today);
            handleFilterStartDate('');
            handleFilterEndDate('');
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
        else if (selectedOption === 'allLeads') {
            handleFilterStartDate('');
            handleFilterEndDate('');
            setSpecificDate(null)
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
        else if (selectedOption === 'specificDate') {
            handleShowDateFilterModalSpecific();
            handleFilterStartDate('');
            handleFilterEndDate('');
            setSpecificDate(null)
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
        else if (selectedOption === 'month') {
            handleShowMonthFilterOpen();
            handleFilterStartDate('');
            handleFilterEndDate('');
            setSpecificDate(null)
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
        else if (selectedOption === 'year') {
            handleShowYearFilterOpen();
            handleFilterStartDate('');
            handleFilterEndDate('');
            setSpecificDate(null)
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
        else {
            handleShowDateFilterModal();
            handleFilterStartDate('');
            handleFilterEndDate('');
            setSpecificDate(null)
            setTodayDateSpecific(null)
            setStartDate(null);
            setEndDate(null);
        }
    };


    const handleMonthButtonClick = (month) => {
        setSelectedMonth(month);
        setStartDate(null);
        setEndDate(null);
        handleFilterStartDate('');
        handleFilterEndDate('');
        setSpecificDate('');
        setSpecificDate(null)
        setTodayDateSpecific(null)

    };
    const handleYearButtonClick = (year) => {
        setSelectedYear(year);
        setStartDate(null);
        setEndDate(null);
    };

    useEffect(() => {
        serverUrl.get(`/landlord/landlord-leads/?id=${selectedLead}`, config)
            .then((res) => {
                setFormData({
                    name: res.data.results[0].name || '',
                    email: res.data.results[0].email || '',
                    phone: res.data.results[0].phone || '',
                    location: res.data.results[0].location || '',
                    service: res.data.results[0].service || '',
                    status: res.data.results[0].status || '',
                    feedback: res.data.results[0].feedback || '',
                    feedback_commentary: res.data.results[0].feedback_commentary || '',
                });

            })
            .catch((error) => {
                console.log(error)
            })

    }, [selectedLead]);
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const handleDeleteApartment = () => {
        if (selectedLead) {
            serverUrl.delete(`/landlord/landlord-leads/${selectedLead}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/landlord-leads/`, config)
                        .then((res) => {
                            setLandlordApartment(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Succesfully deleted the lead')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);
    const uniquePropertyLocation = new Set();
    const uniqueLocation = landlordApartment.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(0);
    };
    const handleFilterSpecificDate = (date) => {
        setTodayDateSpecific(date);
        setSearchQuery("");
        setCurrentPage(0);
        setStartDate(null);
        setEndDate(null);
    };
    const handleFilterStartDate = (date) => {
        setStartDate(date);
        setSearchQuery("");
        setCurrentPage(0);
    };
    const handleFilterEndDate = (date) => {
        setEndDate(date);
        setSearchQuery("");
        setCurrentPage(0);
    };
    const clearFilters = () => {
        setSearchQuery("");
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(0);
        setSpecificDate(null)
        setTodayDateSpecific(null)
    };
    const filteredData = landlordApartment.filter((item) => {
        const dateFilter =
            (!startDate || new Date(item.updated_at) >= startDate) &&
            (!endDate || new Date(item.updated_at) <= endDate);

        // filter by today
        const itemDate = new Date(item.updated_at);
        const specificDateMidnight = new Date(specificDate);
        specificDateMidnight.setHours(0, 0, 0, 0);

        const dateFilterSpecific = (!specificDate || itemDate.toDateString() === specificDateMidnight.toDateString());

        // filter by specific date
        const todayDateMidnight = new Date(todayDateSpecific);
        todayDateMidnight.setHours(0, 0, 0, 0);

        const dateFilterToday = (!todayDateSpecific || itemDate.toDateString() === todayDateMidnight.toDateString());

        // filter by month

        const itemMonth = new Date(item.updated_at).toLocaleString('default', { month: 'long' }).toLowerCase();

        const monthFilter = (!selectedMonth || itemMonth === selectedMonth.toLowerCase());

        // filter by year
        const itemYear = new Date(item.updated_at).getFullYear();
        const yearFilter = (!selectedYear || itemYear === selectedYear);



        const searchableFields = Object.values(item).join(" ").toLowerCase();
        const searchFilter = searchableFields.includes(searchQuery.toLowerCase());

        return dateFilter && searchFilter && dateFilterSpecific && dateFilterToday && monthFilter && yearFilter;
    });
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);
    const sortedItems = displayedItems.slice().sort((a, b) => {
        const dateA = a.updated_at ? new Date(a.updated_at) : new Date(0);
        const dateB = b.updated_at ? new Date(b.updated_at) : new Date(0);
        return dateB - dateA;
    });
    const exportCSV = () => {
        console.log('here')
        const csvData = [
            ['Name', 'Email', 'Phone Number', 'Location', 'Service', 'Status', 'Feedback', 'Feedback Commentary', 'Date Submited', 'Date Updated'],
            ...landlordApartment.map((item) => [item.name, item.email, item.phone, item.location, item.service, item.status, item.feedback, item.feedback_commentary,
            new Date(item.created_at).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numberic'
            }), new Date(item.updated_at).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numberic'
            })]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Landlord Leads.csv');
    };
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }
            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }
            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };
        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    function toCamelCase(str) {
        if (!str) {
            return '';
        }
        return str.replace(/[-_]+(.)?/g, (_, c) => (c ? ` ${c.toUpperCase()}` : ''));
    }
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (<SideBar />)}
            <div className='property leadLanlord'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>LEADS FOR INTRESTED LANDLORD</p>
                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>
                        <div>
                            <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel' ></i></Button>
                            {/* <Button className='btn btn-danger text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                        </div>
                        <div>
                            <Form.Select
                                className=''
                                onChange={(e) => handleDateFilterChange(e.target.value)}
                            >
                                <option value="" disabled selected>
                                    Filter by Date <i className='bi bi-calendar'></i>
                                </option>
                                <option value="allLeads">All Leads</option>
                                <option value="today">Today</option>
                                <option value="specificDate">Specific Date</option>
                                <option value="specificRange">Specific Range</option>
                                <option value="month">Month</option>
                                <option value="year">Year</option>
                            </Form.Select>
                            {/* specific date modal */}
                            <Modal show={showDateFilterModalSpecific} onHide={handleCloseDateFilterModalSpecific}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Select specific date</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="date-filter">
                                        <p className="date-label">Date:</p>
                                        <Form.Control type="date" placeholder="Specific Date" onChange={(e) => handleFilterSpecificDate(new Date(e.target.value))} />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseDateFilterModalSpecific}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* specific date range */}
                            <Modal show={showDateFilterModal} onHide={handleCloseDateFilterModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Date Filters</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="date-filter">
                                        <p className="date-label">From:</p>
                                        <Form.Control type="date" placeholder="Start Date" onChange={(e) => handleFilterStartDate(new Date(e.target.value))} />
                                        <span className="date-separator">To:</span>
                                        <Form.Control type="date" placeholder="End Date" onChange={(e) => handleFilterEndDate(new Date(e.target.value))} />
                                        <Button className='btn btn-secondary btn-sm text-white clear-filters' onClick={clearFilters}>Clear Filters</Button>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" className='mt-3' onClick={handleCloseDateFilterModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* specific month */}
                            <Modal show={showMonthFilter} onHide={handleShowMonthFilterClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Select month</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="date-filter">
                                        {monthsTillToday.map((month) => (
                                            <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                                                <Button className='btn btn-success text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                                                    {month}</Button>
                                            </Col>
                                        ))}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleShowMonthFilterClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* specific year */}
                            <Modal show={showYearFilter} onHide={handleShowYearFilterClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Select Year</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="date-filter">
                                        {years.map((year) => (
                                            <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                                                <Button className='btn btn-success text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleYearButtonClick(year)}>
                                                    {year}</Button>
                                            </Col>
                                        ))}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleShowYearFilterClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div>
                            <Form className="searchBar w-100">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                            </Form>
                        </div>
                    </div>

                    <Table className='table mt-2 DisplayDesktop'>
                        <thead className='underline'>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Feedback</th>
                            <th className='w-25'>Service</th>
                            <th>Status</th>
                            <th>Date Updated</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {sortedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.name}</td>
                                    <td>{lead.phone}</td>
                                    <td>{lead.location}</td>
                                    <td style={{ width: "17%" }}>{lead.service}</td>
                                    {lead.status === 'pending' ?
                                        <td className='text-capitalize text-success fw-bold'>{lead.status}</td>
                                        :
                                        <td className='text-capitalize text-warning fw-bold'>{lead.status}</td>
                                    }
                                    {/* {lead.feedback === 'intrested' ?
                                        <td className='text-capitalize text-success fw-bold'>{toCamelCase(lead.feedback)}</td>
                                        :
                                        <td className='text-capitalize text-danger fw-bold'>{toCamelCase(lead.feedback)}</td>
                                    } */}
                                    <td>{new Date(lead.updated_at).toLocaleDateString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: '2-digit'
                                    })}
                                    </td>
                                    <td>
                                        <Button className='btn btn-sm btn-danger text-white me-2' onClick={() => handleOpenModal(lead.id)}
                                        >
                                            <i className='bi bi-trash'></i>
                                        </Button>
                                        <Button className='btn btn-sm btn-warning text-white me-2' onClick={() => handleOpenModalEdit(lead.id)}>
                                            <i className='bi bi-pen'></i>
                                        </Button>
                                        <Button className='btn btn-sm btn-success text-white me-2' onClick={() => handleOpenModalView(lead.id)}>
                                            <i className='bi bi-eye'></i>
                                        </Button>

                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>



                    <Table className='table mt-2 DisplayMobile'>
                        <thead className='underline'>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th className='w-25'>Service</th>
                            <th>Status</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {sortedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.name}</td>
                                    <td>{lead.phone}</td>
                                    <td style={{ width: "17%" }}>{lead.service}</td>
                                    {lead.status === 'pending' ?
                                        <td className='text-capitalize text-success fw-bold'>{lead.status}</td>
                                        :
                                        <td className='text-capitalize text-warning fw-bold'>{lead.status}</td>
                                    }
                                  
                                    <td>
                                        <Button className='btn btn-sm btn-danger text-white me-2' onClick={() => handleOpenModal(lead.id)}
                                        >
                                            <i className='bi bi-trash'></i>
                                        </Button>
                                        <Button className='btn btn-sm btn-warning text-white me-2' onClick={() => handleOpenModalEdit(lead.id)}>
                                            <i className='bi bi-pen'></i>
                                        </Button>
                                        <Button className='btn btn-sm btn-success text-white me-2' onClick={() => handleOpenModalView(lead.id)}>
                                            <i className='bi bi-eye'></i>
                                        </Button>

                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Lead</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Do you want to delete this lead?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                Delete
                            </button>
                            <button className='btn btn-secondary' onClick={handleCloseModal}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Landlord's Lead Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                            <Form onSubmit={handleEditApartment}>
                                <p className='lableName'>Landlord's Name</p>

                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.name}
                                        name="name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Landlord's Email</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.email}
                                        name="email"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Landlord's Phone Number</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="number"
                                        value={formData.phone}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.phone}
                                        name="phone"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Landlord's Location</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.location}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.location}
                                        name="location"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Service Intrested in</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.service}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.service}
                                        name="service"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Follow up status</p>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>

                                    <Form.Select
                                        size='sm'
                                        className="input-field"
                                        value={formData.status}
                                        onChange={handleChangeEdit}
                                        name='status'
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="assisted">Assisted</option>
                                    </Form.Select>
                                </div>

                                <p className='lableName'>Landlord's feedback</p>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>

                                    <Form.Select
                                        size='sm'
                                        className="input-field"
                                        value={formData.feedback}
                                        onChange={handleChangeEdit}
                                        name='feedback'
                                    >
                                        <option value="intrested">Intrested</option>
                                        <option value="not_intrested">Not Intrested</option>
                                    </Form.Select>
                                </div>

                                <p className='lableName'>Feedback Commentary</p>

                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <textarea
                                        className="input-field"
                                        type="text"
                                        name="feedback_commentary"
                                        value={formData.feedback_commentary}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.feedback_commentary}
                                        required

                                        style={{ height: "330px" }}
                                    />
                                </div>

                                <Button variant="primary" type="submit" className="nextBtn mt-3">
                                    Save
                                </Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>

                            <button className='btn btn-secondary' onClick={handleCloseModalEdit}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showModalView} onHide={handleCloseModalView}>
                        <Modal.Header closeButton>
                            <Modal.Title>Landlord's Lead Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                            <p className='lableName'>Landlord's Name</p>

                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    value={currentLead.name}
                                />
                            </div>

                            <p className='lableName'>Landlord's Email</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    value={currentLead.email}
                                />
                            </div>

                            <p className='lableName'>Landlord's Phone Number</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    value={currentLead.phone}

                                />
                            </div>

                            <p className='lableName'>Landlord's Location</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    value={currentLead.location}

                                />
                            </div>

                            <p className='lableName'>Service Intrested in</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    value={currentLead.service}

                                />
                            </div>

                            <p className='lableName'>Follow up status</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field text-capitalize"
                                    value={currentLead.status}

                                />
                            </div>

                            <p className='lableName'>Landlord's feedback</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field text-capitalize"
                                    value={toCamelCase(currentLead.feedback)}
                                />
                            </div>

                            <p className='lableName'>Feedback Commentary</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <textarea
                                    className="input-field"
                                    value={currentLead.feedback_commentary}
                                    style={{ height: "300px" }}

                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={handleCloseModalView}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>
            </div>
        </div>)
}

export default LeadLandlord




