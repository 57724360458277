import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Booking.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Table } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import loaderImage from '../../assets/img/logo.png'

function Booking() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const [isLoading, setIsLoading] = useState(true)
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const [bookings, setBookings] = useState([])
    useEffect(() => {
        setIsLoading(true);
        serverUrl.get(`/landlord/booking/`, config)
            .then((res) => {
                setBookings(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null);
    };
    const filteredData = bookings.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const exportCSV = () => {
        const csvData = [
            ['Viewer Name', 'Viewer Phone', 'Viewer Email', 'Meeting Date', 'Agent Name', 'Agent Email', 'Agent Phone', 'Apartment', 'House', 'Status', 'Feedback,'],
            ...bookings.map((item) => [item.user_name, item.user_phone, item.user_email, item.meeting_date, item.agent_name, item.agent_email, item.agent_phone,
            item.apartment_name, item.house_name, item.status, item.feedback]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Bookings.csv');
    };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of bookings) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [bookings]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setselectedBooking] = useState(null);

    const handleOpenModal = bookingId => {
        setselectedBooking(bookingId);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDeleteApartment = () => {
        if (selectedBooking) {
            serverUrl.delete(`/landlord/booking/${selectedBooking}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/booking/`, config)
                        .then((res) => {
                            setBookings(res.data.results)
                            setShowModal(false);

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Succesfully deleted the Viewing')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const [showModalEdit, setShowModalEdit] = useState(false);

    const [currentBooking, setCurrentBooking] = useState([])

    const handleOpenModalEdit = leadId => {
        setselectedBooking(leadId);
        serverUrl.get(`/landlord/booking/${leadId}/`, config)
            .then((res) => {
                console.log(res)
                setCurrentBooking(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
        setShowModalEdit(true);
    };
    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const [showModalView, setShowModalView] = useState(false);

    const handleOpenModalView = leadId => {
        setselectedBooking(leadId);

        setShowModalView(true);
    };
    const handleCloseModalView = () => {
        setShowModalView(false);
    };

    const [formData, setFormData] = useState({
        user_name: "",
        user_phone: "",
        user_email: "",
        meeting_date: "",
        agent_name: "",
        agent_phone: "",
        agent_email: "",
        apartment_name: "",
        house_name: "",
        status: "",
        feedback: "",
        starting_time: "",
        duration: "",


    });

    useEffect(() => {
        serverUrl.get(`/landlord/booking/?id=${selectedBooking}`, config)
            .then((res) => {
                setFormData({
                    user_name: res.data.results[0].user_name || '',
                    user_phone: res.data.results[0].user_phone || '',
                    user_email: res.data.results[0].user_email || '',
                    meeting_date: res.data.results[0].meeting_date || '',
                    agent_name: res.data.results[0].agent_name || '',
                    agent_phone: res.data.results[0].agent_phone || '',
                    agent_email: res.data.results[0].agent_email || '',
                    apartment_name: res.data.results[0].apartment_name || '',
                    house_name: res.data.results[0].house_name || '',
                    status: res.data.results[0].status || '',
                    feedback: res.data.results[0].feedback || '',
                    starting_time: res.data.results[0].starting_time || '',
                    duration: res.data.results[0].duration || '',


                });

            })
            .catch((error) => {
                console.log(error)
            })

    }, [selectedBooking]);
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }


    function handleEditApartment(e) {
        e.preventDefault();
        const accessToken = localStorage.getItem("authTokens");
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        const dataHouse = {
            user_name: formData.user_name,
            user_phone: formData.user_phone,
            user_email: formData.user_email,
            meeting_date: formData.meeting_date,
            agent_name: formData.agent_name,
            agent_phone: formData.agent_phone,
            agent_email: formData.agent_email,
            apartment_name: formData.apartment_name,
            house_name: formData.house_name,
            status: formData.status,
            feedback: formData.feedback,
            starting_time: (() => {
                if (formData.starting_time) {
                    const startTime = new Date(`2023-11-28 ${formData.starting_time}`);

                    const formatter = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        millisecond: 'numeric',
                        hour12: false,
                    });

                    return formatter.format(startTime);
                }
                return null;
            })(),
            duration: formData.duration,
            end_time: (() => {
                if (formData.starting_time && formData.duration) {
                    const startTime = new Date(`2023-11-28 ${formData.starting_time}`);
                    const durationMinutes = parseInt(formData.duration, 10);

                    if (!isNaN(durationMinutes)) {
                        const endTime = new Date(startTime.getTime() + durationMinutes * 60000);

                        const formatter = new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            millisecond: 'numeric',
                            hour12: false,
                        });

                        return formatter.format(endTime);
                    }
                }
                return null;
            })(),
        };
        try {
            serverUrl.patch(`/landlord/booking/${selectedBooking}/`, dataHouse, config)
                .then((res) => {
                    setShowModalView(false);
                    serverUrl.get(`/landlord/booking/`, config)
                        .then((res) => {
                            setBookings(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Successfully Updated The Viewing!');
                })
                .catch((error) => {
                    console.log('Unexpected error: ' + error.message);

                });
        } catch (error) {
            console.log(error);
        }
    }




    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>BOOKINGS FOR VIEWING</p>

                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>

                        <div>
                            <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                            {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>
                    </div>
                    <Table className='table mt-2'>
                        <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                            <th>Viewer's Name</th>
                            <th>Viewer's Phone</th>
                            <th>Viewing Date</th>
                            <th>Starting Time</th>
                            <th>End Time</th>
                            <th>Property</th>
                            <th>Unit</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>

                            {displayedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.user_name}</td>
                                    <td>{lead.user_phone}</td>
                                    <td>{lead.meeting_date}</td>
                                    <td>{lead.starting_time}</td>
                                    <td>{lead.end_time}</td>
                                    <td>{lead.apartment_name}</td>
                                    <td>{lead.house_name}</td>
                                    {lead.status === 'incomplete' ?
                                        <td className='text-danger fw-bold text-capitalize'>{lead.status}</td>
                                        :
                                        <td className='text-success fw-bold text-capitalize'>{lead.status}</td>

                                    }
                                    <td>
                                        <Button className='btn btn-sm btn-success text-white'
                                            onClick={() => handleOpenModalView(lead.id)}>

                                            <i className='bi bi-pen  me-2'></i>Edit
                                        </Button>
                                    </td>
                                    <td>
                                        <Button className='btn btn-sm btn-warning  text-white'
                                            onClick={() => handleOpenModalEdit(lead.id)}>
                                            <i className='bi bi-eye  me-2'></i>View
                                        </Button>
                                    </td>
                                    <td>
                                        <Button className='btn btn-sm btn-danger text-white'
                                            onClick={() => handleOpenModal(lead.id)}>
                                            <i className='bi bi-trash  me-2'></i>Delete
                                        </Button>
                                    </td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>

                    {/* delete the booking */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Booking</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Do you want to delete this viewing?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                Delete
                            </button>
                            <button className='btn btn-secondary' onClick={handleCloseModal}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* view the booking */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Viewings Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                            <p className='lableName'>Viewer's Name</p>

                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.user_name}

                                    placeholder={currentBooking.user_name}
                                    name="user_name"
                                    required
                                />
                            </div>

                            <p className='lableName'>Viewer's Phone</p>

                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.user_phone}
                                    placeholder={currentBooking.user_phone}
                                    name="user_phone"
                                    required
                                />
                            </div>

                            <p className='lableName'>Viewer's Email</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="email"
                                    value={currentBooking.user_email}
                                    placeholder={currentBooking.user_email}
                                    name="user_email"
                                    required
                                />
                            </div>

                            <p className='lableName'>Meeting Date</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="date"
                                    value={currentBooking.meeting_date}

                                    placeholder={currentBooking.meeting_date}
                                    name="meeting_date"
                                    required
                                />
                            </div>

                            
                            <p className='lableName'>Starting Time</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="time"
                                    value={currentBooking.starting_time}

                                    placeholder={currentBooking.starting_time}
                                    name="starting_time"
                                    required
                                />
                            </div>

                            <p className='lableName'>Duration in min(s)</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="number"
                                    value={currentBooking.duration}

                                    placeholder={currentBooking.duration}
                                    name="duration"
                                    required
                                />
                            </div>

                            <p className='lableName'>Ending Time</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="time"
                                    value={currentBooking.end_time}

                                    placeholder={currentBooking.end_time}
                                    name="end_time"
                                    required
                                />
                            </div>

                            <p className='lableName'>Agent's Name</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.agent_name}
                                    placeholder={currentBooking.agent_name}
                                    name="agent_name"
                                    required
                                />
                            </div>

                            <p className='lableName'>Agent's Phone</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.agent_phone}
                                    placeholder={currentBooking.agent_phone}
                                    name="agent_phone"
                                    required
                                />
                            </div>

                            <p className='lableName'>Agent's Email</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.agent_email}
                                    placeholder={currentBooking.agent_email}
                                    name="agent_email"
                                    required
                                />
                            </div>


                            <p className='lableName'>Apartment</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.apartment_name}
                                    placeholder={currentBooking.apartment_name}
                                    name="apartment_name"
                                    required
                                />
                            </div>

                            <p className='lableName'>House</p>
                            <div className="input-icons mt-2">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    value={currentBooking.house_name}
                                    placeholder={currentBooking.house_name}
                                    name="house_name"
                                    required
                                />
                            </div>


                            <p className='lableName'>Status</p>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>

                                <Form.Select
                                    size='sm'
                                    className="input-field"
                                    value={currentBooking.status}

                                    name='status'
                                >
                                    <option value="">{currentBooking.status}</option>
                                </Form.Select>
                            </div>

                            <p className='lableName'>Feedback </p>

                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <textarea
                                    className="input-field"
                                    type="text"
                                    name="feedback"
                                    value={currentBooking.feedback}

                                    placeholder={currentBooking.feedback}
                                    required

                                    style={{ height: "330px" }}
                                />
                            </div>

                        </Modal.Body>
                        <Modal.Footer>

                            <button className='btn btn-secondary' onClick={handleCloseModalEdit}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* edit the booking */}
                    <Modal show={showModalView} onHide={handleCloseModalView}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit viewing details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                            <Form onSubmit={handleEditApartment}>
                                <p className='lableName'>Viewer's Name</p>

                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.user_name}
                                        placeholder={formData.user_name}
                                        onChange={handleChangeEdit}
                                        name="user_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Viewer's Phone</p>

                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.user_phone}
                                        placeholder={formData.user_phone}
                                        onChange={handleChangeEdit}
                                        name="user_phone"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Viewer's Email</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="email"
                                        value={formData.user_email}
                                        placeholder={formData.user_email}
                                        onChange={handleChangeEdit}
                                        name="user_email"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Meeting Date</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="date"
                                        value={formData.meeting_date}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.meeting_date}
                                        name="meeting_date"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Starting Time</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="time"
                                        value={formData.starting_time}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.starting_time}
                                        name="starting_time"
                                        required
                                    />
                                </div>
                                <p className='lableName'>Duration in min(s)</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="number"
                                        value={formData.duration}
                                        onChange={handleChangeEdit}
                                        placeholder={formData.duration}
                                        name="duration"
                                        required
                                    />
                                </div>
                                <p className='lableName'>Agent's Name</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.agent_name}
                                        placeholder={formData.agent_name}
                                        onChange={handleChangeEdit}
                                        name="agent_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Agent's Phone</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.agent_phone}
                                        placeholder={formData.agent_phone}
                                        onChange={handleChangeEdit}
                                        name="agent_phone"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Agent's Email</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="email"
                                        value={formData.agent_email}
                                        placeholder={formData.agent_email}
                                        onChange={handleChangeEdit}

                                        name="agent_email"
                                        required
                                    />
                                </div>


                                <p className='lableName'>Apartment</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.apartment_name}
                                        placeholder={formData.apartment_name}
                                        onChange={handleChangeEdit}

                                        name="apartment_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>House</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={formData.house_name}
                                        placeholder={formData.house_name}
                                        onChange={handleChangeEdit}

                                        name="house_name"
                                        required
                                    />
                                </div>


                                <p className='lableName'>Status</p>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>

                                    <Form.Select
                                        size='sm'
                                        className="input-field"
                                        value={formData.status}
                                        onChange={handleChangeEdit}

                                        name='status'
                                    >
                                        <option value="incomplete">Incomplete</option>
                                        <option value="complete">Complete</option>

                                    </Form.Select>
                                </div>

                                <p className='lableName'>Feedback </p>

                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <textarea
                                        className="input-field"
                                        type="text"
                                        name="feedback"
                                        value={formData.feedback}
                                        onChange={handleChangeEdit}

                                        placeholder={formData.feedback}
                                        required

                                        style={{ height: "330px" }}
                                    />
                                </div>

                                <Button variant="primary" type="submit" className="nextBtn mt-3">
                                    Save
                                </Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-secondary' onClick={handleCloseModalView}>
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>


                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${bookings.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>


            </div >

        </div >
    )
}

export default Booking




