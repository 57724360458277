import React, { useState } from 'react'
import './ApartmentImage.css'
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function ApartmentImage() {
  const navigate = useNavigate()
  // the image declaration
  const [image, setImage] = useState(null);

  //   button that does the uploading to while the input is hidden
  const handleButtonClick = () => {
    document.getElementById('imageUpload').click();
  };

  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    Apartmentimg: null
  });
  const handleChange = (event) => {
    // handling the selected image 

    const selectedImage = event.target.files[0];
    if (selectedImage && selectedImage.type.includes('image')) {
      setImage(selectedImage);
      setFormData((prevFormData) => ({
        ...prevFormData,
        Apartmentimg: selectedImage,
      }));
    } else {
      setImage(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        Apartmentimg: null,
      }));
      toast.error('Kindly select an image file!');
    }
  }
  // handling the form and how the data will be sent to the backend
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can link with the Apartment from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    const Apartment = localStorage.getItem('Apartment')

    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment', Apartment);
    data.append('image', formData.Apartmentimg);

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data
    try {
      serverUrl

        //send the Apartment image using the following endpoint
        .post("/landlord/apartment-images/", data, config)
        .then((res) => {
          console.log(res)
          toast.success('Successfully Added Apartment Image!');
          navigate('/add-house')

        })

    } catch (error) {
      toast.error(error);
    }

  }

  return (
    <div className="imageProperty">
      <h2>Property Image(s)</h2>

      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="input-icons">
          <Button className='imageButton' onClick={handleButtonClick}>
            Select Image For Apppartment
          </Button>
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleChange}
          />
          {image && (
            <div>
              <img src={URL.createObjectURL(image)} alt="preview" className='imageUploaded' />
            </div>
          )}
          <div className="bottomButtons">
            <Button type="button" className="skipButton">
              Skip
            </Button>

            <Button type="submit" className="nextButton">
              Next
            </Button>
          </div>
        </div>


      </Form>

    </div>
  )
}

export default ApartmentImage
