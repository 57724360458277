import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import './BulkUploads.scss'
import { Table, Button, Form, FormControl, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from 'react-toastify';

function BulkUploads() {

  // get user details so that you can link with the Apartment from the access tokens
  const accessToken = localStorage.getItem("authTokens");

  // decode the tokens so that you can get the user_id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;


  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // header's access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }


  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  const exportCSV = () => {
    const csvData = [
      ['Property Name', 'Location','County'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'property_template.csv');
  };

  // add csv template for property
  const [showModalProperty, setshowModalProperty] = useState(false);
  const handleOpenProperty = () => {
    setshowModalProperty(true);
  };

  const handlCloseProperty = () => {
    setshowModalProperty(false);
  };

  // get the house templates
  const exportCSVHouse = () => {
    const csvData = [
      ['Unit Number', 'Bedroom', 'Total Rooms', 'Bathroom', 'Rent', 'Deposit', 'Unit Type', 'Invoice Date', 'Token Prepaid Account Number'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'unit_template.csv');
  };

  // add csv template for house
  const [showModalHouse, setshowModalHouse] = useState(false);
  const handleOpenHouse = () => {
    setshowModalHouse(true);
  };

  const handleCloseHouse = () => {
    setshowModalHouse(false);
  };

  // get the house templates
  const exportCSVTenant = () => {
    const csvData = [
      ['Full Name', 'Email', 'Phone Number', 'Occupation', 'Marital Status', 'House Number'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'tenant_template.csv');
  };

  // add csv template for house
  const [showModalTenant, setshowModalTenant] = useState(false);
  const handleOpenTenant = () => {
    setshowModalTenant(true);
  };

  const handleCloseTenant = () => {
    setshowModalTenant(false);
  };

  // randomise the images for my properties
  const [backgroundImage, setBackgroundImage] = useState('');
  useEffect(() => {

    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }

      let randomNumber = Math.floor(Math.random() * 7) + 1;
      const invalidNumbers = [0, -1];
      while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 7) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/reports/reports${randomNumber}.jpg`;
      setBackgroundImage(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
    };

    getRandomImage();
  }, []);

  // get the  list of apartments owned by the user logged in 

  const [landlordApartment, setLandlordApartment] = useState([])
  const [landlordUserId, setLandlordUserId] = useState('')
  useEffect(() => {

    // get the landlord details that are linkexd to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        setLandlordUserId(landlordIds.toString())
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => res.data.results)
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);

          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    apartment_csv: null
  });

  const [selectedApartmentId, setSelectedApartmentId] = useState("");

  const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);

  function handleApartmentSelect(selectedApartmentId) {
    setSelectedApartmentId(selectedApartmentId)
    // get the houses to be listed when apartment is selected
    // serverUrl
    //   .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
    //   .then((res) => {
    //     // Filter houses with occupancy status "vacant"
    //     const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

    //     // Set the filtered vacant houses in the state
    //     setApartmentHouse(vacantHouses);
    //   })
    //   .catch((error) => {
    //     // Handle the error, e.g., display an error message to the user
    //     console.log("Error fetching apartment data:", error);
    //     // You can also update state to indicate the error, if needed
    //   });

  }

  const [selectedFile, setSelectedFile] = useState(null);


  // onchange functionality  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);


    if (file && file.type.includes('csv')) {
      setSelectedFile(file);
      setFormData((prevFormData) => ({
        ...prevFormData,
        apartment_csv: file,
      }));
    } else {
      setSelectedFile(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        apartment_csv: null,
      }));
    }
  }

  //upload the apartment csv to the backend 
  function handleUploadApartment(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('landlord', landlordUserId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }


    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload-property-template/`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully uploaded the csv of the property!')
          handlCloseProperty()
          setSelectedFile(null);
          window.location.reload()
          // console.log(res.data)
          // Update the properties to see the new data
          // setLandlordApartment((prevApartments) => [...prevApartments, res.data]);

        })
        .catch((error) => {
          toast.error(error.message)
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }



  //upload the tenant csv to the backend 
  function handleUploadTenant(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment_id', selectedApartmentId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }

    console.log(formData.apartment_csv.name)

    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload_tenant/?apartment_id=${selectedApartmentId}`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully Uploaded the tenants')
          handleCloseTenant()
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Request failed:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }

  // upload the house csv to the backend
  function handleUploadHouse(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment_id', selectedApartmentId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload_houses/?apartment_id=${selectedApartmentId}`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully Uploaded the units')
          handleCloseHouse()
          setSelectedFile(null);
          window.reload.location()
        })
        .catch((error) => {
          toast.success('Sorry an error occured while uploading the csv')
          console.error("Request failed:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }

  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);


  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };
  const sortedData = landlordApartment.sort((a, b) => {
    const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === 'asc') {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(' ').toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='bulk'>

        {/* top display */}
        <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
          <p className='properties'>BULK UPLOADS</p>

        </div>


        {/* property upload */}
        <div className="tasks mt-4">
          <h2> Property Upload </h2>

          <div className="d-flex justify-content-between">
            <div>
              <Button className="btn btn-success btn-sm text-white me-3" onClick={handleOpenProperty} >
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-warning btn-sm text-white" onClick={exportCSV} >
                Download Template <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  Property Name<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  County<i className="bi bi-chevron-expand"></i>
                </th>

                <th>
                  Location<i className="bi bi-chevron-expand"></i>
                </th>
            

              </tr>
            </thead>
            <tbody>
              {displayedItems.map((landlord) => (
                <tr>
                  <td>{landlord.name}</td>
                  <td>{landlord.county}</td>
                  <td>{landlord.location}</td>
                </tr>

              ))}

            </tbody>

          </Table>

          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>
          </div>

          {/* Modal upload the template */}
          <Modal show={showModalProperty} onHide={handlCloseProperty}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Property CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadApartment}>
                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Property CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>

          </Modal>
        </div>

        {/* unit upload */}
        <div className="tasks mt-4">
          <h2> Units Upload </h2>

          <div className="d-flex justify-content-between">
            <div>
              <Button className="btn btn-success text-white me-3" onClick={handleOpenHouse} >
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-warning text-white" onClick={exportCSVHouse} >
                Download Template <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  Unit Number<i className="bi bi-chevron-expand"></i>
                </th>
                <th >
                  Rent<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Due Date<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Unit Type<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  No of bedrooms<i className="bi bi-chevron-expand"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>

          </Table>

          {/* Modal upload the template  for house*/}
          <Modal show={showModalHouse} onHide={handleCloseHouse}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Unit CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadHouse}>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedApartmentHouse}
                  onChange={(e) => handleApartmentSelect(e.target.value)}

                >
                  <option value="">Select Apartment</option>
                  {landlordApartment.map((landlord) => (
                    <option key={landlord.id} value={landlord.id}>
                      {landlord.name}
                    </option>
                  ))}
                </Form.Select>


                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Unit CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>
         
          </Modal>

        </div>

      </div>
    </>
  )
}

export default BulkUploads