import React, { useState, useEffect,useMemo } from "react";
import "./UtilitiesReport.scss";
import { useParams } from "react-router-dom";
import {
  Button,
  Table,
  Form,
  FormControl,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";

// auth imports
import { serverUrl } from "../../../serverUrl";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

function UtilitiesReport(Apartmentid) {
  const [isLoading, setIsLoading] = useState(false);

  // getting the id and finding the apparment details
  const { id } = useParams();

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = useMemo(() => {
    return {
        headers: { Authorization: `Bearer ${access}` }
    };  
},[access])

  const [selectedHouses, setSelectedHouses] = useState([]);

  // the filter to work for the month and years

  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString("default", { month: "long" });
  });
  // Get the current month automatically
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;

  const years = Array.from({ length: 21 }, (_, i) => startYear - i);

  // display 12 churches per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);

  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };

  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);

  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("");
  const [yearFilterActive, setYearFilterActive] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [setSelectedMonth] = useState("");
  const [setSelectedYear] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    if (yearFilterActive) {
      localStorage.removeItem("yearUtilities");
    }
    localStorage.setItem("monthUtilities", month);
    setShowModal(false);
    window.location.reload();
  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
    if (monthFilterActive) {
      localStorage.removeItem("monthUtilities");
    }

    localStorage.setItem("yearUtilities", year);
    setShowModal(false);
    window.location.reload();
  };

  useEffect(() => {
    // get if the filter is set
    let setMonthFilter = localStorage.getItem("monthUtilities");
    setMonthFilterActive(setMonthFilter);

    let setYearFilter = localStorage.getItem("yearUtilities");
    setYearFilterActive(setYearFilter);

    if (setYearFilter) {
      serverUrl
        .get(
          `/tenant/generate_annual_income_report/?apartment_id=${id}&year=${setYearFilter}&income_category=expense`,
          config
        )
        .then((res) => {
          setSelectedHouses(res.data.incomeList);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (!setMonthFilter) {
        // get the houses linked to the apartment
        serverUrl
          .get(
            `/tenant/generate_month_income_report/?apartment_id=${id}&month=${currentMonth}&year=${currentYear}&income_category=expense`,
            config
          )
          .then((res) => {
            setSelectedHouses(res.data.incomeList);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        serverUrl
          .get(
            `/tenant/generate_month_income_report/?apartment_id=${id}&month=${setMonthFilter}&year=${currentYear}&income_category=expense`,
            config
          )
          .then((res) => {
            setSelectedHouses(res.data.incomeList);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [config,currentMonth,currentYear,id]);

  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [ setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = selectedHouses.sort((a, b) => {
    const sortValueA =
      typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB =
      typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex =
    currentPage === totalPages - 1
      ? filteredData.length
      : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const exportCSV = () => {
    const csvData = [
      ["Name", "House", "Utility", "Amount", "Invoice Month", "Units"],
      ...selectedHouses.map((item) => [
        item.tenant,
        item.house,
        item.expense_name,
        item.amount,
        item.month_units,
        item.units,
      ]),
    ];
    const csvBlob = new Blob([csvData.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    saveAs(csvBlob, "utilities_report.csv");
  };

  function handleDownload() {
    setIsLoading(true);
    // we will have to loop through all the months but with the selected year

    serverUrl
      .get(
        `/tenant/generate_annual_income_report/?apartment_id=${Apartmentid.Apartmentid}&year=${currentYear}&income_category=expense&download=true`,
        config
      )
      .then((res) => {
        // create a download url for the pdf and then automatically download the pdf
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "utilities_report.pdf");
        document.body.appendChild(link);
        link.click();
        toast.success("Successfully Downloaded Your Utilities Report");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // filter button to remove the filter
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("monthUtilities");
    localStorage.removeItem("yearUtilities");
    toast.success("Successfully Reset The Utilities Report Filter!");
    window.location.reload();
  };

  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <div className="UtilitiesReport">
        {/* list of the tenants of the apartment and payment details */}
        <div className="tasks ">
          <h2 style={{ color: "#c0392b" }}>utilities income</h2>

          {/* when any of the filters is on */}
          <div className="d-flex justify-content-between">
            <Form.Select
              size="sm"
              className="formCal"
              onChange={handleSelectChange}
              style={{ width: "20%" }}
            >
              <option selected disabled>
                Filter Report By:
              </option>
              <option>Monthly</option>
              <option>Yearly</option>
            </Form.Select>

            {monthFilterActive ? (
              <p className="filterText">
                Utilities Income Report For {monthFilterActive}{" "}
              </p>
            ) : yearFilterActive ? (
              <p className="filterText">
                Utilities Income Report For {yearFilterActive}
              </p>
            ) : (
              <p className="filterText" style={{ marginRight: "31rem" }}>
                Utilities Income Report For {currentMonth}
              </p>
            )}

            {monthFilterActive || yearFilterActive ? (
              <Button className="text-white filterButton" onClick={handleReset}>
                Reset Filter
              </Button>
            ) : (
              <></>
            )}
          </div>

          {/* modal to be displayed */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header className="bg-primary text-white" closeButton>
              <Modal.Title>Filter By {selectedOption}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedOption === currentMonth ? (
                <>
                  <p>{currentMonth}</p>
                </>
              ) : selectedOption === "Monthly" ? (
                <>
                  <Form>
                    <Row className="flex-wrap justify-content-between">
                      {months.map((month) => (
                        <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                          <Button
                            className="btn btn-success text-white w-75"
                            style={{ marginInline: "0.5rem" }}
                            onClick={() => handleMonthButtonClick(month)}
                          >
                            {month}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </Form>
                </>
              ) : selectedOption === "Yearly" ? (
                <>
                  <Form>
                    <Row className="flex-wrap justify-content-between">
                      {displayedYears.map((year) => (
                        <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                          <Button
                            className="btn btn-success text-white w-75"
                            style={{ marginInline: "0.5rem" }}
                            onClick={() => handleYearButtonClick(year)}
                          >
                            {year}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                    <div className="mt-3 d-flex justify-content-center">
                      {currentYearPage > 0 && (
                        <Button
                          variant="outline-primary"
                          onClick={handlePrevYearPage}
                        >
                          Previous
                        </Button>
                      )}
                      {currentYearPage < totalPagesYear - 1 && (
                        <Button
                          variant="outline-primary"
                          onClick={handleNextYearPage}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </Form>
                </>
              ) : (
                <>
                  <p>Date</p>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="d-flex justify-content-between">
            <div>
              <Button
                className="btn btn-success text-white me-3"
                onClick={exportCSV}
              >
                {" "}
                EXCEL <i className="bi bi-file-earmark-excel "></i>
              </Button>
              <Button
                className="btn btn-danger text-white me-3"
                onClick={handleDownload}
              >
                PDF <i className="bi bi-file-pdf-fill"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl
                type="text"
                placeholder="Search"
                onChange={handleSearch}
              />
            </Form>
          </div>
          <Table className="table">
            {yearFilterActive ? (
              <>
                <thead className="underline">
                  <th>Tenant Image</th>
                  <th onClick={() => handleSort("tenant_name")}>
                    Name<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_house")}>
                    House<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("expense_category")}>
                    Utility<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_amount")}>
                    Amount<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("unit")}>
                    Units<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_date_paid")}>
                    Date Paid<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("month")}>
                    Invoice Month<i className="bi bi-chevron-expand"></i>
                  </th>
                </thead>
                <tbody>
                  {displayedItems.map((expenses, index) => (
                    <tr>
                      <td style={{ width: "15%" }}>
                        <Image
                          src={expenses.tenant_image}
                          style={{ width: "40%", borderRadius: "50%" }}
                        />
                      </td>
                      <td>{expenses.tenant_name}</td>
                      <td>{expenses.tenant_house}</td>
                      <td>{expenses.expense_category}</td>
                      <td>{expenses.tenant_amount}</td>
                      <td>{expenses.unit}</td>
                      <td>{expenses.tenant_date_paid}</td>
                      <td>{expenses.month}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <thead className="underline">
                  <th>Tenant Image</th>
                  <th onClick={() => handleSort("tenant_name")}>
                    Name<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_house")}>
                    House<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("expense_category")}>
                    Utility<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_amount")}>
                    Amount<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("unit")}>
                    Units<i className="bi bi-chevron-expand"></i>
                  </th>
                  <th onClick={() => handleSort("tenant_date_paid")}>
                    Date Paid<i className="bi bi-chevron-expand"></i>
                  </th>
                </thead>
                <tbody>
                  {displayedItems.map((expenses, index) => (
                    <tr>
                      <td style={{ width: "15%" }}>
                        <Image
                          src={expenses.tenant_image}
                          style={{ width: "40%", borderRadius: "50%" }}
                        />
                      </td>
                      <td>{expenses.tenant_name}</td>
                      <td>{expenses.tenant_house}</td>
                      <td>{expenses.expense_category}</td>
                      <td>{expenses.tenant_amount}</td>
                      <td>{expenses.unit}</td>
                      <td>{expenses.tenant_date_paid}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </Table>
          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${
              selectedHouses.length
            }`}</span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              &gt;
            </button>
          </div>

          <h2 className="text-center text-primary">Total amount : KES 0</h2>
        </div>
      </div>
    </>
  );
}

export default UtilitiesReport;
