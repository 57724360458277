import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import "./Property.scss";
import {
  Button,
  Card,
  Container,
  Form,
  FormControl,
  Image,
  Modal,
} from "react-bootstrap";
import DefaultImage from '../../assets/img/default.png'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import loaderImage from "../../assets/img/logo.png";

function Property() {
  const [showSidebar, setShowSidebar] = useState(true);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // loading status
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  // related to apartment
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

  // counted house
  const [houseCount, setHouseCount] = useState({});

  // delete apartment
  const handleDeleteApartment = () => {
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the passed id from the modal
    const id = selectedApartment.id;
    const updateData = {
      is_visible: false,
    };
    // get the landlord details of the user
    serverUrl
      .patch(`/landlord/apartment/${id}/`, updateData, config)
      .then((res) => {
        // make the data real time
        setSelectedApartment(null);
        handleCloseModal();
        window.location.reload();
        toast.success("Property Deleted!");
      }, [])
      .catch((error) => {
        console.log(error);
      });
  };

  // pasing the apartment Details to the modal
  const handleOpenModal = (apartmentDetail) => {
    setSelectedApartment(apartmentDetail);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // apartments of the landlord
  const [landlordApartment, setLandlordApartment] = useState([]);

  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;
  const apartmentImage = {};

  const [landlordIdApartment, setLandlordIdApartment] = useState("");
  // from the localstorage get the logged in user
  useEffect(() => {
    setIsLoading(true);
    // get the landlord details that are linked to the user
    serverUrl
      .get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        setLandlordIdApartment(landlordIds);
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          // return serverUrl
          //   .get(
          //     `/landlord/landlord-apartment_name/?landlord_id=${landlordId}`,
          //     config
          //   )
          //   .then((res) => res.data.results)
          //   .catch((error) => {
          //     console.log(error);
          //     return [];
          //   });

          return serverUrl.get(`/users/list-user-groups/?user_id=${userId}`, config)
          .then((res) => {
            const userGroups = res.data;
            let landlordApartmentPromises;
        
            const superAdminGroup = userGroups.find((group) => group.name === 'Super Admin');
            if (superAdminGroup) {
              landlordApartmentPromises = serverUrl.get(`/landlord/apartment`, config)
                .then((res) => {
                  return res.data.results.filter((apartment) => apartment.for_kodi === false);
                })
                .catch((error) => {
                  console.log(error);
                  return [];
                });
            } else {
              landlordApartmentPromises = Promise.all(landlordIds.map((landlordId) => {
                return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                  .then((res) => res.data.results)
                  .catch((error) => {
                    console.log(error);
                    return [];
                  });
              }));
            }
        
            return landlordApartmentPromises;
          })
          .catch((error) => {
            console.log(error);
            return [];
          });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);

            // get the images of the apartments based on the apartment id
            apartments.map((apartment) => {
              serverUrl
                .get(
                  `/landlord/apartment-images/?apartment_id=${apartment.id}`,
                  config
                )
                .then((res) => {
                  res.data.results.forEach((res) => {
                    apartmentImage[res.apartment] = res.image;
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            });
            setSelectedApartmentImage(apartmentImage);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  // filter based on the property location
  const [selectedPropertyLocation, setPropertyLocation] = useState(null);
  const [selectedPropertyListed, setPropertyListed] = useState(null);
  const [selectedPropertyFeatured, setPropertyFeatured] = useState(null);

  // Create a Set to store unique location status
  const uniquePropertyLocation = new Set();

  // Filter houses array to get unique rent amounts
  const uniqueLocation = landlordApartment.filter((county) => {
    if (!uniquePropertyLocation.has(county.county)) {
      uniquePropertyLocation.add(county.county);
      return true;
    }
    return false;
  });

  // on search of the properties get to list the properties
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };
  const filteredData = landlordApartment.filter((item) => {
    // Filter by selected rent
    if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
      return false;
    }
    if (
      selectedPropertyListed &&
      item.is_listed.toString() !== selectedPropertyListed.toString()
    ) {
      return false;
    }
    if (
      selectedPropertyFeatured &&
      item.is_featured.toString() !== selectedPropertyFeatured.toString()
    ) {
      return false;
    }
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });
  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex =
    currentPage === totalPages - 1
      ? filteredData.length
      : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  // randomise the images for my properties
  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    const getRandomImage = () => {
      let storedImage = localStorage.getItem("backgroundImage");
      if (!storedImage) {
        storedImage = "";
      }

      let randomNumber = Math.floor(Math.random() * 10) + 1;
      while (
        randomNumber === 0 ||
        randomNumber === -1 ||
        storedImage.endsWith(`${randomNumber}.jpg`)
      ) {
        randomNumber = Math.floor(Math.random() * 10) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
      setBackgroundImage(imageUrl);
      localStorage.setItem("backgroundImage", imageUrl);
      setIsLoading(false);
    };

    getRandomImage();
  }, []);

  // randomise for each apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState("");
  useEffect(() => {
    const getRandomImage = () => {
      const usedImages = Object.values(backgroundImageApartment);
      const availableImages = Array.from(
        { length: 10 },
        (_, index) => index + 1
      );
      const remainingImages = availableImages.filter(
        (image) => !usedImages.includes(image)
      );
      const randomNumber =
        remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      const updatedImages = { ...backgroundImageApartment };

      // Loop through each house and update the image if not set
      for (const unitDetail of landlordApartment) {
        const apartmentId = unitDetail.id;
        if (!updatedImages[apartmentId]) {
          updatedImages[apartmentId] = getRandomImage();
        }
      }
      setBackgroundImageApartment(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [landlordApartment]);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const stylesStar = {
    left: "85%",
    backgroundColor: "transparent",
    color: "orange",
    width: "6%",
    fontsize: "2rem",
    top: "1px",
    fontSize: "1.8rem",
    fontWeight: "bold",

  };

  const stylesStarLiked = {
    left: "85%",
    backgroundColor: "transparent",
    color: "orange",
    width: "6%",
    fontsize: "2rem",
    top: "1px",
    fontSize: "1.8rem",
    fontWeight: "bold",
  };
  const [heartState, setHeartState] = useState({});

  // update the featured data
  const toggleHearts = async (propertyId) => {
    // check if the apartment has an image first
    serverUrl
      .get(`/landlord/apartment-images/?apartment_id=${propertyId}`, config)
      .then((res) => {
        console.log(res.data.count);
        if (res.data.count === 0) {
          toast.error(
            "Kindly add an image for that property so that it can be featured"
          );
        } else {
          // when the user clicks then update the status from is_featured to not featured
          const prevHeartStateValue = heartState[propertyId];
          // Update the UI to immediately reflect the change.
          setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [propertyId]: !prevHeartState[propertyId],
          }));

          // If the user has already liked the apartment, update it.
          if (prevHeartStateValue) {
            try {
              serverUrl
                .get(`/landlord/apartment/?id=${propertyId}`, config)
                .then((res) => {
                  if (res.data.count === 1) {
                    // When you get the apartment check if its featured already.
                    if (res.data.results[0].is_featured === true) {
                      const data = {
                        is_featured: false,
                      };
                      serverUrl
                        .patch(
                          `/landlord/apartment/${propertyId}/`,
                          data,
                          config
                        )
                        .then((res) => {
                          serverUrl
                            .get(
                              `/landlord/landlord-apartment_name/?landlord_id=${landlordIdApartment}`,
                              config
                            )
                            .then((res) => {
                              setLandlordApartment(res.data.results);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                          toast.success(
                            "Succesfully removed the property as featured properties"
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }

                    // await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                  }
                })

                .catch((error) => {
                  console.log(error);
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            serverUrl
              .get(`/landlord/apartment/?id=${propertyId}`, config)
              .then((res) => {
                if (res.data.results[0].is_featured === false) {
                  const data = {
                    is_featured: true,
                  };
                  serverUrl
                    .patch(`/landlord/apartment/${propertyId}/`, data, config)
                    .then((res) => {
                      serverUrl
                        .get(
                          `/landlord/landlord-apartment_name/?landlord_id=${landlordIdApartment}`,
                          config
                        )
                        .then((res) => {
                          setLandlordApartment(res.data.results);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                      toast.success(
                        "Succesfully added the property as featured properties"
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} alt="Loading..." />
      </div>
    );
  }

  return (
    <div>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && <SideBar />}
      <div className="property">
        <div
          className="topSelectedProperty"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <p className="properties">OUR PROPERTIES</p>
        </div>

        <Card className="cardProperty">
          <Form className="searchProperty">
            <FormControl
              type="text"
              placeholder="Search Property"
              className="mr-sm-2 rounded-4"
              onChange={handleSearch}
            />
          </Form>

          <Form.Select
            size="sm"
            className="formProperty"
            value={selectedPropertyLocation}
            onChange={(e) => setPropertyLocation(e.target.value)}
            style={{ marginRight: "15rem", width: "80%" }}
          >
            <option disabled selected>
              By County
            </option>
            <option value="">All Properties</option>
            {uniqueLocation.map((apartment) => (
              <option value={apartment.county} className='text-capitalize'>{apartment.county}</option>
            ))}
          </Form.Select>
          <Form.Select
            size="sm"
            className="formProperty"
            value={selectedPropertyFeatured}
            onChange={(e) => setPropertyFeatured(e.target.value)}
            style={{ marginRight: "15rem", width: "80%" }}
          >
            <option disabled selected>
              By Featured{" "}
            </option>
            <option value="">All Properties</option>
            <option value="true">Featured</option>
            <option value="false">Not Featured</option>
          </Form.Select>

          <Form.Select
            size="sm"
            className="formProperty"
            value={selectedPropertyListed}
            onChange={(e) => setPropertyListed(e.target.value)}
            style={{ marginRight: "10rem", width: "80%" }}
          >
            <option disabled selected>
              By Listed
            </option>
            <option value="">All Properties</option>
            <option value="true">Listed</option>
            <option value="false">Not Listed</option>
          </Form.Select>

          {/*                    <Button className='findNow'>Find Now</Button> */}
        </Card>

        <div>
          <Button className="propertyButton btn btn-sm btn-success">
            <Link
              to="/new-property"
              className="text-white text-decoration-none"
            >
              Add Property
            </Link>
          </Button>
        </div>
        {/* the cards that have the houses and their properties */}
        <div className="housesCards">
          {displayedItems.map((apartmentDetail, index) => (
            <Card key={index.id} className="house">
              <Card.Body>
                <div className="image-container">
                  <Link
                    key={apartmentDetail.id}
                    to={`/selected-property/${apartmentDetail.id}`}
                    className="text-danger text-decoration-none"
                  >
                    {selectedApartmentImage[apartmentDetail.id] ? (
                      <Image
                        src={selectedApartmentImage[apartmentDetail.id]}
                        className="property-grid"
                        alt="Apartment Image"
                      />
                    ) : (
                      <Image
                        src={DefaultImage}
                        className="property-grid"
                        alt="No image, add one"
                      />
                    )}

                  </Link>

                  <div className="badge">{apartmentDetail.num_houses} Units</div>
                  {apartmentDetail.is_listed === true ? (
                    <div
                      className="badge"
                      style={{
                        left: "85px",
                        backgroundColor: "#ffaf16",
                        color: "white",
                      }}
                    >
                      Listed
                    </div>
                  ) : (
                    <></>
                  )}

                  <div onClick={() => toggleHearts(apartmentDetail.id)}>
                    {heartState[apartmentDetail.id] ? (
                      <div className="badge" style={stylesStar}>
                        {apartmentDetail.is_featured === true ? (
                          <i className="bi bi-star-fill"></i>
                        ) : (
                          <i className="bi bi-star"></i>
                        )}
                      </div>
                    ) : (
                      <div className="badge" style={stylesStarLiked}>
                        {apartmentDetail.is_featured === true ? (
                          <i className="bi bi-star-fill"></i>
                        ) : (
                          <i className="bi bi-star"></i>
                        )}
                      </div>
                    )}
                  </div>

                  <p className="text-muted float-left">
                    {apartmentDetail.location},{apartmentDetail.county}
                  </p>
                  <br></br>
                </div>
                <p className="houseName" style={{ clear: "left" }}>
                  {apartmentDetail.name}{" "}
                </p>
                <p></p>

                <div className="buttonsProperties">
                  <Link
                    key={apartmentDetail.id}
                    to={`/selected-property/${apartmentDetail.id}`}
                    className="text-danger text-decoration-none"
                  >
                    View Property <i className="bi bi-arrow-right"></i>
                  </Link>
                  <button
                    className="icon-button btn btn-danger bg-danger text-white"
                    onClick={() => handleOpenModal(apartmentDetail)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                  <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Property</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to delete the property?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="success" onClick={handleCloseModal}>
                        No
                      </Button>
                      <Button
                        apartmentId={apartmentDetail.id}
                        variant="danger"
                        onClick={handleDeleteApartment}
                      >
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
        {landlordApartment.length > 8 ? (
          <div className="pagination" style={{ marginLeft: "14rem" }}>
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length
              }`}</span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
            >
              &gt;
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Property;
