import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import QuillEditor from "./QuillEditor";
import './BlogAdd.scss'
import Header from './Header';
import SideBar from './SideBar';

function BlogsAdd() {
    const [showSidebar, setShowSidebar] = useState(true);
    const navigate = useNavigate();

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }


    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;


    const [formDataBlog, setFormDataBlog] = useState({
        blog: '',
        description: '',
        long_description: '',
        category: '',
    });

    const handleChangeBlog = (event) => {
        const { name, value } = event.target;
        setFormDataBlog((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const [blogId, setBlogId] = useState('')






    const [quillValue, setQuillValue] = useState("");
    const [quillFileValue, setQuillFileValue] = useState("");
    const onEditorChange = (value) => {
        setQuillValue(value);
    };

    const onFileChange = (file) => {
        setQuillFileValue(file);
    };

    const handleButtonClick = () => {
        const fileInput = document.getElementById('imageUpload');
        fileInput.click();
    };

    const [images, setImages] = useState([]);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setImages(file);
    };


    function handleBlog(e) {
        e.preventDefault();
        var isPublic = false

        if (document.getElementById("public").checked) {
            var isPublic = true
        }
        const blogData = {
            name: formDataBlog.blog,
            user: userId,
            category: formDataBlog.category,
            description: formDataBlog.description,
            long_description: quillValue,
            is_public: isPublic
        }

        serverUrl.post('/landlord/blog/', blogData, config)
            .then((res => {
                if(images){
                    const formData = new FormData();
                    formData.append('image', images);
                    formData.append('blog', res.data.id);
                    formData.append('is_main', true);
    
                    serverUrl.post(`/landlord/blog-image/`, formData, config)
                        .then((res) => {
                            navigate('/blog')
                            toast.success('Blog Details is uploaded succesfully')

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                else{
                    navigate('/blog')
                    toast.success('Blog Details is uploaded succesfully')

                }
                setBlogId(res.data.id)
             
            }))
            .catch((error) => {
                console.log(error)
            })
    }



    return (
        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && <SideBar />}
            <div className="blogAdd" >
                <div className="">
                    <Card className="leftCard">
                        <h2>Create a blog</h2>

                        <Form className="" onSubmit={handleBlog}>


                            <div className='d-flex justify-content-between'>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        placeholder="Title"
                                        name="blog"
                                        value={formDataBlog.blog}
                                        onChange={handleChangeBlog}
                                        required
                                    />
                                </div>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        placeholder="Category"
                                        name="category"
                                        value={formDataBlog.category}
                                        onChange={handleChangeBlog}
                                        required
                                    />
                                </div>

                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <textarea
                                        className="input-field"
                                        type="text"
                                        placeholder="Short Description"
                                        name="description"
                                        rows={1}
                                        value={formDataBlog.description}
                                        onChange={handleChangeBlog}
                                        required
                                    />
                                </div>
                            </div>

                            <Button className='btn btn-sm mb-2' onClick={handleButtonClick}>
                                Select Blog cover photo
                            </Button>

                            <input
                                type="file"
                                id="imageUpload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleChange}
                            />

                            <div className="col-md-12">
                                <img src={images && images instanceof File ? URL.createObjectURL(images) : ''} alt="" className=' ' style={{width:"50vw",height:"40vh",objectFit:"cover"}}/>
                            </div>



                            <div style={{ marginLeft: "2rem", textAlignLast: "left", marginBottom: "1rem" }}>
                                <input type='checkbox' id='public' /><span className='ms-2'>Make the blog public?</span>
                            </div>

                            <QuillEditor
                                placeholder={"Start Typing Something"}
                                onEditorChange={onEditorChange}
                                onFilesChange={onFileChange}
                                value={formDataBlog.long_description}
                            />



                            <Button variant="warning" type="submit" className=" text-white" style={{ width: "10%", marginTop: "2rem", float: "right" }}>
                                Save Blog
                            </Button>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default BlogsAdd;
