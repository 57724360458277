import React, { useState, useEffect } from 'react'
import './SelectedHouse.scss'
import { Link, useParams } from "react-router-dom";
import Header from './Header';
import SideBar from './SideBar';
import { saveAs } from 'file-saver';

import loaderImage from '../../assets/img/logo.png'
import SelectedHouseCarousel from './SelectedHouseCarousel.js';
import { Button, Card, Col, Row, Image, Modal, Form, Dropdown, FormSelect, FormControl, Table } from 'react-bootstrap';
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import IssueReport from './reports/IssueReport';
import HistoryReport from './reports/HistoryReport';
import InvoiceReport from './reports/InvoiceReport';
import ReceiptReport from './reports/ReceiptReport';



function SelectedHouse() {
    const navigate = useNavigate()
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [showModalHouse, setShowModalHouse] = useState(false)
    const [showModalRate, setShowModalRate] = useState(false);

    // modal for editing the details of the tenant
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [houseTenant, setHouseTenant] = useState([])
    const [joined, setJoined] = useState("")
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    // the current date format in yyyy-mm-dd
    let formatDate = new Date().toISOString().split("T")[0]

    // remove tenant
    const [showModalDeleteTenant, setShowModalDeleteTenant] = useState(false);
    const [tenantRemove, setTenantRemove] = useState(null);
    const [tenantIdOccupancy, setTenantIdOccupancy] = useState(null);
    const [houseId, setHouseId] = useState(null);

    const handleOpenDeleteTenant = () => {
        setShowModalDeleteTenant(true);
    };
    const handleCloseModalDeleteTenant = () => {
        setShowModalDeleteTenant(false);
    };
    const handleCloseModalReloadTenantDelete = () => {
        setShowModalDeleteTenant(false);
        window.location.reload()
    };
    // loading status
    const [isLoading, setIsLoading] = useState(true)


    // form data for tenant details
    const [formDataTenant, setFormDataTenant] = useState({
        full_name: "",
        phone_number: ""
    });
    const [landlord, setLandlord] = useState("")

    // house selected
    const [houseSelected, setHouseSelected] = useState([])
    const [houseSelectedApartment, setHouseSelectedApartment] = useState("")
    const [houseApartment, setHouseApartment] = useState("")


    // for kodi score purpose
    const [tenantScore, setTenantScore] = useState("")
    const [tenantId, setTenantId] = useState("")
    const [occupancySet, setOccupancy] = useState({})
    const [rentStatus, setRentStatus] = useState({})
    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [unitAmenities, setUnitAmenities] = useState([])
    const [unitRooms, setUnitRooms] = useState([])

    const [unitAmenitiesIcons, setUnitAmenitiesIcons] = useState([])

    const [sharedUnit, setSharedUnits] = useState('')
    const [likedUnits, setLikedUnit] = useState('')
    const [userShareCounts, setUserShareCounts] = useState([])
    const [userLikedUnit, setUserLikedUnit] = useState([])

    // get the house selected by user
    useEffect(() => {
        setIsLoading(true);

        // get the landlord of that house
        serverUrl
            .get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                setLandlord(res.data.results[0].id);
            })
            .catch((error) => {
                console.log(error);
            });

        serverUrl
            .get(`/landlord/house/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data);
                setHouseSelectedApartment(res.data.apartment);
                setIsLoading(false);
                // get the apartment name of the house
                serverUrl.get(`/landlord/apartment/?id=${res.data.apartment}`, config)
                    .then((res) => {
                        setHouseApartment(res.data.results[0].name)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

        // get the tenant of the house
        serverUrl
            .get(`/tenant/occupancy/?house_id=${id}`, config)
            .then((res) => {
                // this the occupancy id obtained
                setTenantRemove(res.data.results[0].id);
                // the house id obtained
                setHouseId(res.data.results[0].house);
                // the tenant id obtained
                setTenantIdOccupancy(res.data.results[0].tenant);
                setJoined(res.data.results[0].date_in);

                const tenantOccupancy = {};
                res.data.results.forEach((occupancy) => {
                    if (occupancy.status === "active") {
                        tenantOccupancy[id] = occupancy;
                        setOccupancy(occupancy);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

        // get the amenities
        serverUrl.get(`/landlord/alliance-unit-amenities?house_id=${id}`, config)
            .then((res) => {
                setUnitAmenities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
        // get the amenities of that unit 
        serverUrl.get(`/landlord/unit-icons/`, config)
            .then((res) => {
                setUnitAmenitiesIcons(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        // Assuming unitRooms is an array of objects with room_id property
        serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
            .then((res) => {
                const unitRooms = res.data.results;
                const groupedRooms = unitRooms.reduce((acc, amenity) => {
                    const { room_id, room, room_image, room_image_id } = amenity;

                    if (acc[room_id]) {
                        acc[room_id].push({ room, room_image, room_id, room_image_id });
                    } else {
                        acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                    }

                    return acc;
                }, {});
                // Transform the groupedRooms object into an array of objects
                const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                    room: groupedRooms[roomId][0],
                    data: groupedRooms[roomId],

                }));
                // Now, you can set the state with the grouped data
                setUnitRooms(groupedRoomsArray);

            })
            .catch((error) => {
                console.log(error);
            });


        serverUrl.get(`/landlord/shared-units?house=${id}`, config)
            .then((res) => {
                setSharedUnits(res.data.count)

                // get the users
                const shareData = res.data.results;

                // Create a mapping to store the count for each user
                const userCountMap = {};

                // Get unique user IDs from shares
                const uniqueUserIds = [...new Set(shareData.map((share) => share.user))];

                // Fetch user details for each unique user ID
                Promise.all(
                    uniqueUserIds.map((userId) =>
                        serverUrl.get(`/users/users-details/?id=${userId}`, config)
                    )
                )
                    .then((userDetailsResponses) => {
                        // Update the userCountMap with user names and the latest share time
                        userDetailsResponses.forEach((userDetailsResponse) => {
                            const userDetails = userDetailsResponse.data.results[0];
                            const userId = userDetails.id;
                            const userName = userDetails.full_name;

                            // Find the latest share time for the current user
                            const latestShareTime = shareData
                                .filter((share) => share.user === userId)
                                .reduce((latest, share) => {
                                    const shareTime = new Date(share.created_at).getTime();
                                    return shareTime > latest ? shareTime : latest;
                                }, 0);

                            userCountMap[userId] = {
                                name: userName,
                                count: 0,
                                latestShareTime: latestShareTime,
                            };
                        });

                        // Update the count for each user
                        shareData.forEach((share) => {
                            const userId = share.user;
                            userCountMap[userId].count++;
                        });

                        // Convert userCountMap to an array and sort it based on count in descending order
                        const sortedUserShareCounts = Object.entries(userCountMap).map(
                            ([userId, { name, count, latestShareTime }]) => ({
                                userId,
                                name,
                                count,
                                latestShareTime,
                            })
                        );
                        sortedUserShareCounts.sort((a, b) => b.count - a.count);

                        setUserShareCounts(sortedUserShareCounts);
                    })
                    .catch((error) => {
                        console.log(error);
                    });


            })
            .catch((error) => {
                console.log(error)
            })



        serverUrl.get(`/landlord/viewers-favourites/?house=${id}`, config)
            .then((res) => {
                setLikedUnit(res.data.count)

                // get the users
                const likedData = res.data.results;

                // Create a mapping to store the count for each user
                const userCountMap = {};

                // Get unique user IDs from shares
                const uniqueUserIds = [...new Set(likedData.map((share) => share.user))];

                // Fetch user details for each unique user ID
                // Assuming likeData contains information about likes and each like has a created_at timestamp
                Promise.all(
                    uniqueUserIds.map((userId) =>
                        serverUrl.get(`/users/users-details/?id=${userId}`, config)
                    )
                )
                    .then((userDetailsResponses) => {
                        // Update the userCountMap with user details and the latest liked date
                        userDetailsResponses.forEach((userDetailsResponse) => {
                            const userDetails = userDetailsResponse.data.results[0];
                            const userId = userDetails.id;
                            const userName = userDetails.full_name;
                            const userPhone = userDetails.phone_number;

                            // Find the latest liked date for the current user
                            const latestLikedDate = likedData
                                .filter((like) => like.user === userId)
                                .reduce((latest, like) => {
                                    const likeDate = new Date(like.created_at);
                                    return likeDate > latest ? likeDate : latest;
                                }, new Date(0)); // Initialize with a date far in the past

                            userCountMap[userId] = {
                                name: userName,
                                phone: userPhone,
                                latestLikedDate: latestLikedDate.toLocaleString(),
                            };
                        });

                        // Convert userCountMap to an array and sort it based on names
                        const sortedUserLikedDates = Object.values(userCountMap).sort((a, b) =>
                            a.name.localeCompare(b.name)
                        );

                        // Set the state with the sorted user liked dates
                        setUserLikedUnit(sortedUserLikedDates);
                    })
                    .catch((error) => {
                        console.log(error);
                    });


            })
            .catch((error) => {
                console.log(error)
            })

        // get the icons of the units
    }, []);

    useEffect(() => {
        serverUrl
            .get(`/tenant/tenant/${occupancySet.tenant}`, config)
            .then((res) => {
                setTenantId(res.data.id);
                // get the kodi score of the tenant
                serverUrl
                    .get(`/tenant/tenancy-kodi-score/?tenant_id=${res.data.id}`, config)
                    .then((res) => {
                        setTenantScore(res.data.kodi_score);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                serverUrl
                    .get(`/users/users-details/${res.data.user}/`, config)
                    .then((res) => {
                        setHouseTenant(res.data);
                        // get the rent status payment
                        serverUrl
                            .get(`/landlord/get-rent-status/?user_id=${res.data.id}`, config)
                            .then((res) => {
                                setRentStatus(res.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [occupancySet.tenant]);




    const selectedUnit = houseSelected
    const idHouse = parseInt(id)

    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // modal for adding tenant

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // done button reloading the page
    const handleCloseModalReload = () => {
        setShowModal(false);
        window.location.reload()
    };

    const [sharesUnitModal, setSharesUnitModal] = useState(false)


    const handleOpenShareUnitsModal = () => {
        setSharesUnitModal(true);
    };

    const handleCloseShareUnitsModal = () => {
        setSharesUnitModal(false);
    };

    const [likedUnitModal, setLikedUnitModal] = useState(false)


    const handleOpenLikedUnitsModal = () => {
        setLikedUnitModal(true);
    };

    const handleCloseLikedUnitsModal = () => {
        setLikedUnitModal(false);
    };

    const [publicModal, setPublicModal] = useState(false)


    const handleOpenPublicModal = () => {
        setPublicModal(true);
    };

    const handleClosePublicModal = () => {
        setPublicModal(false);
    };
    // modal for editing the details of the tenant

    const handleOpenModalEdit = () => {
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    };
    const handleCloseModalEditReload = () => {
        setShowModal(false);
        window.location.reload()
    };
    // counting the number of digits inserted on the phone number
    const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length;
    // the data that will be posted to the api is as follows



    const handleOpenModalRate = () => {
        setShowModalRate(true);
    };

    const handleCloseModalRate = () => {
        setShowModalRate(false);
    };
    // done button reloading the page
    const handleCloseModalReloadRate = () => {
        setShowModalRate(false);
        window.location.reload()
    };
    // form data for tenant details ratings
    const [formDataTenantRate, setFormDataTenantRate] = useState({
        comment: "",
        rating: ""
    });

    // the stars value
    const [starsClicked, setStarsClicked] = useState(0);

    useEffect(() => {
        // console.log("Value of stars:", starsClicked === 0 ? 0 : starsClicked);
    }, [starsClicked]);

    const handleStarClick = (starIndex) => {
        if (starIndex === 0) {
            setStarsClicked(0);
        } else if (starsClicked === starIndex) {
            setStarsClicked(starIndex - 1);
        } else {
            setStarsClicked(starIndex);
        }
    };


    // form data to be sent to backend on eadding the House
    const [formDataHouse, setFormDataHouse] = useState({
        house_number: "",
        total_rooms: "",
        due_date: "",
        bedroom: "",
        deposit: "",
        bathroom: "",
        rent: "",
        type_of_house: "",
        about: "",
        size: ""
    });

    // Use useEffect to update formData when selectedApartment changes
    useEffect(() => {
        if (selectedUnit) {
            setFormDataHouse({
                house_number: selectedUnit.house_number || '',
                total_rooms: selectedUnit.total_rooms || '',
                due_date: selectedUnit.due_date || '',
                bedroom: selectedUnit.bedroom || '',
                deposit: selectedUnit.deposit || '',
                bathroom: selectedUnit.bathroom || '',
                rent: selectedUnit.rent || '',
                type_of_house: selectedUnit.type_of_house || '',
                about: selectedUnit.about || '',
                size: selectedUnit.size || '',

            });
        }
    }, [selectedUnit]);

    // onchange functionality on editing a  house
    const handleChangeHouse = (event) => {
        const { name, value } = event.target;
        setFormDataHouse((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    // editing the selected house
    function handleSubmitHouse(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // the data that will be posted to the api is as follows
        const dataHouse = {
            apartment: houseSelectedApartment,
            house_number: formDataHouse.house_number,
            bedroom: formDataHouse.bedroom,
            bathroom: formDataHouse.bathroom,
            due_date: formDataHouse.due_date,
            deposit: parseInt(formDataHouse.deposit),
            rent: formDataHouse.rent,
            type_of_house: formDataHouse.type_of_house,
            type_rent_status: formDataHouse.type_rent_status,
            rent_type: formDataHouse.rent_type,
            about: formDataHouse.about,
        };
        
        if(formDataHouse.size){
            dataHouse.size = formDataHouse.size
        }
        //  now post the data
        try {
            serverUrl

                //send the House details using the following endpoint
                .patch(`/landlord/house/${id}/`, dataHouse, config)
                .then((res) => {
                    setFormDataHouse({
                        house_number: "",
                        total_rooms: "",
                        due_date: "",
                        bedroom: "",
                        deposit: "",
                        bathroom: "",
                        rent: "",
                        type_of_house: "",
                    });
                    toast.success('Successfully Updated The Unit!');

                    window.location.reload()
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        toast.error('Failed to update the unit kindly try again')
                        console.log('Error while adding a new house' + error.message);
                    } else {
                        toast.error('Failed to update the unit kindly try again')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
        }

    }

    // add the special amenities
    const [showModalAmenity, setShowModalAmenity] = useState(false);
    const handleOpenModalAmenityAdd = () => {
        setShowModalAmenity(true);
    };

    const handleCloseModalAmenityAdd = () => {
        setShowModalAmenity(false);
    };

    // add the rooms for the units
    const [showModalRooms, setShowModalRooms] = useState(false);
    const handleOpenModalRoomAdd = () => {
        setShowModalRooms(true);
    };

    const handleCloseModalRoomAdd = () => {
        setShowModalRooms(false);
    };

    // delete the rooms for the units
    const [showModalRoomsDelete, setShowModalRoomsDelete] = useState(false);
    const handleOpenModalRoomDelete = () => {
        setShowModalRoomsDelete(true);
    };

    const handleCloseModalRoomDelete = () => {
        setShowModalRoomsDelete(false);
    };

    // add amenities variables 
    const [formDataAmenity, setFormDataAmenity] = useState({
        icon: ""
    });

    const handleChangeAmenity = (e) => {
        const selectedFacilityIconId = e.target.value;
        setFormDataAmenity({ ...formDataAmenity, icon: selectedFacilityIconId });
    };


    // the rooms in the units being added
    const [formDataRoom, setFormDataRoom] = useState({
        name: ""
    });

    const handleChangeRoom = (e) => {
        const selectedFacilityIconId = e.target.value;
        setFormDataRoom({ ...formDataAmenity, name: selectedFacilityIconId });
    };

    // delete amenity variables
    const [formDataAmenityDelete, setFormDataAmenityDelete] = useState({
        amenity: "",

    });
    // onchange functionality on delete new amenity
    const handleChangeAmenityDelete = (name) => (event) => {
        const { value } = event.target;
        setFormDataAmenityDelete((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // delete room 
    const [formDataRoomDelete, setFormDataRoomDelete] = useState({
        room: "",

    });
    // onchange functionality on delete new room
    const handleChangeRoomDelete = (name) => (event) => {
        const { value } = event.target;
        setFormDataRoomDelete((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };



    // add nearby facilities
    function handleAmenities(e) {
        // preventing from redirecting to other pages
        e.preventDefault();

        // the data that will be posted to the api is as follows
        const data = {
            house: parseInt(id),
            icon: parseInt(formDataAmenity.icon),
        };

        //  now post the data 
        try {
            serverUrl

                //send the amenities of the apartment to the backend
                .post(`/landlord/unit-amenities/`, data, config)
                .then((res) => {
                    toast.success('Successfully added the amenities for this unit!');
                    setFormDataAmenity({
                        icon: "",
                    })
                    // get the amenities
                    serverUrl.get(`/landlord/alliance-unit-amenities?house_id=${id}`, config)
                        .then((res) => {
                            setUnitAmenities(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    toast.error('Unexpected error')
                    console.log('Unexpected error: ' + error.message);

                });

        } catch (error) {
            console.log(error);
        }

    }


    // submit to delete the amenity
    function handleDeleteAmenity(e) {
        // preventing from redirecting to other pages
        e.preventDefault();

        //  now post the data
        try {
            serverUrl

                //send the amenities of the apartment to the backend
                .delete(`/landlord/unit-amenities/${formDataAmenityDelete.amenity}/`, config)
                .then((res) => {
                    toast.success('Successfully Deleted The Amenity Of The Unit!');
                    setFormDataAmenityDelete({
                        amenity: ""
                    })
                    // get the amenities
                    serverUrl.get(`/landlord/alliance-unit-amenities?house_id=${id}`, config)
                        .then((res) => {
                            setUnitAmenities(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    toast.error('Unexpected error')
                    console.log('Unexpected error: ' + error.message);

                });

        } catch (error) {
            console.log(error);
        }

    }

    // add the special amenities
    const [showModalAmenityDelete, setShowModalAmenityDelete] = useState(false);
    const handleOpenModalAmenityDelete = () => {
        setShowModalAmenityDelete(true);
    };

    const handleCloseModalAmenityDelete = () => {
        setShowModalAmenityDelete(false);
    };
    // now display the stars based on the score they have obtained

    const scoreParts = tenantScore.split("/");
    const scoreKodi = scoreParts[0];
    const dividedScore = parseFloat(scoreParts[0]) / 2;

    const starCount = Math.floor(dividedScore);
    // give them default half stars if for their score
    const hasHalfStar = dividedScore < 1;

    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < starCount) {
            stars.push(<i className='bi bi-star-fill'></i>);
        } else if (i === starCount && hasHalfStar) {
            stars.push(<i className='bi bi-star-half'></i>);
        } else {
            stars.push(<i className='bi bi-star'></i>);
        }
    }

    // when user clicks call then open the call
    const handleClickPhone = () => {
        window.location.href = `tel:${houseTenant.phone_number}`;
    };

    // send reminder for the landlord
    const [remindersModal, setRemindersModal] = useState(false)

    // modal for filtering on the month
    const handleReminderModalOpen = () => {
        setRemindersModal(true);
    };

    const handleReminderModalClose = () => {
        setRemindersModal(false);
    };
    const handleReminderModalReload = () => {
        setRemindersModal(false);
        window.location.reload()
    };

    const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
    const [addTemplate, setAddTemplate] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOptionReminder(option);
        setAddTemplate(false)
    };

    const handleAddTemplate = () => {
        setSelectedOptionReminder('')
        setAddTemplate(true)
    }





    // get the facilities added in each apartment
    const addedFacilityIds = unitAmenities.map((facility) => facility.icon_id);
    const availableAmenities = unitAmenitiesIcons.filter((facility) => !addedFacilityIds.includes(facility.id));

    // change the unit status
    const occupancyStatus = () => {
        // get to see the status of the house first
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {

                if (res.data.occupancy_status === "vacant") {
                    const data = {
                        occupancy_status: "occupied"
                    }

                    serverUrl.patch(`/landlord/house/${id}/`, data, config)
                        .then((res) => {
                            toast.success('Succesfully changed the status the house is now occupied')
                            setShowModal(false);
                            window.location.reload()

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    const data = {
                        occupancy_status: "vacant"
                    }


                    serverUrl.patch(`/landlord/house/${id}/`, data, config)
                        .then((res) => {
                            toast.success('Succesfully changed the status the house is now vacant')
                            setShowModal(false);
                            window.location.reload()

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
            })


    }

    const publicStatus = () => {
        // get to see the status of the house first
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {
                const PublicUnit = res.data.is_public
                // check if the house has images
                serverUrl.get(`/landlord/house-images?house_id=${res.data.id}`, config)
                    .then((res) => {
                        if (res.data.count > 4) {
                            if (PublicUnit === false) {
                                const data = {
                                    is_public: true
                                }

                                serverUrl.patch(`/landlord/house/${id}/`, data, config)
                                    .then((res) => {
                                        toast.success('Succesfully changed the status the house is publicly listed')
                                        setPublicModal(false);
                                        window.location.reload()

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                            else {
                                const data = {
                                    is_public: false
                                }


                                serverUrl.patch(`/landlord/house/${id}/`, data, config)
                                    .then((res) => {
                                        toast.success('Succesfully changed the status the house is not publicly listed')
                                        setPublicModal(false);
                                        window.location.reload()

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                        }
                        else {
                            toast.error('Kindly add more than 5 images for this unit to allow it to be made public')
                            setPublicModal(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })


            })
            .catch((error) => {
                console.log(error)
            })


    }

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }

    // delete the image
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);
    const openModal = (apartmentId) => {
        setSelectedApartmentId(apartmentId);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedApartmentId(null);
    };
    // on search of the property liked
    const [searchQueryProperty, setSearchQueryProperty] = useState("");
    const [selectedRowProperty, setSelectedRowProperty] = useState(null);

    const handleSearchProperty = (event) => {
        setSearchQueryProperty(event.target.value);
        setSelectedRowProperty(null); // Reset selected row when user starts a new search
    };

    const filteredDataProperty = userShareCounts.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryProperty.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageProperty, setCurrentPageProperty] = useState(0);
    const itemsPerPageProperty = 10;

    const totalPagesProperty = Math.ceil(filteredDataProperty.length / itemsPerPageProperty);
    const startIndexProperty = currentPageProperty * itemsPerPageProperty;
    const endIndexProperty = currentPageProperty === totalPagesProperty - 1 ? filteredDataProperty.length : startIndexProperty + itemsPerPageProperty;
    const displayedItemsProperty = filteredDataProperty.slice(startIndexProperty, endIndexProperty);

    const exportCSVProperty = () => {
        const csvData = [
            ['Unit', 'Shares', 'Time Shared'],
            ...userShareCounts.map((item) => [item.name, item.count, new Date(item.latestShareTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Shares.csv');
    };

    const handlePrevPageProperty = () => {
        setCurrentPageProperty(currentPageProperty - 1);
    };

    const handleNextPageProperty = () => {
        setCurrentPageProperty(currentPageProperty + 1);
    };

    const handleDeleteApartment = () => {
        if (selectedApartmentId) {
            serverUrl.delete(`/landlord/house-room-image/${selectedApartmentId}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
                        .then((res) => {
                            const unitRooms = res.data.results;
                            const groupedRooms = unitRooms.reduce((acc, amenity) => {
                                const { room_id, room, room_image, room_image_id } = amenity;

                                if (acc[room_id]) {
                                    acc[room_id].push({ room, room_image, room_id, room_image_id });
                                } else {
                                    acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                                }

                                return acc;
                            }, {});

                            // Transform the groupedRooms object into an array of objects
                            const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                                room: groupedRooms[roomId][0],
                                data: groupedRooms[roomId],

                            }));
                            // Now, you can set the state with the grouped data
                            setUnitRooms(groupedRoomsArray);
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    setIsModalOpen(false);

                    toast.success('Succesfully deleted the image')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    // on search of the property liked
    const [searchQueryLikes, setSearchQueryLikes] = useState("");
    const [selectedRowLikes, setSelectedRowLikes] = useState(null);

    const handleSearchLikes = (event) => {
        setSearchQueryLikes(event.target.value);
        setSelectedRowLikes(null); // Reset selected row when user starts a new search
    };

    const filteredDataLikes = userLikedUnit.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryLikes.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageLikes, setCurrentPageLikes] = useState(0);
    const itemsPerPageLikes = 10;

    const totalPagesLikes = Math.ceil(filteredDataLikes.length / itemsPerPageLikes);
    const startIndexLikes = currentPageLikes * itemsPerPageLikes;
    const endIndexLikes = currentPageLikes === totalPagesLikes - 1 ? filteredDataLikes.length : startIndexLikes + itemsPerPageLikes;
    const displayedItemsLikes = filteredDataLikes.slice(startIndexLikes, endIndexLikes);

    const exportCSVLikes = () => {
        const csvData = [
            ['Apartment', 'Likes', 'Liked Date'],
            ...userLikedUnit.map((item) => [item.apartmentName, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Likes.csv');
    };

    const handlePrevPageLikes = () => {
        setCurrentPageLikes(currentPageLikes - 1);
    };

    const handleNextPageLikes = () => {
        setCurrentPageLikes(currentPageLikes + 1);
    };

    // delete the room selected
    function handleDeleteRoom(e) {
        e.preventDefault();

        // Get the room ID and then delete it
        serverUrl.get(`/landlord/house-room/?room=${formDataRoomDelete.room}&house=${id}`)
            .then((res) => {
                const deletePromises = res.data.results.map((result) => {
                    return serverUrl.delete(`/landlord/house-room/${result.id}/`, config);
                });

                Promise.all(deletePromises)
                    .then((deleteResults) => {
                        toast.success('Successfully Deleted The Room(s) Of The Unit!');
                        setFormDataRoomDelete({
                            room: "",
                        });

                        // Assuming unitRooms is an array of objects with room_id property
                        serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
                            .then((res) => {
                                const unitRooms = res.data.results;
                                const groupedRooms = unitRooms.reduce((acc, amenity) => {
                                    const { room_id, room, room_image, room_image_id } = amenity;

                                    if (acc[room_id]) {
                                        acc[room_id].push({ room, room_image, room_id, room_image_id });
                                    } else {
                                        acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                                    }

                                    return acc;
                                }, {});

                                // Transform the groupedRooms object into an array of objects
                                const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                                    room: groupedRooms[roomId][0],
                                    data: groupedRooms[roomId],

                                }));
                                // Now, you can set the state with the grouped data
                                setUnitRooms(groupedRoomsArray);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        toast.error('Error deleting room(s)');
                        console.log('Error deleting room(s): ' + error.message);
                    });
            })
            .catch((error) => {
                toast.error('Error fetching room(s) for deletion');
                console.log('Error fetching room(s) for deletion: ' + error.message);
            });
    }

    function toCamelCase(str) {
        return str.replace(/_/g, " ");
    }

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            {
                selectedUnit.id === idHouse ?
                    (<div className='selectedHouse'>

                        <SelectedHouseCarousel houseId={selectedUnit.id} />
                        <div className='selectedHouseImageContent'>
                            <h2>{selectedUnit.house_number}</h2>
                            <span >{houseApartment}</span>
                            <div className='buttonsSelectedHouse' style={{ display: "block" }}>
                                <Button className='btn btn-success btn-sm border-0 me-3' onClick={handleOpenModalEdit}>Edit</Button>
                                {/* modal to edit unit */}
                                <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Your Unit Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>

                                        <Form onSubmit={handleSubmitHouse}>
                                            <p className='lableName'>Unit Name</p>

                                            <div className="input-icons mt-2">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="text"
                                                    value={formDataHouse.house_number}
                                                    onChange={handleChangeHouse}
                                                    placeholder={selectedUnit.house_number}
                                                    name="house_number"
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Rent Amount</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="rent"
                                                    value={formDataHouse.rent}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Rent Amount"}
                                                    required
                                                />
                                            </div>

                                            <p className='lableName'>Unit Deposit Amount</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="deposit"
                                                    value={formDataHouse.deposit}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Deposit Amount"}
                                                    required
                                                />
                                            </div>

                                            <p className='lableName'>No of Bedrooms</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="bedroom"
                                                    value={formDataHouse.bedroom}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"No of Bedrooms"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>No of Bathrooms</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="bathroom"
                                                    value={formDataHouse.bathroom}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"No of Bathrooms"}
                                                    required
                                                />
                                            </div>
                                            {formDataHouse.rent_type === 'commercial' ?
                                                <>
                                                    <p className='lableName'>Unit Size</p>

                                                    <div className="input-icons">
                                                        <i className="fa fa-hashtag icon"></i>
                                                        <input
                                                            className="input-field"
                                                            type="number"
                                                            name="size"
                                                            value={formDataHouse.size}
                                                            onChange={handleChangeHouse}
                                                            placeholder={"Unit Square Foot Size"}
                                                            required
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>

                                            }






                                            <p className='lableName'>Unit Rent Type</p>
                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>

                                                <Form.Select
                                                    size='sm'
                                                    className="input-field text-capitalize"
                                                    value={formDataHouse.rent_type}
                                                    onChange={handleChangeHouse}
                                                    name='rent_type'
                                                >
                                                    <option selected disabled className='text-capitalize'>{toCamelCase(selectedUnit.rent_type)}</option>

                                                    <option value="residential">Residential</option>
                                                    <option value="commercial">Commercial</option>
                                                </Form.Select>
                                            </div>

                                            <p className='lableName'>Unit Type Rent Status</p>
                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>

                                                <Form.Select
                                                    size='sm'
                                                    className="input-field text-capitalize"
                                                    value={formDataHouse.type_rent_status}
                                                    onChange={handleChangeHouse}
                                                    name='type_rent_status'
                                                >
                                                    <option selected disabled className='text-capitalize'>{toCamelCase(selectedUnit.type_rent_status)}</option>
                                                    <option value="rent">Rent</option>
                                                    <option value="rent_to_own">Rent To Own</option>
                                                    <option value="for_sale">For Sale</option>

                                                </Form.Select>
                                            </div>


                                            <p className='lableName'>Unit Type</p>
                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <Form.Select
                                                    size='sm'
                                                    className='input-field text-capitalize'
                                                    value={formDataHouse.type_of_house}
                                                    onChange={handleChangeHouse}
                                                    name="type_of_house"

                                                >
                                                    <option selected disabled className='text-capitalize'>{toCamelCase(selectedUnit.type_of_house)}</option>

                                                    <option value="apartment_units">Apartment Units</option>
                                                    <option value="studio">Studio</option>
                                                    <option value="bungalow">Bungalow</option>
                                                    <option value="bedsitter">Bedsitter</option>
                                                    <option value="servant_quarters">Servant Quarters(SQ)</option>
                                                    <option value="mansionette">Mansionette</option>
                                                    <option value="office">Office</option>
                                                    <option value="stall">Stall</option>
                                                    <option value="shop">Shop</option>
                                                </Form.Select>
                                            </div>

                                            <p className='lableName'>About Unit</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <textarea
                                                    className="input-field"
                                                    type="text"
                                                    name="about"
                                                    value={formDataHouse.about}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"About Unit"}
                                                    required

                                                    style={{ height: "330px" }}
                                                />
                                            </div>

                                            <Button variant="primary" type="submit" className="nextBtn mt-3">
                                                Save
                                            </Button>
                                        </Form>

                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="success" onClick={handleCloseModalEditReload} >
                                            Done
                                        </Button>
                                    </Modal.Footer>

                                </Modal>

                                <Button className='btn btn-warning btn-sm border-0 text-white me-3' onClick={handleOpenModal}>Change Occupancy Status</Button>
                                <Link to={`/selected-house-image/${idHouse}`}>
                                    <Button className='btn btn-info btn-sm text-white text-capitialize'>
                                        Gallery

                                    </Button>
                                </Link>
                                {selectedUnit.is_public ?
                                    <Button onClick={handleOpenPublicModal} className='btn ms-3 btn-sm text-white border-0 text-capitialize' style={{ backgroundColor: '#e67e22' }}>Remove From Publicly Listed </Button>

                                    :
                                    <Button onClick={handleOpenPublicModal} className='btn ms-3 btn-sm text-white border-0 text-capitialize' style={{ backgroundColor: '#8e44ad' }}>Make Publicly Listed </Button>

                                }


                                {/* modal for adding a new tenant */}
                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Occupancy Status</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {selectedUnit.occupancy_status === 'vacant' ?
                                            <p>Do you want to change the unit from vacant to occupied?</p>
                                            :
                                            <p>Do you want to change the unit from occupied to vacant?</p>

                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={occupancyStatus} >
                                            Yes
                                        </Button>
                                        <Button variant="danger" onClick={handleCloseModal} >
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={publicModal} onHide={handleClosePublicModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Unit publicly listed </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {selectedUnit.is_public ?
                                            <p>Do you want to remove the unit from publicly listed?</p>
                                            :
                                            <p>Do you want to add the unit to publicly listed ?</p>

                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={publicStatus} >
                                            Yes
                                        </Button>
                                        <Button variant="danger" onClick={handleClosePublicModal} >
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>


                        <div className='d-flex justify-content-between'>

                            <div className='selectedHouseStatus'>
                                {selectedUnit.occupancy_status === 'vacant' ?
                                    <Button className='btn btn-success text-capitalize'>{selectedUnit.occupancy_status}</Button>
                                    :
                                    <>
                                        <Button className='btn btn-warning text-white text-capitalize' >{selectedUnit.occupancy_status}</Button>

                                    </>

                                }
                                {selectedUnit.type_rent_status === 'rent' ?
                                    <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#8e44ad" }}>{toCamelCase(selectedUnit.type_rent_status)}</Button>
                                    : selectedUnit.type_rent_status === 'rent_to_own' ?
                                        <Button className='btn btn-info ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#e67e22" }}>{toCamelCase(selectedUnit.type_rent_status)}</Button>
                                        :
                                        <Button className='btn btn-secondary border-0 ms-3 text-white text-capitalize' style={{ backgroundColor: "#9b59b6" }} >{toCamelCase(selectedUnit.type_rent_status)}</Button>
                                }

                                {selectedUnit.rent_type === 'residential' ?
                                    <Button className='btn btn-success ms-3 border-0 text-capitalize' style={{ backgroundColor: "#34495e" }}>{selectedUnit.rent_type}</Button>
                                    :
                                    <>
                                        <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#2ecc71" }} >{selectedUnit.rent_type}</Button>

                                    </>

                                }

                                {selectedUnit.is_public ?
                                    <Button className='btn btn-success ms-3 border-0 text-capitalize' style={{ backgroundColor: "#bdc3c7" }}>Publicly Listed</Button>
                                    :
                                    <>

                                    </>

                                }


                            </div>

                            <div className='selectedHouseStatus'>
                                <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#16a085" }} onClick={handleOpenShareUnitsModal}><i class="bi bi-share"></i> Shared {sharedUnit}</Button>
                                <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#3498db" }} onClick={handleOpenLikedUnitsModal} ><i class="bi bi-hand-thumbs-up"></i> Liked {likedUnits}</Button>


                            </div>

                            {/* the shared list */}
                            <Modal show={sharesUnitModal} onHide={handleCloseShareUnitsModal} size='xl'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Users who shared</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tasks ' >
                                        <div className='d-flex justify-content-between'>

                                            <div>
                                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVProperty}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                                                {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                                            </div>
                                            <Form className="searchBar">
                                                <FormControl type="text" placeholder="Search" onChange={handleSearchProperty} />
                                            </Form>
                                        </div>
                                        <Table className='table mt-2'>
                                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                                <th>Unit</th>
                                                <th>Shares</th>
                                                <th>Time Shared</th>
                                            </thead>
                                            <tbody>
                                                {displayedItemsProperty.length === 0 ?
                                                    <p>No user found </p>
                                                    :
                                                    displayedItemsProperty.map((user) => (
                                                        <tr>

                                                            <td>{user.name}</td>
                                                            <td>{user.count}</td>
                                                            <td>{new Date(user.latestShareTime).toLocaleDateString('en-US', {
                                                                month: 'long',
                                                                day: 'numeric',
                                                                year: '2-digit'
                                                            })}</td>

                                                        </tr>

                                                    ))
                                                }

                                            </tbody>
                                        </Table>
                                        <div className="pagination">
                                            <button onClick={handlePrevPageProperty} disabled={currentPageProperty === 0}>
                                                &lt;
                                            </button>
                                            <span>{`${startIndexProperty + 1}-${endIndexProperty} of ${userShareCounts.length}`}</span>
                                            <button onClick={handleNextPageProperty} disabled={currentPageProperty === totalPagesProperty - 1}>
                                                &gt;
                                            </button>
                                        </div>
                                    </div>



                                </Modal.Body>
                                <Modal.Footer>

                                    <Button variant="secondary" onClick={handleCloseShareUnitsModal} >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* the liked list */}
                            <Modal show={likedUnitModal} onHide={handleCloseLikedUnitsModal} size='xl'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Users who liked this unit</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tasks ' >
                                        <div className='d-flex justify-content-between'>

                                            <div>
                                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVLikes}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                                            </div>
                                            <Form className="searchBar">
                                                <FormControl type="text" placeholder="Search" onChange={handleSearchLikes} />
                                            </Form>
                                        </div>
                                        <Table className='table mt-2'>
                                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                                <th>User</th>
                                                <th>User Phone Number</th>
                                                <th>Liked Time</th>
                                            </thead>
                                            <tbody>
                                                {displayedItemsLikes.map((user) => (
                                                    <tr>

                                                        <td>{user.name}</td>
                                                        <td>{user.phone}</td>
                                                        <td>{new Date(user.latestLikedDate).toLocaleDateString('en-US', {
                                                            month: 'long',
                                                            day: 'numeric',
                                                            year: '2-digit'
                                                        })}</td>

                                                    </tr>

                                                ))
                                                }

                                            </tbody>
                                        </Table>
                                        <div className="pagination">
                                            <button onClick={handlePrevPageLikes} disabled={currentPageLikes === 0}>
                                                &lt;
                                            </button>
                                            <span>{`${startIndexLikes + 1}-${endIndexLikes} of ${userLikedUnit.length}`}</span>
                                            <button onClick={handleNextPageLikes} disabled={currentPageLikes === totalPagesLikes - 1}>
                                                &gt;
                                            </button>
                                        </div>
                                    </div>

                                    {/* <Table className='table table-bordered'>
                                        <thead>
                                            <th>User</th>
                                            <th>User Phone Number</th>
                                        </thead>
                                        {
                                            userLikedUnit.map((user) => (
                                                <tbody>
                                                    <td>
                                                        {user.name}
                                                    </td>
                                                    <td>{user.phone}

                                                    </td>
                                                    <td>{new Date(user.latestLikedDate).toLocaleDateString('en-US', {
                                                                month: 'long',
                                                                day: 'numeric',
                                                                year: '2-digit'
                                                            })}

                                                    </td>

                                                </tbody>
                                            ))
                                        }

                                    </Table> */}


                                </Modal.Body>
                                <Modal.Footer>

                                    <Button variant="secondary" onClick={handleCloseLikedUnitsModal} >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </div>


                        <h3 className='selectedHouseTenant'>{houseTenant.full_name?.toLowerCase()}</h3>
                        <Row className='selectedHouseDetails'>
                            <Col md={8} sm={12}>
                                {selectedUnit.type_rent_status === 'for_sale' ?
                                    (
                                        <p className='Rent float-left' style={{ fontSize: "1.1rem" }}>Ksh. {selectedUnit.rent?.toLocaleString()}</p>

                                    ) : (

                                        <p className='Rent float-left' style={{ fontSize: "1.1rem" }}>Ksh. {selectedUnit.rent?.toLocaleString()} per Month</p>

                                    )}

                                <h5 className='float-left'>Description</h5>
                                {selectedUnit.about === null ?
                                    <p className='descriptionHouseSelected'>About status is empty</p>
                                    :
                                    <p className='descriptionHouseSelected'>{selectedUnit.about}</p>
                                }
                                {/* house attributes */}
                                <Card className='selectedHouseMetrics'>
                                    <Card.Header className='text-start' style={{ color: "#f39c12" }}>
                                        ATTRIBUTES
                                    </Card.Header>
                                    <Card.Body>
                                        <Row >
                                            <Col md={6}>
                                                <span><Image src='https://kodinyumba.app/media/house_metric_icons/bedroom.png' style={{ width: "10%" }} /> Bedrooms
                                                    {selectedUnit.bedroom === null ?
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [0]</span>
                                                        :
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [{selectedUnit.bedroom}]</span>
                                                    }

                                                </span>
                                            </Col>
                                            <Col md={6}>
                                                <span><Image src='https://kodinyumba.app/media/house_metric_icons/bathroom.png' style={{ width: "10%" }} /> Bathrooms
                                                    {selectedUnit.bathroom === null ?
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [0]</span>
                                                        : selectedUnit.bathroom === 1001 ?
                                                            <span style={{ color: "#f39c12", fontWeight: "bold" }}> [All Ensuite]</span>
                                                            : selectedUnit.bathroom === 1111 ?
                                                                <span style={{ color: "#f39c12", fontWeight: "bold" }}> [Master Ensuite]</span>
                                                                :
                                                                <span style={{ color: "#f39c12", fontWeight: "bold" }}> [{selectedUnit.bathroom}]</span>
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} style={{ marginTop: "1rem" }}>
                                                {selectedUnit.occupancy_status === "vacant" ?
                                                    <span><Image src='https://kodinyumba.app/media/house_metric_icons/vacant.png' style={{ width: "10%", color: "#f39c12", fontWeight: "bold" }} /> Vacant House</span>
                                                    :
                                                    <span><Image src='https://kodinyumba.app/media/house_metric_icons/due-date.png' style={{ width: "10%" }} /> Due Date <span style={{ color: "#f39c12", fontWeight: "bold" }}>[{new Date(rentStatus.next_date).getDate()}]</span></span>
                                                }

                                            </Col>
                                            <Col md={6} style={{ marginTop: "1rem" }}>
                                                {selectedUnit.type_of_house.toLowerCase() === "apartment_units" ?
                                                    <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/apartment.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                    : selectedUnit.type_of_house.toLowerCase() === "studio" ?
                                                        <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/studio.png' style={{ width: "10%" }} />  {toCamelCase(selectedUnit.type_of_house)}</span>

                                                        : selectedUnit.type_of_house.toLowerCase() === "bungalow" ?
                                                            <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/bungalow.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                            : selectedUnit.type_of_house.toLowerCase() === "bedsitter" ?
                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/bedsitter.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                                : selectedUnit.type_of_house.toLowerCase() === "servant_quarters" ?
                                                                    <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/servant_quarter.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                                    : selectedUnit.type_of_house.toLowerCase() === "mansionette" ?
                                                                        <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/mansionette.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                                        : selectedUnit.type_of_house.toLowerCase() === "office" ?
                                                                            <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/office.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                                            : selectedUnit.type_of_house.toLowerCase() === "stall" ?
                                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/stall.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                                                :
                                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/stall.png' style={{ width: "10%" }} /> {toCamelCase(selectedUnit.type_of_house)}</span>

                                                }

                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* units attributes */}

                                <Card className='selectedHouseMetrics'>
                                    <Card.Header className='text-start' style={{ color: "#8e44ad" }}>
                                        WHAT THIS UNIT OFFERS
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            {unitAmenities.length > 0 ?
                                                unitAmenities.map((amenity) => (
                                                    <Col md={12} className="amenties text-start d-flex">
                                                        <Image src={amenity.icon_image} style={{ width: "5vw", height: "7vh", marginBottom: "1rem" }} />
                                                        <div className='mb-3'>
                                                            <p className='ms-3'> {amenity.icon}</p>
                                                            <span className='text-muted ms-3 d-flex '>{amenity.icon_description}</span>
                                                        </div>
                                                    </Col >
                                                ))
                                                :
                                                <p style={{ color: "#95a5a6" }}>No special amenity added for this house kindly add one</p>
                                            }
                                        </Row>
                                        <Row>
                                            <div className='amenitiesDiv'>

                                                {unitAmenities.length > 2 ? (
                                                    <>
                                                    </>
                                                )
                                                    :
                                                    (<>
                                                        <span><i className='bi bi-plus bg-primary text-white p-2' onClick={handleOpenModalAmenityAdd}></i></span>

                                                    </>)}



                                                {unitAmenities.length > 0 ? (
                                                    <>
                                                        <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalAmenityDelete}></i></span>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>
                                        </Row>

                                        {/* add the amenity to the apartment */}
                                        <Modal show={showModalAmenity} onHide={handleCloseModalAmenityAdd}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Add special amenities for this unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form onSubmit={handleAmenities}>
                                                    <div className="input-icons">

                                                        <FormSelect className="formProperty" value={formDataAmenity.icon} onChange={handleChangeAmenity}>
                                                            <option value="">Select amenity To Add</option>
                                                            {availableAmenities.map((amenity) => (
                                                                <option key={amenity.id} value={amenity.id}>
                                                                    {amenity.name}
                                                                </option>
                                                            ))}
                                                        </FormSelect>


                                                    </div>


                                                    <Button className='nextBtn mt-3' type='submit'>Save</Button>
                                                </Form>
                                            </Modal.Body>

                                        </Modal>


                                        {/* delete the selected amenity */}

                                        <Modal show={showModalAmenityDelete} onHide={handleCloseModalAmenityDelete}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Amenities For This Unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>


                                                <Form onSubmit={handleDeleteAmenity}>
                                                    <div className="input-icons">
                                                        <FormSelect
                                                            className="formProperty"
                                                            value={formDataAmenityDelete.amenity || ''} // Update the value property
                                                            onChange={handleChangeAmenityDelete('amenity')} // Pass the name as an argument
                                                        >
                                                            <option disabled value="">
                                                                Select Amenity To Delete
                                                            </option>
                                                            {unitAmenities.length > 0 ? (
                                                                unitAmenities.map((amenity) => (
                                                                    <option
                                                                        key={amenity.id}
                                                                        value={amenity.id}
                                                                    >
                                                                        {amenity.icon}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                                                            )}
                                                        </FormSelect>

                                                    </div>

                                                    <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                                                        Delete Amenity
                                                    </Button>
                                                </Form>


                                            </Modal.Body>

                                        </Modal>

                                    </Card.Body>
                                </Card>


                            </Col>

                            <Col md={4} sm={12}>

                                <Card className='selectedHouseTenantDetails mt-4'>
                                    <Card.Body className='noTenantCard' style={{ height: 'auto' }}>
                                        <h2>The rooms within this unit</h2>
                                        <Row>
                                            {unitRooms.length !== 0 ? (
                                                unitRooms.map((roomData) => (
                                                    <div className="amenties text-start" key={roomData.room.room_id}>
                                                        <span className='' style={{ fontSize: "1.3rem" }}>{roomData.room.room}</span>

                                                        {/* If there are more unit rooms in the group, display them */}
                                                        {roomData.data.length > 0 ? (
                                                            <div className='d-flex w-100 mt-4 mb-4' style={{ overflow: 'auto' }}>
                                                                {roomData.data.map((unitRoom, index) => (
                                                                    <div key={index}>
                                                                        {/* Display additional unit rooms in the group */}
                                                                        <Image src={unitRoom.room_image} className='unitRoomImage' />
                                                                        <span className='unitRoomDelete'
                                                                            onClick={() => openModal(unitRoom.room_image_id)}
                                                                            style={{
                                                                                position: 'relative',
                                                                                padding: '0.8rem',
                                                                                backgroundColor: 'red',
                                                                                borderRadius: '50%',
                                                                                marginRight: '-4rem',
                                                                                height: "fit-content",
                                                                            }}
                                                                        ><i className='bi bi-trash'></i></span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                            :
                                                            (
                                                                <>
                                                                    <br></br>
                                                                    <br></br>

                                                                    <span>Sorry no image for this room kindly add one</span>
                                                                    <br></br>
                                                                    <br></br>
                                                                </>
                                                            )}
                                                    </div>
                                                ))
                                            ) : (
                                                <span style={{ color: "#95a5a6" }}>This unit has no added rooms. Kindly add one.</span>
                                            )}

                                            {/* modal to delete image */}
                                            <Modal show={isModalOpen} onHide={closeModal}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Delete Image</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <p>Do you want to delete this image?</p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                                        Delete
                                                    </button>
                                                    <button className='btn btn-secondary' onClick={closeModal}>
                                                        Close
                                                    </button>
                                                </Modal.Footer>
                                            </Modal>
                                        </Row>

                                        <Row>
                                            <div className='amenitiesDiv'>
                                                <Link to={`/add-room/${id}`}>
                                                    <span><i className='bi bi-plus bg-primary text-white p-2'></i></span>

                                                </Link>

                                                {unitRooms.length !== 0 ? (
                                                    <>
                                                        <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalRoomDelete}></i></span>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>
                                        </Row>

                                        {/* delete the selected room */}

                                        <Modal show={showModalRoomsDelete} onHide={handleCloseModalRoomDelete}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Rooms For This Unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>


                                                <Form onSubmit={handleDeleteRoom}>
                                                    <div className="input-icons">
                                                        <FormSelect
                                                            className="formProperty"
                                                            value={formDataRoomDelete.room || ''}
                                                            onChange={handleChangeRoomDelete('room')}
                                                        >
                                                            <option disabled value="">
                                                                Select Room To Delete
                                                            </option>
                                                            {unitRooms.length !== 0 ? (
                                                                unitRooms.map((room) => (
                                                                    <option
                                                                        key={room.room.room_id}
                                                                        value={room.room.room_id}
                                                                    >
                                                                        {room.room.room}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <p>No Rooms Found For This Unit. Kindly Add One.</p>
                                                            )}
                                                        </FormSelect>

                                                    </div>

                                                    <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                                                        Delete Room
                                                    </Button>
                                                </Form>


                                            </Modal.Body>

                                        </Modal>

                                    </Card.Body>

                                </Card>
                            </Col>
                        </Row>
                    </div >)
                    :
                    (<>
                        <div className='mt-5 pt-5'>
                            <p className='mt-5 text-capitialize fw-bold fs-1' style={{ color: "#95a5a6" }}>House Not Found</p>
                        </div>
                    </>)

            }


        </>
    )
}

export default SelectedHouse