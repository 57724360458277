import React from 'react'
import Apartment from '../../../assets/img/apartment6.jpg'
import Apartment1 from '../../../assets/img/apartment2.jpg'
import Apartment5 from '../../../assets/img/apartment4.jpg'
import Apartment6 from '../../../assets/img/apartment8.jpg'
import Apartment7 from '../../../assets/img/apartment7.jpg'
import Apartment8 from '../../../assets/img/apartment9.jpg'
import User from '../../../assets/img/user_sqaure.jpg'
import User1 from '../../../assets/img/user1.jpg'
import User2 from '../../../assets/img/user2.png'
import User3 from '../../../assets/img/user3.jpg'

export  const ReportsApartment = [
    {
        id: '1',
        name: 'Johari Apartments',
        units: '10',
        occupancy: '50',
        income: '50,000',
        current_income: '25,750',
        image:Apartment5,
        location:'Runda',
        account:'Landlord',
        account_name:'Joy Wanjiru',
        account_image:User,
        
    },
    {
        id: '2',
        name: 'Grey Heights Apartments',
        units: '1',
        occupancy: '5',
        income: '5,000',
        current_income: '3,000',
        image:Apartment1,
        location:'Ruai',
        account:'Caretaker',
        account_name:'Peter Nyogesa',
        account_image:User2
    },
    {
        id: '3',
        name: 'Clairomnt Appartmet',
        units: '21',
        occupancy: '55',
        income: '70,000',
        current_income: '50,200',
        image:Apartment,
        location:'Ruiru',
        account:'Landlord',
        account_name:'Omeri Wendo',
        account_image:User1
    },
    {
        id: '4',
        name: 'Vintage Apartment',
        units: '16',
        occupancy: '89',
        income: '70,000',
        current_income: '67,000',
        image:Apartment6,
        location:'Juja',
        account:'Landlord',
        account_name:'Mwema Kioko',
        account_image:User2
    },
    {
        id: '5',
        name: 'Willemy Apartments',
        units: '12',
        occupancy: '45',
        income: '71,000',
        current_income: '45,000',
        image:Apartment7,
        location:'Donholm',
        account:'Caretaker',
        account_name:'Maryanne Wairimu',
        account_image:User
    },
    {
        id: '6',
        name: 'Bandari Apartments',
        units: '2',
        occupancy: '51',
        income: '27,000',
        current_income: '19,500',
        image:Apartment8,
        location:'Ndumberi',
        account:'Agent',
        account_name:'Philis Karanja',
        account_image:User3
        
    }
]
export default ReportsApartment