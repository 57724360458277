import React, { useState, useEffect } from "react";

import "./Login.css";
import { Link } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import { serverUrl } from "../../serverUrl";
import axios from 'axios';
import jwt_decode from "jwt-decode"

import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import TermsConditions from "../newDashboard/TermsConditions";
import Privacy from "../newDashboard/Privacy";

function Login() {
  // setting up the form with the data that it will contain
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });

  // this are the constants that are used in the setting up of the user's tokens
  let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
  let [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  // the counting of the phone number digits restricted to count only 10 digits
  const digitCount = formData.phoneNumber.replace(/[^0-9]/g, "").length;

  // the onchange function when some changes are done on a field
  const handleChange = (event) => {
    const { name, value } = event.target;

    // limit phone number input to 10 digits
    if (name === "phoneNumber") {
      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const yellowHappyFace = '\u{1F600}';
  // the main function to be carried out on submit of the form
  function handleSubmit(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    try {
      // first try and get the user if they are in the allianceMembers table and check if they can access
      // get the user id
      serverUrl.get(`/users/get-user/?phone_number=${formData.phoneNumber}`)
        .then((res) => {
          if (res.data.status === true) {
            const user_id = parseInt(res.data.user)
            serverUrl.get(`/landlord/alliance-members/?user=${user_id}&is_visible=true`)
              .then((res) => {
                if (res.data.count === 0) {
                  toast.error('You can not access your account you have been removed from the system')
                }
                else {
                  serverUrl
                    // hit endpoint and check if user is there and connect user
                    .post("/users/login/", {
                      phone_number: formData.phoneNumber,
                      password: formData.password,
                    })
                    .then((res) => {
                      // when user has been found get the access token and store the token and navigate them to the home page
                      const data = res.data.data
                      setAuthTokens(data.tokens)
                      setUser(jwt_decode(data.tokens.access))
                      console.log(setUser)
                      localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                      toast.success(`Welcome Back ${yellowHappyFace}`)
                      navigate('/dashboard')
                    })
                    .catch((error) => {
                      if (error.response && error.response.status === 401) {
                        toast.error('Wrong Credentials Check Again Credentials!')
                      } else {
                        console.log('Unexpected error: ' + error.message);
                      }
                    });
                }
              })
          }
          else {
            toast.error('You do not have an account with us. Kindly request one from your team leader')
          }

        })
        .catch((error) => {

        })


    } catch (error) {
      console.log(error.response)

    }
  }



  function handleLogout() {
    // clear auth tokens and user data on logout
    setAuthTokens(null);
    localStorage.removeItem('authTokens');
    navigate('/login')
  }


  function updateTokens() {
    // check if access token has expired and update tokens
    const decoded = jwt_decode(authTokens.access);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      axios
        .post('/users/token/refresh/', { refresh: authTokens.refresh })
        .then((res) => {
          handleSubmit(res.data.data.tokens);
        })
        .catch(() => {
          handleLogout();
        });
    }
  }

  useEffect(() => {
    // check if auth tokens exist and update tokens every 10 minutes
    if (authTokens) {
      const interval = setInterval(() => {
        updateTokens();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [authTokens]);

  // modal to display the terms and conditions
  const [showModalTerms, setShowModalTerms] = useState(false);

  const handleOpenModalTerms = () => {
    setShowModalTerms(true);
  };

  const handleCloseModalTerms = () => {
    setShowModalTerms(false);
  };

  // modal to display the privacy
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);

  const handleOpenModalPrivacy = () => {
    setShowModalPrivacy(true);
  };

  const handleCloseModalPrivacy = () => {
    setShowModalPrivacy(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  return (
    <div className="loginForm">
      <h2>Welcome Back to Alliance Realtors Limited</h2>
      <p className="text-muted">Best tool to manage your property</p>
      <Form onSubmit={handleSubmit}>
        <div className="input-icons">
          <i className="fa fa-phone icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <p
          style={{
            fontSize: "12px",
            paddingRight: "20px",
            float: "right",
          }}
        >
          {digitCount}/10
        </p>

        <div className="input-icons">
          <i className="fa fa-lock icon"></i>
          <input
            className="input-field"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
            {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "10px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "10px" }} />}
          </span>

        </div>

        <Link to="/forgot-password" className="forgotPassword">
          Change Password?
        </Link>

        <Button variant="success" type="submit" className="loginBtn">
          Login

        </Button>

        <div className="termsAndConditions">
          <h6>By creating an account, you are agreeing to our</h6>

          <h6>
            <Link className="terms" onClick={handleOpenModalTerms}>
              Terms & Conditions
            </Link>{" "}
            {/* Terms and conditions modal*/}
            <Modal show={showModalTerms} onHide={handleCloseModalTerms}>
              <Modal.Header closeButton>
                <Modal.Title>Terms And Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TermsConditions />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalTerms}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            and
            <Link className="privatePolicy" onClick={handleOpenModalPrivacy}>
              {" "}
              Privacy Policy
            </Link>
            <Modal show={showModalPrivacy} onHide={handleCloseModalPrivacy}>
              <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Privacy />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalPrivacy}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </h6>
        </div>
      </Form>
    </div>
  );
}

export default Login;
