import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";

// styles
import './WalletLogin.scss';

// importing components
import HomeCarousel from '../../components/HomeCarousel';
import HomeFooter from '../../components/HomeFooter';

function WalletOtp() {
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 13) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 13) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/LandingPage/${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    const images = [backgroundImageApartment];

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    return (
        <div className='homeCard'>
            <div className="image-container">
                <HomeCarousel images={images} className="imageHome" />
                <div className="wave"></div>
            </div>
            <div className='main-content '>
                <h2 className='text-capitalize fs-5 fw-bold mt-3'>Kindly enter your otp</h2>

                <div className="input-icons">
                    <i className="fa fa-hashtag icon"></i>
                    <input
                        className="input-field"
                        type="number"
                        placeholder="Enter Otp"
                        name="phoneNumber"
                        // value={formData.phoneNumber}
                        // onChange={handleChange}
                        required
                    />
                </div>
                {/* <p
    style={{
        fontSize: "12px",
        paddingRight: "20px",
        float: "right",
    }}
>
    {digitCount}/10
</p> */}

                {/* <div className="input-icons">
                    <i className="fa fa-lock icon"></i>
                    <input
                        className="input-field"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        // value={formData.password}
                        // onChange={handleChange}
                        required
                    />
                    <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "10px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "10px" }} />}
                    </span>

                </div> */}

                <Button variant="primary" type="submit" className="loginBtn" href='#/home'>
                    Login

                </Button>
            </div>

        </div>
    );
}

export default WalletOtp;
