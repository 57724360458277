import React, { useState, useEffect } from 'react'
import { Button, Table, Form, FormControl, Modal, FormSelect } from 'react-bootstrap';
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
function WalletCheckers() {

    const [addTemplate, setAddTemplate] = useState(false);

    const handleReminderModalOpen = () => {
        setAddTemplate(true);
    };

    const handleReminderModalClose = () => {
        setAddTemplate(false);
    };

    const [templates, setTemplate] = useState([])
    const [landlord, setLanlordId] = useState('')

    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    useEffect(() => {
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                setLanlordId(landlordIds.toString())
                // get the total number of sms templates
                serverUrl.get(`/communication/get_landlord_message_templates/?landlord_id=${landlordIds.toString()}`, config)
                    .then((res) => {
                        setTemplate(res.data.results)
                    })
                    .catch((error) => {
                        console.log(res)
                    })
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const [formData, setFormData] = useState({
        subject: "",
        content: "",

    });

    //   handling the change of the data in the form
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    // submit the data to add the template
    function handleAddTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        // data to be sent to the backend
        const data = {
            title: formData.subject,
            text: formData.content,
            created_by_message_template: userId,
            updated_by_message_template: userId
        }
        // send the data to the backend 
        try {
            serverUrl.post('/communication/message-templates/', data, config)
                .then((res) => {
                    toast.success("Template added successfully!")
                    handleReminderModalClose()

                    setTemplate((prevTemplates) => [...prevTemplates, res.data]);

                    setFormData({
                        subject: "",
                        content: "",
                    })
                })
                .catch((error) => {
                    toast.error(error.response.data.error)
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    // delete the template
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedTemplateId, setSelectedTemplateId] = useState(null)

    const handleDeleteModalOpen = (templateId) => {
        setDeleteModal(true);
        setSelectedTemplateId(templateId)
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    function handleDeleteTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        // delete the message template that is selected
        serverUrl.delete(`/communication/message-templates/${selectedTemplateId}/`, config)
            .then((res) => {
                // Update the state to remove the deleted template
                setTemplate((prevTemplates) =>
                    prevTemplates.filter((template) => template.id !== selectedTemplateId)
                );
                handleDeleteModalClose()
                toast.success("Successfully deleted the template!")

            })
            .catch((error) => {
                console.log(error)
            })
    }

    // update the template
    const [updateModal, setUpdateModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const handleUpdateModalOpen = (templateId) => {
        setUpdateModal(true);
        setSelectedTemplate(templateId)
    };

    const handleUpdateModalClose = () => {
        setUpdateModal(false);
    };

    const [formDataEdit, setFormDataEdit] = useState({
        subject: "",
        content: "",

    });

    //   handling the change of the data in the form
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormDataEdit((prevFormDataEdit) => ({
            ...prevFormDataEdit,
            [name]: value,
        }));
    };

    function handleUpdateTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        // update the message template that is selected
        // data to be sent to the backend
        const data = {
            title: formDataEdit.subject,
            text: formDataEdit.content,
            created_by_message_template: userId,
            updated_by_message_template: userId
        }

        serverUrl.patch(`/communication/message-templates/${selectedTemplate}/`, data, config)
            .then((res) => {
                // Update the local template state manually
                const updatedTemplate = res.data;
                const updatedTemplates = templates.map(template =>
                    template.id === updatedTemplate.id ? updatedTemplate : template
                );
                setTemplate(updatedTemplates);

                handleUpdateModalClose()
                toast.success("Successfully updated the template!")

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const exportCSV = () => {
        const csvData = [
            ['Name', 'Phone Number', 'Authorizer Type'],
            ...authorizer.map((item) => [item.name, item.phone, item.type]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Authorizers.csv');
    };

    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const authorizer = [
        {
            name: "Faith Mwende",
            phone: "075124562",
            type: "checker",
        },
        {
            name: "Alex Mwendwa",
            phone: "075124562",
            type: "Maker",
        },
    ]

    // const sortedData = templates.sort((a, b) => {
    const sortedData = authorizer.sort((a, b) => {

        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const handleRowClick = (item) => {
        setSelectedRow(item);
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    return (
        <>
            {/* list of the tenants of the apartment and payment details */}
            <div className='tasks mt-5'>
                <h2 style={{ color: "#e67e22" }}>Wallet Checkers </h2>

                <div className='d-flex justify-content-between '>
                    <p></p>
                    <Button className='btn btn-sm float-end' onClick={handleReminderModalOpen}>Add Authorizer</Button>
                </div>

                <div className='d-flex justify-content-between mt-2'>

                    <div>
                        <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                    </div>
                    <Form className="searchBar">
                        <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                    </Form>
                </div>
                <Table className='table mt-2'>
                    <thead className='underline'>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Authorizer Type</th>
                        <th>Actions</th>



                    </thead>
                    <tbody>
                        {displayedItems.map((template) => (
                            <tr >
                                <td>{template.name}</td>
                                <td>{template.phone}</td>
                                <td>{template.type}</td>

                                <td>
                                    <i
                                        className='bi bi-trash-fill text-danger'
                                        onClick={() => handleDeleteModalOpen(template.id)}
                                    ></i>
                                </td>
                                {/* modal to delete the template */}
                                <Modal show={deleteModal} onHide={handleDeleteModalClose}>
                                    <Modal.Header className='bg-danger text-white' closeButton>

                                        <Modal.Title>Delete Authorizer?</Modal.Title>

                                    </Modal.Header>
                                    <Modal.Body>

                                        <Form onSubmit={handleDeleteTemplate}>
                                            <p> Are you sure you want to delete this Authorizer?</p>

                                            <Modal.Footer className="d-flex justify-content-between">
                                                <Button variant='danger' type='submit'>
                                                    Yes
                                                </Button>

                                                <Button variant='success' onClick={handleDeleteModalClose}>
                                                    No
                                                </Button>

                                            </Modal.Footer>
                                        </Form>


                                    </Modal.Body>

                                </Modal>
                            </tr>
                        ))}




                    </tbody>
                    {/* modal for adding authorizers */}
                    <Modal show={addTemplate} onHide={handleReminderModalClose}>
                        <Modal.Header className='bg-primary text-white' closeButton>

                            <Modal.Title>Add Authorizer</Modal.Title>

                        </Modal.Header>
                        <Modal.Body>

                            <>
                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Authorizer</label>

                                    <FormSelect>
                                        <option selected disabled>Select authorizer to add</option>
                                        <option>Joy Marianne</option>
                                        <option>Trevor Noah</option>
                                        <option>Beatrice Wendo</option>
                                    </FormSelect>
                                </div>
                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Authorizer Type</label>

                                    <FormSelect>
                                        <option selected disabled>Select authorizer to add</option>
                                        <option>Maker</option>
                                        <option>Checker</option>
                                    </FormSelect>
                                </div>
                                {/* <Form onSubmit={handleAddTemplate}>
                                    <div className="input-icons  mt-2 mb-2">
                                        <i class="bi bi-chat-left-dots icon"></i>
                                        <textarea className="input-field" rows={1} placeholder='Enter Title' name='subject' onChange={handleChange} value={formData.subject} ></textarea>
                                    </div>

                                    <div className="input-icons">
                                        <i class="bi bi-send-plus icon"></i>
                                        <textarea className="input-field" placeholder='Enter Message' name='content' onChange={handleChange} value={formData.content} ></textarea>
                                    </div>
                                    <Button variant='success' type='submit' className='float-end'>
                                        Confirm
                                    </Button>
                                </Form> */}
                            </>


                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button variant='primary' onClick={handleReminderModalClose}>
                                Add
                            </Button>
                            <Button variant='danger' onClick={handleReminderModalClose}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </Table>
                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 0}>
                        &lt;
                    </button>
                    <span>{`${startIndex + 1}-${endIndex} of ${authorizer.length}`}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        &gt;
                    </button>
                </div>
            </div>
        </>
    )
}

export default WalletCheckers