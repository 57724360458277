import React, { useState, useEffect } from 'react'
import './AddProperty.css'
import { Form, Button, FormSelect } from "react-bootstrap";
import { toast } from 'react-toastify';

// auth imports

import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function PageAddApartment() {
  const navigate = useNavigate()
  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    about: "",
    county: "",
    longitude: "",
    latitude: ""

  });

  const MAX_DESCRIPTION_LENGTH = 1024;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'about' && value.length > MAX_DESCRIPTION_LENGTH) {
      // Truncate the input to the maximum allowed length
      const truncatedValue = value.substring(0, MAX_DESCRIPTION_LENGTH);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: truncatedValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const [counties, setCounties] = useState([])
  useEffect(() => {
    serverUrl.get(`/landlord/county`)
      .then((res) => {
        setCounties(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const [formDataCounty, setFormDataCounty] = useState({
    county: "",

  });
  const handleCounty = (name) => (event) => {
    const { value } = event.target;
    setFormDataCounty((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  // when submitting the form it's functionallities
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can link with the Apartment from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the data that will be posted to the api is as follows
    const data = {
      name: formData.name,
      location: formData.location,
      about: formData.about,
      latitude: formData.latitude,
      longitude: formData.longitude,

      county: formDataCounty.county

    };

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }



    //  now post the data
    try {

      // get the landlord using the user id
      serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
          const landlordId = res.data.results[0].id
          //  add the data of the apartment to the apartment details
          serverUrl.post("/landlord/apartment/", data, config)
            .then((res) => {
              // setting the Apartment details
              localStorage.setItem('apartment', JSON.stringify(res.data.id))
              //  landlord details
              const Apartment = res.data.id

              const landlordApartment = {
                landlord: landlordId,
                apartment: Apartment
              }

              serverUrl
                .post("/landlord/landlord-apartment/", landlordApartment, config)
                .then((res) => {
                })
              toast.success('Successfully Added Apartment!');
              navigate('/property')

            })

            // displaying the messages of why the error 
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                alert('Error while adding property' + error.message);
              } else {
                toast.error('Unexpected error')
                console.log('Unexpected error: ' + error.message);
              }
            });
        })
        .catch((error) => {
          console.log(error)
        })


    } catch (error) {
      console.log(error);
    }

  }
  return (
    <>

      <div className='d-flex justify-content-'>
        <Link to={`/property`} className='addHouse ms-2'><i className='bi bi-arrow-left'></i></Link>
      </div>

      <div className="registerForm">
        <h2>Create Your Property</h2>

        <Form onSubmit={handleSubmit}>
          <div className="input-icons">
            <i className="fa fa-hashtag icon"></i>
            <input
              className="input-field"
              type="text"
              placeholder="Property Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-icons">
            <i className="fa fa-hashtag icon"></i>
            <input
              className="input-field"
              type="location"
              placeholder="Add Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </div>


          <FormSelect
            className="formProperty input-icons CountyDiv"
            value={formDataCounty.county}
            onChange={handleCounty('county')}
          >
            <option disabled value="">
              Select County
            </option>
            {counties.map((county) => (
              <option
                key={county.name}
                value={county.name}
                className="input-field"

              >
                {county.county}
              </option>
            ))}
          </FormSelect>

          <div className="input-icons">
            <i className="fa fa-hashtag icon"></i>
            <input
              className="input-field"
              type="number"
              placeholder="Property Latitude's"
              name="latitude"
              value={formData.latitude}
              onChange={handleChange}

            />
          </div>

          <div className="input-icons">
            <i className="fa fa-hashtag icon"></i>
            <input
              className="input-field"
              type="number"
              placeholder="Property Longitude's"
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}

            />
          </div>

          <div className="input-icons">
            <i className="fa fa-hashtag icon"></i>
            <textarea
              className="input-field"
              type="text"
              placeholder="About/ Description"
              name="about"
              value={formData.about}
              onChange={handleChange}

              style={{ height: "320px" }}
            />
          </div>
          
          <span className='charactersRemaining'>
            {MAX_DESCRIPTION_LENGTH - formData.about.length} characters remaining
          </span>



          <Button variant="primary" type="submit" className="nextBtn">
            Add Property
          </Button>
        </Form>

      </div>
    </>
  )
}

export default PageAddApartment