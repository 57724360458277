import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HomeFooter.css";

function HomeFooter() {
  return (
    <div className="footer_landing">
      <div className="d-flex justify-content-around buttons_footer">
       
        <Link to="/login" className="login">
          <Button
            variant="primary"
            size="md"
            className="bg-transparent border-0 text-white"
          >
            Login
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HomeFooter;
