import React, { useState, useEffect } from 'react';
import Header from './Header';
import SideBar from './SideBar';
import './ApproveProperty.scss';
import { Button, FormControl, Form, Table, Modal, Tab, Tabs, Carousel } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";


function ApproveProperty() {
    const [showSidebar, setShowSidebar] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [apartments, setApartments] = useState([]);
    const [unApproved, setUnlistedApartments] = useState([]);

    const [contacts, setContacts] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState(null); // State for selected property
    const [showPropertyModal, setShowPropertyModal] = useState(false); // State for controlling modal visibility
    const [apartmentImages, setApartmentImages] = useState([]);
    const [apartmentUnits, setApartmentUnits] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]); // State for selected property IDs
    const [showApprovalModal, setShowApprovalModal] = useState(false); // State for controlling approval modal visibility

    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    };
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    useEffect(() => {
        serverUrl.get(`landlord/alliance-members`, config)
            .then((res) => {
                const promises = res.data.results.map((member) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${member.landlord}`, config)
                        .then((res) => {
                            // Filter apartments where is_listed is true
                            const listedApartments = res.data.results.filter(apartment => apartment.is_listed);
                            // Filter apartments where is_listed is false
                            const unlistedApartments = res.data.results.filter(apartment => !apartment.is_listed);
                
                            // Map the filtered apartments and add full_name to each
                            const mappedListedApartments = listedApartments.map(apartment => ({
                                ...apartment,
                                full_name: member.user.full_name
                            }));
                
                            // Map the unlisted apartments and add full_name to each
                            const mappedUnlistedApartments = unlistedApartments.map(apartment => ({
                                ...apartment,
                                full_name: member.user.full_name
                            }));
                
                            return {
                                listedApartments: mappedListedApartments,
                                unlistedApartments: mappedUnlistedApartments
                            };
                        })
                        .catch((error) => {
                            console.log(error);
                            return {
                                listedApartments: [],
                                unlistedApartments: []
                            };
                        });
                });
                
                Promise.all(promises)
                    .then((apartmentResults) => {
                        // Flatten the array of arrays into a single array for listed apartments
                        const allListedApartments = apartmentResults.map(result => result.listedApartments).flat();
                        setApartments(allListedApartments);
                
                        // For unlisted apartments, flatten the array of arrays into a single array
                        const allUnlistedApartments = apartmentResults.map(result => result.unlistedApartments).flat();
                        setUnlistedApartments(allUnlistedApartments);
                
                        // Now fetch contact information for both listed and unlisted apartments
                        const allApartments = [...allListedApartments, ...allUnlistedApartments];
                        const contactPromises = allApartments.map((apartment) => {
                            return serverUrl.get(`/landlord/contact-apartment-booking/?apartment=${apartment.id}`, config)
                                .then((res) => {
                                    return res.data.results;
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return [];
                                });
                        });
                
                        Promise.all(contactPromises)
                            .then((contactResults) => {
                                const allContacts = contactResults.flat();
                                setContacts(allContacts);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
            setSelectedProperties([]);
        } else {
            setSelectedRows(apartments.map(apartment => apartment.id));
            setSelectedProperties(apartments.map(apartment => apartment.id));
        }
        setSelectAll(!selectAll);
    };

    const handleRowSelect = (apartmentId) => {
        if (selectedRows.includes(apartmentId)) {
            setSelectedRows(selectedRows.filter(id => id !== apartmentId));
            setSelectedProperties(selectedProperties.filter(id => id !== apartmentId));
        } else {
            setSelectedRows([...selectedRows, apartmentId]);
            setSelectedProperties([...selectedProperties, apartmentId]);
        }
    };

    // unapprove all
    const handleUnapproveAll = () => {
        const selectedApartmentIds = apartments.map(apartment => apartment.id);
        setSelectedRows(selectedApartmentIds); // Select all properties
        setSelectedProperties(selectedApartmentIds); // Update selected properties state
        setShowApprovalModal(true); // Show approval modal
    };
    
    // const handleUnapproveAll = () => {
    //     // Update is_listed status to false for all selected properties
    //     const unapprovePromises = selectedProperties.map(property => {
    //         // const data = {
    //         //     is_listed: false
    //         // };
    //         // return serverUrl.patch(`/landlord/apartment/${property.id}/`, data, config);
    //     });
    
    //     // Wait for all properties to be unapproved
    //     Promise.all(unapprovePromises)
    //         .then((responses) => {
    //             // Handle successful unapproval
    //             toast.success('Successfully unapproved all selected properties!');
    //             // Fetch updated data immediately after unapproval
    //             serverUrl.get(`landlord/alliance-members`, config)
    //                 .then((res) => {
    //                     const promises = res.data.results.map((member) => {
    //                         return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${member.landlord}`, config)
    //                             .then((res) => {
    //                                 // Filter apartments where is_listed is true
    //                                 const listedApartments = res.data.results.filter(apartment => apartment.is_listed);
    //                                 // Filter apartments where is_listed is false
    //                                 const unlistedApartments = res.data.results.filter(apartment => !apartment.is_listed);
    
    //                                 // Map the filtered apartments and add full_name to each
    //                                 const mappedListedApartments = listedApartments.map(apartment => ({
    //                                     ...apartment,
    //                                     full_name: member.user.full_name
    //                                 }));
    
    //                                 // Map the unlisted apartments and add full_name to each
    //                                 const mappedUnlistedApartments = unlistedApartments.map(apartment => ({
    //                                     ...apartment,
    //                                     full_name: member.user.full_name
    //                                 }));
    
    //                                 return {
    //                                     listedApartments: mappedListedApartments,
    //                                     unlistedApartments: mappedUnlistedApartments
    //                                 };
    //                             })
    //                             .catch((error) => {
    //                                 console.log(error);
    //                                 return {
    //                                     listedApartments: [],
    //                                     unlistedApartments: []
    //                                 };
    //                             });
    //                     });
    
    //                     Promise.all(promises)
    //                         .then((apartmentResults) => {
    //                             // Flatten the array of arrays into a single array for listed apartments
    //                             const allListedApartments = apartmentResults.map(result => result.listedApartments).flat();
    //                             setApartments(allListedApartments);
    
    //                             // For unlisted apartments, flatten the array of arrays into a single array
    //                             const allUnlistedApartments = apartmentResults.map(result => result.unlistedApartments).flat();
    //                             setUnlistedApartments(allUnlistedApartments);
    
    //                             // Now fetch contact information for both listed and unlisted apartments
    //                             const allApartments = [...allListedApartments, ...allUnlistedApartments];
    //                             const contactPromises = allApartments.map((apartment) => {
    //                                 return serverUrl.get(`/landlord/contact-apartment-booking/?apartment=${apartment.id}`, config)
    //                                     .then((res) => {
    //                                         return res.data.results;
    //                                     })
    //                                     .catch((error) => {
    //                                         console.log(error);
    //                                         return [];
    //                                     });
    //                             });
    
    //                             Promise.all(contactPromises)
    //                                 .then((contactResults) => {
    //                                     const allContacts = contactResults.flat();
    //                                     setContacts(allContacts);
    //                                 })
    //                                 .catch((error) => {
    //                                     console.log(error);
    //                                 });
    
    //                         })
    //                         .catch((error) => {
    //                             console.log(error);
    //                         });
    
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                 });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    
    //     // Close the approval modal
    //     setShowApprovalModal(false);
    // };
    

    // approve all
    const handleApproveAll = () => {
        setSelectedRows(apartments.map(apartment => apartment.id)); // Select all properties
        setSelectedProperties(apartments.map(apartment => apartment.id)); // Update selected properties state
        setShowApprovalModal(true);
    };

    const handleApprove = () => {
        console.log("Selected properties:", selectedProperties);
        // Implement approval logic here
    };

    const handleViewProperty = (property) => {
        setSelectedProperty(property);
        setShowPropertyModal(true);
        // get the property images
        serverUrl.get(`/landlord/apartment-images/?apartment_id=${property.id}`, config)
            .then((res) => {
                setApartmentImages(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });

        // get the property units
        serverUrl.get(`/landlord/house/?apartment_id=${property.id}`, config)
            .then((res) => {
                setApartmentUnits(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // approve the selected property
    const handleApproveProperty = (property) => {
        const data = {
            is_listed: true
        }

        serverUrl.patch(`/landlord/apartment/${property.id}/`, data, config)
            .then((res) => {
                toast.success('Succesfully Approved the property!')
                serverUrl.get(`landlord/alliance-members`, config)
                    .then((res) => {
                        const promises = res.data.results.map((member) => {
                            return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${member.landlord}`, config)
                                .then((res) => {
                                    // Filter apartments where is_listed is true
                                    const listedApartments = res.data.results.filter(apartment => apartment.is_listed);
                                    // Filter apartments where is_listed is false
                                    const unlistedApartments = res.data.results.filter(apartment => !apartment.is_listed);
                        
                                    // Map the filtered apartments and add full_name to each
                                    const mappedListedApartments = listedApartments.map(apartment => ({
                                        ...apartment,
                                        full_name: member.user.full_name
                                    }));
                        
                                    // Map the unlisted apartments and add full_name to each
                                    const mappedUnlistedApartments = unlistedApartments.map(apartment => ({
                                        ...apartment,
                                        full_name: member.user.full_name
                                    }));
                        
                                    return {
                                        listedApartments: mappedListedApartments,
                                        unlistedApartments: mappedUnlistedApartments
                                    };
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return {
                                        listedApartments: [],
                                        unlistedApartments: []
                                    };
                                });
                        });
                        
                        Promise.all(promises)
                            .then((apartmentResults) => {
                                // Flatten the array of arrays into a single array for listed apartments
                                const allListedApartments = apartmentResults.map(result => result.listedApartments).flat();
                                setApartments(allListedApartments);
                        
                                // For unlisted apartments, flatten the array of arrays into a single array
                                const allUnlistedApartments = apartmentResults.map(result => result.unlistedApartments).flat();
                                setUnlistedApartments(allUnlistedApartments);
                        
                                // Now fetch contact information for both listed and unlisted apartments
                                const allApartments = [...allListedApartments, ...allUnlistedApartments];
                                const contactPromises = allApartments.map((apartment) => {
                                    return serverUrl.get(`/landlord/contact-apartment-booking/?apartment=${apartment.id}`, config)
                                        .then((res) => {
                                            return res.data.results;
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            return [];
                                        });
                                });
                        
                                Promise.all(contactPromises)
                                    .then((contactResults) => {
                                        const allContacts = contactResults.flat();
                                        setContacts(allContacts);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                        
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    };

    // unapprove the selected property
    const handleUnApproveProperty = (property) => {
        const data = {
            is_listed: false
        }

        serverUrl.patch(`/landlord/apartment/${property.id}/`, data, config)
            .then((res) => {
                toast.success('Succesfully unapproved the property!')
                serverUrl.get(`landlord/alliance-members`, config)
                    .then((res) => {
                        const promises = res.data.results.map((member) => {
                            return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${member.landlord}`, config)
                                .then((res) => {
                                    // Filter apartments where is_listed is true
                                    const listedApartments = res.data.results.filter(apartment => apartment.is_listed);
                                    // Filter apartments where is_listed is false
                                    const unlistedApartments = res.data.results.filter(apartment => !apartment.is_listed);
                        
                                    // Map the filtered apartments and add full_name to each
                                    const mappedListedApartments = listedApartments.map(apartment => ({
                                        ...apartment,
                                        full_name: member.user.full_name
                                    }));
                        
                                    // Map the unlisted apartments and add full_name to each
                                    const mappedUnlistedApartments = unlistedApartments.map(apartment => ({
                                        ...apartment,
                                        full_name: member.user.full_name
                                    }));
                        
                                    return {
                                        listedApartments: mappedListedApartments,
                                        unlistedApartments: mappedUnlistedApartments
                                    };
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return {
                                        listedApartments: [],
                                        unlistedApartments: []
                                    };
                                });
                        });
                        
                        Promise.all(promises)
                            .then((apartmentResults) => {
                                // Flatten the array of arrays into a single array for listed apartments
                                const allListedApartments = apartmentResults.map(result => result.listedApartments).flat();
                                setApartments(allListedApartments);
                        
                                // For unlisted apartments, flatten the array of arrays into a single array
                                const allUnlistedApartments = apartmentResults.map(result => result.unlistedApartments).flat();
                                setUnlistedApartments(allUnlistedApartments);
                        
                                // Now fetch contact information for both listed and unlisted apartments
                                const allApartments = [...allListedApartments, ...allUnlistedApartments];
                                const contactPromises = allApartments.map((apartment) => {
                                    return serverUrl.get(`/landlord/contact-apartment-booking/?apartment=${apartment.id}`, config)
                                        .then((res) => {
                                            return res.data.results;
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            return [];
                                        });
                                });
                        
                                Promise.all(contactPromises)
                                    .then((contactResults) => {
                                        const allContacts = contactResults.flat();
                                        setContacts(allContacts);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                        
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    };

    const filteredData = apartments.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);


    // unapproved
    const filteredDataunapproved = unApproved.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const [currentPageunapproved, setCurrentPageunapproved] = useState(0);
    const itemsPerPageunapproved = 10;
    const totalPagesunapproved = Math.ceil(filteredDataunapproved.length / itemsPerPageunapproved);
    const startIndexunapproved = currentPageunapproved * itemsPerPageunapproved;
    const endIndexunapproved = currentPageunapproved === totalPagesunapproved - 1 ? filteredDataunapproved.length : startIndexunapproved + itemsPerPageunapproved;
    const displayedItemsunapproved = filteredDataunapproved.slice(startIndexunapproved, endIndexunapproved);

    const [activeTab, setActiveTab] = useState('roles');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div>
            <Header showSidebar={showSidebar} onButtonClick={() => setShowSidebar(!showSidebar)} />
            {showSidebar && <SideBar />}

            <div className='property approveproperty'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>APPROVE PROPERTY POSTED</p>
                </div>


                <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="nav-pills d-flex">
                    <Tab eventKey="roles" title="Approved Properties" >
                        <div className='tasks ' >
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <Button className='btn btn-sm btn-warning text-white' onClick={handleUnapproveAll} disabled={selectedRows.length !== apartments.length}>
                                        UnApprove All
                                    </Button>
                                </div>
                                <Form className="searchBar">
                                    <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                                </Form>
                            </div>

                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <Table className='table mt-2'>
                                    <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                        <tr>
                                            <th>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                />
                                            </th>
                                            <th>Property Name</th>
                                            <th>Property Contact</th>
                                            <th>Manged By</th>
                                            <th>Posted By</th>
                                            <th>Date Posted</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedItems.map((property) => {
                                            const propertyContacts = contacts.filter(contact => contact.apartment === property.id);
                                            const contactNumbers = propertyContacts.map(contact => contact.contact_number);
                                            const contactName = propertyContacts.map(contact => contact.contact_name);
                                            return (
                                                <tr key={property.name}>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={selectedRows.includes(property.id)}
                                                            onChange={() => handleRowSelect(property.id)}
                                                        />
                                                    </td>
                                                    <td>{property.name}</td>
                                                    <td>
                                                        {propertyContacts.length === 0 ? (
                                                            <span style={{ color: 'red' }}>No contacts</span>
                                                        ) : (
                                                            [...new Set(contactNumbers)].join(', ')
                                                        )}
                                                    </td>
                                                    <td>
                                                        {propertyContacts.length === 0 ? (
                                                            <span style={{ color: 'red' }}>No contacts</span>
                                                        ) : (
                                                            [...new Set(contactName)].join(', ')
                                                        )}
                                                    </td>
                                                    <td>{property.full_name}</td>
                                                    <td>{new Date(property.created_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</td>
                                                    <td>
                                                        {property.is_listed === true ?
                                                            <span className='text-success'>Approved</span>
                                                            :
                                                            <span className='text-warning'>Not Approved</span>
                                                        }
                                                    </td>
                                                    <td >
                                                        <Button className='btn btn-sm btn-success text-white me-2' onClick={() => handleViewProperty(property)}><i className='bi bi-eye'></i>View</Button>

                                                        <Button className='btn btn-sm btn-danger text-white me-2' onClick={() => handleUnApproveProperty(property)}><i className='bi bi-x'></i>Unapprove</Button>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            )}

                            <div className="pagination">
                                {/* Add pagination controls here */}
                            </div>
                        </div>
                    </Tab>


                    <Tab eventKey="assignedRoles" title="Unapproved Properties">
                    <div className='tasks ' >
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <Button className='btn btn-sm btn-warning text-white' onClick={handleApproveAll} disabled={selectedRows.length !== apartments.length}>
                                        Approve All
                                    </Button>
                                </div>
                                <Form className="searchBar">
                                    <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                                </Form>
                            </div>

                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <Table className='table mt-2'>
                                    <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                        <tr>
                                            <th>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                />
                                            </th>
                                            <th>Property Name</th>
                                            <th>Property Contact</th>
                                            <th>Manged By</th>
                                            <th>Posted By</th>
                                            <th>Date Posted</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayedItemsunapproved.map((property) => {
                                            const propertyContacts = contacts.filter(contact => contact.apartment === property.id);
                                            const contactNumbers = propertyContacts.map(contact => contact.contact_number);
                                            const contactName = propertyContacts.map(contact => contact.contact_name);
                                            return (
                                                <tr key={property.name}>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={selectedRows.includes(property.id)}
                                                            onChange={() => handleRowSelect(property.id)}
                                                        />
                                                    </td>
                                                    <td>{property.name}</td>
                                                    <td>
                                                        {propertyContacts.length === 0 ? (
                                                            <span style={{ color: 'red' }}>No contacts</span>
                                                        ) : (
                                                            [...new Set(contactNumbers)].join(', ')
                                                        )}
                                                    </td>
                                                    <td>
                                                        {propertyContacts.length === 0 ? (
                                                            <span style={{ color: 'red' }}>No contacts</span>
                                                        ) : (
                                                            [...new Set(contactName)].join(', ')
                                                        )}
                                                    </td>
                                                    <td>{property.full_name}</td>
                                                    <td>{new Date(property.created_at).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}</td>
                                                    <td>
                                                        {property.is_listed === true ?
                                                            <span className='text-success'>Approved</span>
                                                            :
                                                            <span className='text-warning'>Not Approved</span>
                                                        }
                                                    </td>
                                                    <td >
                                                        <Button className='btn btn-sm btn-success text-white me-2' onClick={() => handleViewProperty(property)}><i className='bi bi-eye'></i>View</Button>

                                                        <Button className='btn btn-sm btn-success text-white me-2' onClick={() => handleApproveProperty(property)}><i className='bi bi-check'></i>Approve</Button>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            )}

                            <div className="pagination">
                                {/* Add pagination controls here */}
                            </div>
                        </div>

                    </Tab>
                </Tabs>




            </div>

            {/* Modal for displaying property details */}
            <Modal show={showPropertyModal} onHide={() => setShowPropertyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Property Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedProperty && (
                        <>
                            <strong>Name:</strong>
                            <p>{selectedProperty.name}</p>
                            <strong>Location:</strong>
                            <p>{selectedProperty.location}</p>

                            <strong>Units Available</strong>
                            {apartmentUnits.map((units) => (
                                <>
                                    <p>Rent is <strong>Ksh. {units.rent.toLocaleString()}</strong> and has <strong>{units.bedroom} Bedroom(s)</strong> </p>

                                </>
                            ))}
                            <strong>Property Images:</strong>

                            <Carousel>
                                {apartmentImages.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={image.image}
                                            alt={`Image ${index}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>

                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPropertyModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for approving properties */}
            <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Unapprove Properties</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to unapprove the following properties?</p>
                    <ul>
                        {selectedProperties.map(propertyId => {
                            const property = apartments.find(property => property.id === propertyId);
                            return <li key={property.id}>{property.name}</li>;
                        })}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowApprovalModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUnApproveProperty}>
                        Unapprove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ApproveProperty;
