import React, { useState, useEffect } from 'react'
import Header from './Header'
import './Account.scss'
import SideBar from './SideBar'
import { useParams, Link } from 'react-router-dom'
import { Table, Button, Image, Modal, Form } from 'react-bootstrap'
import ReportsApartment from './reports/ReportsApartment'
import User from '../../assets/img/user_sqaure.jpg'
import User1 from '../../assets/img/user1.jpg'
import User2 from '../../assets/img/user2.png'
import User3 from '../../assets/img/user3.jpg'

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function Account() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // getting the id and finding the apparment details
    const { id } = useParams();
    const [apartmentSelected, setApartmentSelected] = useState([])

    // set the accounts to be displayed
    const [accounts, setAccounts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    // modal to be used in adding a new account for the apartments
    const [addModal, setAddModal] = useState(false)

    // modal for adding apartment
    const handleAddAccount = () => {
        setAddModal(true);
    };

    const handleAddAccountClose = () => {
        setAddModal(false);
    };
    const handleCloseModalReload = () => {
        setAddModal(false);
        window.location.reload()
    };

    // get the apartment selected and the details of the logged in user
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    // modal to be used in adding a new account for the apartments
    const [addModalDelete, setAddModalDelete] = useState(false)
    const [userDeleted, setUserDeleted] = useState(null)

    const handleAddAccountDelete = (user) => {
        setAddModalDelete(true);
        setUserDeleted(user)
    };
    const [userSelectedDetails, setUserSelectedDetails] = useState({})


    const handleAddAccountCloseDelete = () => {
        setAddModalDelete(false);
    };


    const [foundUser, setFoundUser] = useState({})
    useEffect(() => {
        setIsLoading(true)
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);

                // Create an array to store all the requests
                const requests = landlordIds.map((landlordId) => {
                    return serverUrl
                        .get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => {
                            return res.data.results;
                        })
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                // Wait for all requests to complete
                Promise.all(requests)
                    .then((results) => {
                        // Merge the results into a single array
                        const mergedResults = [].concat(...results);

                        setApartmentSelected(mergedResults.find((apartment) => apartment.id === parseInt(id)));
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

        // get the accounts that are within the selected apartment
        serverUrl.get(`/landlord/pending-landlord-plus-landlord/?apartment_id=${id}`, config)
            .then((res) => {
                // filter the user account based on the email of the user
                const accountUsers = res.data.results
                serverUrl.get(`/users/users-details/?id=${userId}`, config)
                    .then((res) => {
                        // from the accounts get the logged in user and match to get their status 
                        const userAccountFound = accountUsers.filter(userFound => {
                            return userFound.phone_number === res.data.results[0].phone_number
                        })
                        setFoundUser(userAccountFound[0])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // get the details of the selected account
                serverUrl.get(`/landlord/landlord-name-in-apartment/?apartment_id=${id}`, config)

                    .then((res) => {
                        // from the accounts get the logged in user and match to get their status 
                        const userSelected = accountUsers.filter(userFound => {
                            return userFound.id === userDeleted
                        })
                        setUserSelectedDetails(userSelected[0])
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                setAccounts(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })


    }, [userDeleted]);


    // the data related with the form
    const [formData, setFormData] = useState({
        phone_number: "",
        landlord_category: "",
        landlord_type: "",
        apartment: id
    });
    const digitCount = formData.phone_number.replace(/[^0-9]/g, "").length; // Count only digits



    //   handling the change of the data in the form
    const handleChange = (event) => {
        const { name, value } = event.target;
        // limit phone number input to 10 digits
        if (name === "phone_number") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    // onclick delete the selected account 
    function handleDeleteSelectedAccount(e) {
        e.preventDefault();
        // delete the selected user from that apartmen
        serverUrl.delete(`/landlord/landlord-apartment/${userDeleted}/`, config)
            .then((res) => {
                handleAddAccountCloseDelete()
                toast.success('Succesfully removed the account!')
                setAccounts((prevAccounts) =>
                prevAccounts.filter((account) => account.id !== userDeleted)
                );
            })
            .catch((error) => {
                console.log(error)
            })

    }

    // submit the form and add the new accounts
    function handleSubmit(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        // data to be sent to the backend
        const data = {
            phone_number: formData.phone_number,
            landlord_category: formData.landlord_category.toLowerCase(),
            landlord_type: formData.landlord_type.toLowerCase(),
            apartment: parseInt(id),
        }
        // send the data to the backend 
        try {
            serverUrl.post('/landlord/pending-landlord/', data, config)
                .then((res) => {
                    toast.success("Account Added Successfully!")
                    setFormData({
                        phone_number: "",
                        landlord_category: "",
                        landlord_type: "",
                        apartment: ""
                    })
                    handleAddAccountClose()
                })
                .catch((error) => {
                    toast.error(error)
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='account'>
                {foundUser.landlord_type === 'landlord' ?
                    <>
                        <Button className='btn btn-sm btn-success text-white float-end' onClick={handleAddAccount}>Add Account </Button>

                        {/* // modal for adding the account  */}
                        <Modal show={addModal} onHide={handleAddAccountClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Account For {apartmentSelected.name} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input className='addUtiilitySelect' onChange={handleChange} value={formData.phone_number} name='phone_number' placeholder='Phone Number' type='number' maxLength={10} // set maximum input length to 10 digits
                                            />
                                        </div>
                                        <p
                                            style={{
                                                fontSize: "12px",
                                                paddingRight: "20px",
                                                float: "right",
                                            }}
                                        >
                                            {digitCount}/10
                                        </p>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <select className='addUtiilitySelect' name='landlord_category' onChange={handleChange} value={formData.landlord_category} >
                                                <option disabled value="">Select Their Category</option>
                                                <option value="primary">Primary</option>
                                                <option value="secondary">Secondary</option>
                                            </select>
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <select className='addUtiilitySelect' name='landlord_type' onChange={handleChange} value={formData.landlord_type}>
                                                <option disabled value="">Select Title</option>
                                                <option value="landlord">Landlord</option>
                                                <option value="agent">Agent</option>
                                                <option value="caretaker">Caretaker</option>
                                            </select>
                                        </div>

                                        <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                            Save
                                        </Button>
                                    </Form>
                                </>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={handleCloseModalReload} >
                                    Done
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* modal for removing the account */}
                        <Modal show={addModal} onHide={handleAddAccountCloseDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title>Remove Account For {apartmentSelected.name} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <p>Do you want to remove user whose role is agent in apartment before</p>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={handleAddAccountCloseDelete} >
                                    Done
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    :
                    <></>
                }


                <div className='tasks' >
                    <h5 className='float-start'>Accounts for {apartmentSelected.name}</h5>
                    <Table className='table'>
                        <thead className='underline'>
                            <th>User</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Level</th>
                            <th>Status</th>

                        </thead>
                        <tbody>
                            {accounts.map((account, index) =>
                                <tr>
                                    <td className='w-10'>
                                        <Image src={account.profile_pic} alt='User_Image'></Image>
                                    </td>
                                    <td>
                                        {account.landlord}
                                    </td>
                                    {account.landlord_type === 'landlord' ?
                                        (<td className='landlord'>{account.landlord_type}</td>)
                                        : account.landlord_type === 'agent' ?
                                            (<td className='agent'>{account.landlord_type}</td>)
                                            :
                                            (<td className='caretaker'>{account.landlord_type}</td>)
                                    }


                                    <td>{account.landlord_category}</td>
                                    <td>
                                        <Button className='btn btn-sm btn-success text-white'>Active</Button>
                                    </td>
                                    {foundUser.phone_number !== account.phone_number ?
                                        <>

                                            <td>
                                                <Button className='btn btn-sm btn-danger' onClick={() => handleAddAccountDelete(account.id)}><i className='bi bi-trash'></i></Button>
                                            </td>
                                            {/* modal for removing the account */}
                                            <Modal show={addModalDelete} onHide={handleAddAccountCloseDelete}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Remove account from {apartmentSelected.name} </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {userSelectedDetails &&
                                                        <p>Do you want to remove {userSelectedDetails.landlord}  from {userSelectedDetails.apartment} whose role is {userSelectedDetails.landlord_type}</p>

                                                    }


                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="danger" onClick={handleDeleteSelectedAccount} >
                                                        Yes
                                                    </Button>
                                                    <Button variant="success" onClick={handleAddAccountCloseDelete} >
                                                        No
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </tr>
                            )}

                        </tbody>
                    </Table>


                </div>
            </div>
        </>
    )
}

export default Account