import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Booking.scss'
import { Button, Card, Container, Form, FormControl, FloatingLabel, Image, Modal, Table } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import loaderImage from '../../assets/img/logo.png'

function Feedback() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const [isLoading, setIsLoading] = useState(true)
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const [bookings, setBookings] = useState([])
    const [contactsFeedback, setContactsFeedback] = useState([])

    useEffect(() => {
        setIsLoading(true);
        serverUrl.get(`/landlord/feedbacks/`, config)
            .then((res) => {
                setBookings(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the contacts feedback
        serverUrl.get(`/landlord/contacts-feedback`)
            .then((res) => {
                setContactsFeedback(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

    }, []);
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null);
    };
    const filteredData = bookings.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // contacts
    const filteredDataFeedback = contactsFeedback.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageFeedback, setCurrentPageFeedback] = useState(0);
    const itemsPerPageFeedback = 10;
    const totalPagesFeedback = Math.ceil(filteredDataFeedback.length / itemsPerPageFeedback);
    const startIndexFeedback = currentPageFeedback * itemsPerPageFeedback;
    const endIndexFeedback = currentPage === totalPagesFeedback - 1 ? filteredDataFeedback.length : startIndexFeedback + itemsPerPageFeedback;
    const displayedItemsFeedback = filteredDataFeedback.slice(startIndexFeedback, endIndexFeedback);

    const exportCSV = () => {
        const csvData = [
            ['Name', 'Phone', 'Tenant Status', 'Feedback',],
            ...bookings.map((item) => [item.name, item.phone_number, item.tenant_status, item.feedback]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Feedback.csv');
    };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of bookings) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [bookings]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setselectedBooking] = useState(null);

    const handleOpenModal = bookingId => {
        setselectedBooking(bookingId);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDeleteApartment = () => {
        if (selectedBooking) {
            serverUrl.delete(`/landlord/booking/${selectedBooking}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/booking/`, config)
                        .then((res) => {
                            
                            setBookings(res.data.results)
                            setShowModal(false);

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Succesfully deleted the Viewing')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const [showModalEdit, setShowModalEdit] = useState(false);

    const [currentBooking, setCurrentBooking] = useState([])

    const handleOpenModalEdit = leadId => {
        setselectedBooking(leadId);
        serverUrl.get(`/landlord/booking/${leadId}/`, config)
            .then((res) => {
                console.log(res)
                setCurrentBooking(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
        setShowModalEdit(true);
    };
    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    }

    const [showModalView, setShowModalView] = useState(false);

    const handleOpenModalView = leadId => {
        setselectedBooking(leadId);

        setShowModalView(true);
    };
    const handleCloseModalView = () => {
        setShowModalView(false);
    };

    const [formData, setFormData] = useState({
        user_name: "",
        user_phone: "",
        user_email: "",
        meeting_date: "",
        agent_name: "",
        agent_phone: "",
        agent_email: "",
        apartment_name: "",
        house_name: "",
        status: "",
        feedback: "",
        starting_time: "",
        duration: "",


    });

    const [showDeleteFeedbackModal, setShowFeedbackDeleteModal] = useState(false);
    const [feedbackIdToDelete, setFeedbackIdToDelete] = useState(null);

    const handleDeleteClickFeedback = (leadId) => {
        setFeedbackIdToDelete(leadId);
        setShowFeedbackDeleteModal(true);
    };
    const handleConfirmDeleteFeedback = () => {
        serverUrl.delete(`/landlord/contacts-feedback/${feedbackIdToDelete}`, config)
            .then((res) => {
                toast.success('Succesfully deleted the feedback')
                serverUrl.get(`/landlord/contacts-feedback`)
                    .then((res) => {
                        setContactsFeedback(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
        setShowDeleteModal(false);
    };
    const handleCancelDeleteFeedback = () => {
        setShowFeedbackDeleteModal(false);
    };


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [leadIdToDelete, setLeadIdToDelete] = useState(null);

    const handleDeleteClick = (leadId) => {
        setLeadIdToDelete(leadId);
        setShowDeleteModal(true);
    };
    const handleConfirmDelete = () => {
        serverUrl.delete(`/landlord/feedbacks/${leadIdToDelete}`, config)
            .then((res) => {
                toast.success('Succesfully deleted the feedback')
                serverUrl.get(`/landlord/feedbacks/`, config)
                    .then((res) => {
                        setBookings(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
        setShowDeleteModal(false);
    };
    const handleCancelDelete = () => {
        // Close the modal without performing deletion
        setShowDeleteModal(false);
    };



    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedItems(selectAll ? [] : displayedItems.map((lead) => lead.id));
    };

    const handleToggleSelect = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                // Item is already selected, remove it
                return prevSelectedItems.filter((id) => id !== itemId);
            } else {
                // Item is not selected, add it
                return [...prevSelectedItems, itemId];
            }
        });
    };

    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const handleDeleteAll = () => {
        setShowDeleteAllModal(true);
    };
    const handleCancelDeleteAll = () => {
        setShowDeleteAllModal(false);
    };
    const showDeleteButton = selectedItems.length > 0;

    const handleConfirmDeleteAll = async () => {
        try {
            await Promise.all(selectedItems.map(async (itemId) => {
                await serverUrl.delete(`/landlord/feedbacks/${itemId}`, config);
            }));

            toast.success('Successfully deleted selected feedbacks');
            setShowDeleteAllModal(false);

            serverUrl.get(`/landlord/feedbacks/`, config)
                .then((res) => {
                    setBookings(res.data.results)
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.error('Error deleting items:', error);
        }
    };

    useEffect(() => {
        serverUrl.get(`/landlord/booking/?id=${selectedBooking}`, config)
            .then((res) => {
                setFormData({
                    user_name: res.data.results[0].user_name || '',
                    user_phone: res.data.results[0].user_phone || '',
                    user_email: res.data.results[0].user_email || '',
                    meeting_date: res.data.results[0].meeting_date || '',
                    agent_name: res.data.results[0].agent_name || '',
                    agent_phone: res.data.results[0].agent_phone || '',
                    agent_email: res.data.results[0].agent_email || '',
                    apartment_name: res.data.results[0].apartment_name || '',
                    house_name: res.data.results[0].house_name || '',
                    status: res.data.results[0].status || '',
                    feedback: res.data.results[0].feedback || '',
                    starting_time: res.data.results[0].starting_time || '',
                    duration: res.data.results[0].duration || '',


                });

            })
            .catch((error) => {
                console.log(error)
            })

    }, [selectedBooking]);
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }


    function handleEditApartment(e) {
        e.preventDefault();
        const accessToken = localStorage.getItem("authTokens");
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        const dataHouse = {
            user_name: formData.user_name,
            user_phone: formData.user_phone,
            user_email: formData.user_email,
            meeting_date: formData.meeting_date,
            agent_name: formData.agent_name,
            agent_phone: formData.agent_phone,
            agent_email: formData.agent_email,
            apartment_name: formData.apartment_name,
            house_name: formData.house_name,
            status: formData.status,
            feedback: formData.feedback,
            starting_time: (() => {
                if (formData.starting_time) {
                    const startTime = new Date(`2023-11-28 ${formData.starting_time}`);

                    const formatter = new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        millisecond: 'numeric',
                        hour12: false,
                    });

                    return formatter.format(startTime);
                }
                return null;
            })(),
            duration: formData.duration,
            end_time: (() => {
                if (formData.starting_time && formData.duration) {
                    const startTime = new Date(`2023-11-28 ${formData.starting_time}`);
                    const durationMinutes = parseInt(formData.duration, 10);

                    if (!isNaN(durationMinutes)) {
                        const endTime = new Date(startTime.getTime() + durationMinutes * 60000);

                        const formatter = new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            millisecond: 'numeric',
                            hour12: false,
                        });

                        return formatter.format(endTime);
                    }
                }
                return null;
            })(),
        };
        try {
            serverUrl.patch(`/landlord/booking/${selectedBooking}/`, dataHouse, config)
                .then((res) => {
                    setShowModalView(false);
                    serverUrl.get(`/landlord/booking/`, config)
                        .then((res) => {
                            setBookings(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Successfully Updated The Viewing!');
                })
                .catch((error) => {
                    console.log('Unexpected error: ' + error.message);

                });
        } catch (error) {
            console.log(error);
        }
    }


    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }
    const [activeTab, setActiveTab] = useState('feedback');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const [showAddModal, setShowAddModal] = useState(false);
    const [newFeedbackData, setNewFeedbackData] = useState({
        name: '',
        phone: '',
        email: '',
        feedbackType: '',

    });

    const handleOpenAddModal = () => {
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false);
    };

    const handleChangeAdd = (e) => {
        const { name, value } = e.target;
        setNewFeedbackData({ ...newFeedbackData, [name]: value });
    };

    const handleAddFeedback = () => {
        // Handle adding the new feedback data (e.g., send to backend, update state)
        const data = {
            contact_number: newFeedbackData.phone,
            contact_email: newFeedbackData.email,
            contact_name: newFeedbackData.name,
            feedback: newFeedbackData.feedbackType,
        }

        serverUrl.post(`/landlord/contacts-feedback/`, data)
            .then((res) => {
                toast.success('Succesfully added the feedback')
                setShowAddModal(false)
                setNewFeedbackData({
                    name: '',
                    phone: '',
                    email: '',
                    feedbackType: '',
                });
                serverUrl.get(`/landlord/contacts-feedback`)
                    .then((res) => {
                        setContactsFeedback(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>Feedback from viewers</p>

                </div>
                <div>
                    <div className="nav nav-tabs mb-2 mt-2" style={{ marginLeft: "12rem" }}>
                        <p className="nav-item w-25">
                            <button
                                className={`nav-link ${activeTab === 'feedback' ? 'active' : ''}`}
                                onClick={() => handleTabChange('feedback')}
                            >
                                Feedback
                            </button>
                        </p>
                        <p className="nav-item w-25">
                            <button
                                className={`nav-link ${activeTab === 'list' ? 'active' : ''}`}
                                onClick={() => handleTabChange('list')}
                            >
                                List of Feedbacks
                            </button>
                        </p>
                    </div>
                </div>

                {/* Render content based on active tab */}
                {activeTab === 'feedback' && (
                    <div className='tasks ' >
                        <div className='d-flex justify-content-between'>

                            <div>
                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                            </Form>
                        </div>
                        <div className='d-flex w-25 justify-content-between'>
                            <Button variant="primary" className='btn btn-sm' onClick={handleSelectAll}>
                                Select All view
                            </Button>

                            {showDeleteButton && (
                                <Button variant="danger" className='btn btn-sm' onClick={handleDeleteAll}>
                                    Delete
                                </Button>
                            )}
                        </div>

                        <Table className='table mt-2'>
                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                <tr>
                                    <th>Select</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Feedback Date</th>
                                    <th>Feedback</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedItems.map((lead) => (
                                    <tr key={lead.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(lead.id)}
                                                onChange={() => handleToggleSelect(lead.id)}
                                            />
                                        </td>
                                        <td>{lead.name}</td>
                                        <td>{lead.phone_number}</td>
                                        <td>{formatDate(lead.created_at)}</td>
                                        <td>{lead.feedback}</td>
                                        <td>
                                            <Button
                                                className='btn btn-sm btn-danger text-white'
                                                onClick={() => handleDeleteClick(lead.id)}
                                            >
                                                <i className='bi bi-trash'></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {/* delete the feedback */}
                        <Modal show={showDeleteModal} onHide={handleCancelDelete}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this feedback?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelDelete}>
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleConfirmDelete}>
                                    Yes, Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* delete all the feedback */}
                        <Modal show={showDeleteAllModal} onHide={handleCancelDeleteAll}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete  Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete the selected feedback?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelDeleteAll}>
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleConfirmDeleteAll}>
                                    Yes, Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* delete the booking */}
                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Booking</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Do you want to delete this viewing?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                    Delete
                                </button>
                                <button className='btn btn-secondary' onClick={handleCloseModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* view the booking */}
                        <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Viewings Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                                <p className='lableName'>Viewer's Name</p>

                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.user_name}

                                        placeholder={currentBooking.user_name}
                                        name="user_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Viewer's Phone</p>

                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.user_phone}
                                        placeholder={currentBooking.user_phone}
                                        name="user_phone"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Viewer's Email</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="email"
                                        value={currentBooking.user_email}
                                        placeholder={currentBooking.user_email}
                                        name="user_email"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Meeting Date</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="date"
                                        value={currentBooking.meeting_date}

                                        placeholder={currentBooking.meeting_date}
                                        name="meeting_date"
                                        required
                                    />
                                </div>


                                <p className='lableName'>Starting Time</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="time"
                                        value={currentBooking.starting_time}

                                        placeholder={currentBooking.starting_time}
                                        name="starting_time"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Duration in min(s)</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="number"
                                        value={currentBooking.duration}

                                        placeholder={currentBooking.duration}
                                        name="duration"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Ending Time</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="time"
                                        value={currentBooking.end_time}

                                        placeholder={currentBooking.end_time}
                                        name="end_time"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Agent's Name</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.agent_name}
                                        placeholder={currentBooking.agent_name}
                                        name="agent_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Agent's Phone</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.agent_phone}
                                        placeholder={currentBooking.agent_phone}
                                        name="agent_phone"
                                        required
                                    />
                                </div>

                                <p className='lableName'>Agent's Email</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.agent_email}
                                        placeholder={currentBooking.agent_email}
                                        name="agent_email"
                                        required
                                    />
                                </div>


                                <p className='lableName'>Apartment</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.apartment_name}
                                        placeholder={currentBooking.apartment_name}
                                        name="apartment_name"
                                        required
                                    />
                                </div>

                                <p className='lableName'>House</p>
                                <div className="input-icons mt-2">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={currentBooking.house_name}
                                        placeholder={currentBooking.house_name}
                                        name="house_name"
                                        required
                                    />
                                </div>


                                <p className='lableName'>Status</p>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>

                                    <Form.Select
                                        size='sm'
                                        className="input-field"
                                        value={currentBooking.status}

                                        name='status'
                                    >
                                        <option value="">{currentBooking.status}</option>
                                    </Form.Select>
                                </div>

                                <p className='lableName'>Feedback </p>

                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <textarea
                                        className="input-field"
                                        type="text"
                                        name="feedback"
                                        value={currentBooking.feedback}

                                        placeholder={currentBooking.feedback}
                                        required

                                        style={{ height: "330px" }}
                                    />
                                </div>

                            </Modal.Body>
                            <Modal.Footer>

                                <button className='btn btn-secondary' onClick={handleCloseModalEdit}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* edit the booking */}
                        <Modal show={showModalView} onHide={handleCloseModalView}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit viewing details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "100vh", overflow: "scroll" }}>
                                <Form onSubmit={handleEditApartment}>
                                    <p className='lableName'>Viewer's Name</p>

                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.user_name}
                                            placeholder={formData.user_name}
                                            onChange={handleChangeEdit}
                                            name="user_name"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Viewer's Phone</p>

                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.user_phone}
                                            placeholder={formData.user_phone}
                                            onChange={handleChangeEdit}
                                            name="user_phone"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Viewer's Email</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="email"
                                            value={formData.user_email}
                                            placeholder={formData.user_email}
                                            onChange={handleChangeEdit}
                                            name="user_email"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Meeting Date</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="date"
                                            value={formData.meeting_date}
                                            onChange={handleChangeEdit}
                                            placeholder={formData.meeting_date}
                                            name="meeting_date"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Starting Time</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="time"
                                            value={formData.starting_time}
                                            onChange={handleChangeEdit}
                                            placeholder={formData.starting_time}
                                            name="starting_time"
                                            required
                                        />
                                    </div>
                                    <p className='lableName'>Duration in min(s)</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="number"
                                            value={formData.duration}
                                            onChange={handleChangeEdit}
                                            placeholder={formData.duration}
                                            name="duration"
                                            required
                                        />
                                    </div>
                                    <p className='lableName'>Agent's Name</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.agent_name}
                                            placeholder={formData.agent_name}
                                            onChange={handleChangeEdit}
                                            name="agent_name"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Agent's Phone</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.agent_phone}
                                            placeholder={formData.agent_phone}
                                            onChange={handleChangeEdit}
                                            name="agent_phone"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>Agent's Email</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="email"
                                            value={formData.agent_email}
                                            placeholder={formData.agent_email}
                                            onChange={handleChangeEdit}

                                            name="agent_email"
                                            required
                                        />
                                    </div>


                                    <p className='lableName'>Apartment</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.apartment_name}
                                            placeholder={formData.apartment_name}
                                            onChange={handleChangeEdit}

                                            name="apartment_name"
                                            required
                                        />
                                    </div>

                                    <p className='lableName'>House</p>
                                    <div className="input-icons mt-2">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="text"
                                            value={formData.house_name}
                                            placeholder={formData.house_name}
                                            onChange={handleChangeEdit}

                                            name="house_name"
                                            required
                                        />
                                    </div>


                                    <p className='lableName'>Status</p>
                                    <div className="input-icons">
                                        <i className="fa fa-hashtag icon"></i>

                                        <Form.Select
                                            size='sm'
                                            className="input-field"
                                            value={formData.status}
                                            onChange={handleChangeEdit}

                                            name='status'
                                        >
                                            <option value="incomplete">Incomplete</option>
                                            <option value="complete">Complete</option>

                                        </Form.Select>
                                    </div>

                                    <p className='lableName'>Feedback </p>

                                    <div className="input-icons">
                                        <i className="fa fa-hashtag icon"></i>
                                        <textarea
                                            className="input-field"
                                            type="text"
                                            name="feedback"
                                            value={formData.feedback}
                                            onChange={handleChangeEdit}

                                            placeholder={formData.feedback}
                                            required

                                            style={{ height: "330px" }}
                                        />
                                    </div>

                                    <Button variant="primary" type="submit" className="nextBtn mt-3">
                                        Save
                                    </Button>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='btn btn-secondary' onClick={handleCloseModalView}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>


                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndex + 1}-${endIndex} of ${bookings.length}`}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === 'list' && (


                    <div className='tasks ' >
                        <div className='d-flex justify-content-between'>

                            <div>
                                <h5 className='text-success text-uppercase'>The types of feedback</h5>

                            </div>
                            <div>

                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />

                            </Form>
                        </div>
                        <div className='d-flex  justify-content-between'>
                            <Button variant="primary" className='btn btn-sm' onClick={handleSelectAll}>
                                Select All
                            </Button>

                            {showDeleteButton && (
                                <Button variant="danger" className='btn btn-sm' onClick={handleDeleteAll}>
                                    Delete
                                </Button>
                            )}
                            <Button className='btn btn-sm btn-warning text-white' onClick={handleOpenAddModal}>Add New Feedback</Button>

                        </div>

                        <Table className='table mt-2'>
                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                <tr>
                                    <th>Select</th>
                                    <th>Managed By</th>
                                    <th>Managed By Contact</th>
                                    <th>Manage By Email</th>
                                    <th>Feedback</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedItemsFeedback.map((lead) => (
                                    <tr key={lead.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(lead.id)}
                                                onChange={() => handleToggleSelect(lead.id)}
                                            />
                                        </td>
                                        <td>{lead.contact_name}</td>
                                        <td>{lead.contact_number}</td>
                                        <td>{lead.contact_email}</td>
                                        <td style={{ textTransform: "capitalize" }}>{lead.feedback}</td>
                                        <td>
                                         
                                            <Button
                                                className='btn btn-sm btn-danger text-white'
                                                onClick={() => handleDeleteClickFeedback(lead.id)}
                                            >
                                                <i className='bi bi-trash'></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* add a new feedback type with contacts */}
                        <Modal show={showAddModal} onHide={handleCloseAddModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Feedback</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <FloatingLabel
                                            controlId="Contact Name"
                                            label="Contact Name"
                                            style={{ margin: "0 auto" }}
                                        >

                                            <Form.Control type="text" placeholder="Enter Contact Name" name="name" value={newFeedbackData.name} onChange={handleChangeAdd} />

                                        </FloatingLabel>
                                    </Form.Group> <Form.Group className="mb-3" controlId="formName">
                                        <FloatingLabel
                                            controlId="Contact Email"
                                            label="Contact Email"
                                            style={{ margin: "0 auto" }}
                                        >

                                            <Form.Control type="text" placeholder="Enter Contact Email" name="email" value={newFeedbackData.email} onChange={handleChangeAdd} />

                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formName">
                                        <FloatingLabel
                                            controlId="Contact Phone Number"
                                            label="Contact Phone Number"
                                            style={{ margin: "0 auto" }}
                                        >

                                            <Form.Control type="text" placeholder="Enter Contact Phone Number" name="phone" value={newFeedbackData.phone} onChange={handleChangeAdd} />

                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formFeedbackType">

                                        <Form.Select name="feedbackType" value={newFeedbackData.feedbackType} onChange={handleChangeAdd}>
                                            <option value="">Select feedback type</option>
                                            <option value="deposit">Deposit</option>
                                            <option value="renovation">Renovation</option>
                                        </Form.Select>
                                    </Form.Group>


                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAddModal}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleAddFeedback}>
                                    Add Feedback
                                </Button>
                            </Modal.Footer>
                        </Modal>

                         {/* delete the feedback */}
                         <Modal show={showDeleteFeedbackModal} onHide={handleCancelDeleteFeedback}>
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to delete this feedback?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelDeleteFeedback}>
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleConfirmDeleteFeedback}>
                                    Yes, Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndex + 1}-${endIndex} of ${bookings.length}`}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>


                )}




            </div >

        </div >
    )
}

export default Feedback




