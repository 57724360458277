import React, { useState, useEffect } from 'react'
// import './RoomAdd.scss'
import { Form, Button, FormSelect, Col, Image, Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import { toast } from 'react-toastify';

// auth imports
import { Link, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function RoomAdd() {
    const navigate = useNavigate()
    const { id } = useParams();

    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;


    const [rooms, setRooms] = useState([])
    // image
    const [image, setImage] = useState(null);

    useEffect(() => {
        serverUrl.get(`/landlord/room/`, config)
            .then((res) => {
                setRooms(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    // button that does the uploading to while the input is hidden
    const handleButtonClick = () => {
        document.getElementById('imageUpload').click();
    };

    // the data to be sent in the backend
    const [formData, setFormData] = useState({
        Houseimg: null
    });


    // onchange functionality
    const handleChange = (event) => {
        const { name, value } = event.target;
        // handling the selected image 

        const selectedImage = event.target.files[0];
        if (selectedImage && selectedImage.type.includes('image')) {
            setImage(selectedImage);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Houseimg: selectedImage,
            }));
        } else {
            setImage(null);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Houseimg: null,
            }));
            toast.error('Kindly select an image file!');
        }
    }
    const [selectedRoom, setSelectedRoom] = useState(null);


    const [apartmentImages, setApartmentImages] = useState([])

    useEffect(() => {
        serverUrl.get(`/landlord/house-images/?house_id=${id}`, config)
            .then((res) => {
                setApartmentImages(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [id])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);
    const [selectedImageIds, setSelectedImageIds] = useState([]);


    const openModal = (apartmentId) => {
        setSelectedApartmentId(apartmentId);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedApartmentId(null);
    };

    const handleImageClick = (apartmentId) => {
        setSelectedImageIds((prevSelectedImageIds) => {
            const isSelected = prevSelectedImageIds.includes(apartmentId);

            if (isSelected) {
                return prevSelectedImageIds.filter((id) => id !== apartmentId);
            } else {
                return [...prevSelectedImageIds, apartmentId];
            }
        });


    };

    // const [chosenImage, setChosenImage] = useState('')

    // useEffect(() => {
    //     if (selectedImageIds.length === 1) {
    //         serverUrl.get(`/landlord/house-images/?id=${selectedImageIds[0]}`, config)
    //             .then((res) => {
    //                 setChosenImage(res.data.results[0].image)
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //             })
    //     }
    //     if(selectedImageIds.length > 1){
    //         selectedImageIds.map((images)=>(

    //             serverUrl.get(`/landlord/house-images/?id=${images}`, config)
    //             .then((res) => {
    //                 setChosenImage(res.data.results[0].image)
    //             })
    //             .catch((error) => {
    //                 console.log(error)

    //             })


    //         ))
    //     }
    // },[selectedImageIds])


    const [chosenImage, setChosenImage] = useState([]);

    useEffect(() => {
        if (selectedImageIds.length > 0) {
            const fetchImages = () => {
                const promises = selectedImageIds.map((imageId) => (
                    serverUrl.get(`/landlord/house-images/?id=${imageId}`, config)
                        .then((res) => ({
                            id: imageId,
                            url: res.data.results[0].image
                        }))
                        .catch((error) => {
                            console.log(error);
                            return null; // Handle the error appropriately
                        })
                ));
    
                Promise.all(promises)
                    .then((images) => {
                        // Filter out null values in case of errors
                        const filteredImages = images.filter((image) => image !== null);
                        setChosenImage(filteredImages);
                        // console.log(filteredImages);
                    })
                    .catch((error) => {
                        console.log(error);
                        // Handle the error appropriately
                    });
            };
    
            fetchImages();
        }
    }, [selectedImageIds]);
     
    function handleSubmit(e) {
        e.preventDefault();
    
        if (!selectedRoom || chosenImage.length === 0) {
            toast.error('Please select a room and at least one image.');
            return;
        }
    
        const houseRoom = {
            room: selectedRoom,
            house: id,
        };
    
        serverUrl.post(`/landlord/house-room/`, houseRoom, config)
            .then((res) => {
                // Create an array of image data to be uploaded
                const imageUploadPromises = chosenImage.map((chosenImg) => {
                    const data = new FormData();
    
                    return fetch(chosenImg.url)
                        .then(response => response.blob())
                        .then(blob => {
                            const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
    
                            data.append('image', file);
                            data.append('room', res.data.id);
                            data.append('house', id);
                            data.append('image_house', chosenImg.id);
    
                            return serverUrl.post("/landlord/house-room-image/", data, config);
                        })
                        .then(response => {
                            // Handle the response if needed
                            toast.success('Successfully Added Room Images!');
                            navigate(`/selected-unit/${id}`);
                        })
                        .catch(error => {
                            console.error('Error fetching or processing image:', error);
                            // Handle the error appropriately
                        });
                });
    
                // Wait for all image uploads to complete
                Promise.all(imageUploadPromises)
                    .then((res) => {
                        // Do something after all images are uploaded
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    





    const handleDeleteApartment = () => {
        if (selectedApartmentId) {
            serverUrl.delete(`/landlord/house-image/${selectedApartmentId}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/house-images/?house_id=${id}`, config)
                        .then((res) => {
                            setApartmentImages(res.data.results)
                            closeModal();

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    toast.success('Succesfully deleted the image')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.max(1, Math.min(3, apartmentImages.length)),
        slidesToScroll: 1,
    }

    return (
        <>
            <div className='d-flex justify-content-'>
                <Link to={`/selected-unit/${id}`} className='addHouse'>
                    <i
                        className="bi bi-arrow-left-circle-fill"
                        style={{ fontSize: '2.5rem', color: '#006132', padding: "1rem" }}
                    ></i>
                </Link>
                <h2></h2>
            </div>

            <div className="imageProperty">
                <h2>Room Image(s)</h2>

                <Form onSubmit={handleSubmit} encType="multipart/form-data">

                    <div className="input-icons roomName"
                        style={{ width: "60%", display: "flex", placeContent: "center", paddingLeft: "0" }}
                    >
                        <FormSelect size='sm' className='formProperty w-25' value={selectedRoom} onChange={(e) => setSelectedRoom(e.target.value)}
                        >
                            <option disabled selected>Select Room</option>
                            {rooms.map((room) => (
                                <option key={room.id} value={room.id} > {room.name}</option>


                            ))}
                        </FormSelect>
                    </div>

                    <div className="input-icons">
                        {/* <Button className='imageButton' onClick={handleButtonClick}>
                            Pick for room from house gallery below
                        </Button> */}
                        {selectedRoom ?
                            <>
                                <p className='text-warning fw-bold'>
                                    Pick  the room image from your unit gallery below
                                </p>

                                <Slider {...slickSettings} style={{ marginLeft: '0rem' }}>
                                    {apartmentImages.map((apartment) => (
                                        <div key={apartment.id}>
                                            <Col md={6} sm={6} style={{ marginTop: '1rem' }}>
                                                <span
                                                    className={`mt-4 ${selectedImageIds.includes(apartment.id) ? 'bg-transparent' : 'bg-transparent'}`}
                                                    onClick={() => handleImageClick(apartment.id)}
                                                    style={{
                                                        position: 'relative',
                                                        padding: '0.8rem',
                                                        backgroundColor: 'red',
                                                        borderRadius: '50%',
                                                        marginLeft: '-13rem',
                                                    }}
                                                >
                                                    {selectedImageIds.includes(apartment.id) ?
                                                        <i className='bi bi-check-circle-fill text-warning' style={{ position: 'relative', fontSize: "1.5rem" }}></i>
                                                        :
                                                        <i className='bi bi-check-circle-fill text-white' style={{ position: 'relative', fontSize: "1.5rem" }}></i>
                                                    }
                                                </span>
                                                <Image className='propertiesViewImage' src={apartment.image} />
                                            </Col>
                                        </div>
                                    ))}
                                </Slider>
                                <p className='mt-5 text-warning font-bold'>{selectedImageIds.length} images selected </p>




                                <Modal show={isModalOpen} onHide={closeModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Image</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Do you want to delete this image?</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                            Delete
                                        </button>
                                        <button className='btn btn-secondary' onClick={closeModal}>
                                            Close
                                        </button>
                                    </Modal.Footer>
                                </Modal>

                                <div className="bottomButtons" style={{ justifyContent: "right", width: "100%" }}>
                                    <Button type="submit" className="skipButton" style={{ marginInlineEnd: "initial", width: "auto" }}>
                                        Save
                                    </Button>
                                </div>
                            </>
                            :
                            <></>

                        }


                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                        />
                        {/* {image && (
                            <div>
                                <img src={URL.createObjectURL(image)} alt="preview" className='imageUploaded' />
                            </div>
                        )} */}

                    </div>


                </Form>

            </div>
        </>
    )
}

export default RoomAdd