import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './LeadViewer.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Table } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'
import Apartment from '../../assets/img/apartment.png'
import Unit from '../../assets/img/feedback.png'

function LeadViewer() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showModalUnit, setShowModalUnit] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // pasing the apartment Details to the modal
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModalUnit = () => {
        setShowModalUnit(true);
    };

    const handleCloseModalUnit = () => {
        setShowModalUnit(false);
    };

    // apartments of the landlord
    const [viewerLeads, setViewerLeads] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};

    const [likedApartments, setLikedApartments] = useState('')
    const [likedUnits, setLikedUnits] = useState('')
    const likes = []
    const [likedProperties, setLikedProperties] = useState([])
    const [likedUnitsData, setLikedUnitsData] = useState([])


    useEffect(() => {
        setIsLoading(true);
        // Get the data for the leads of the landlords
        serverUrl.get(`/landlord/alliance-viewer-lead`, config)
            .then((viewerLeadsRes) => {
                const viewerLeads = viewerLeadsRes.data.results;
                const userIds = viewerLeads.map((lead) => lead.user);

                // Get the amount of liked apartments and liked units based on user IDs
                serverUrl.get(`/landlord/viewers-favourites/`, config)
                    .then((viewersFavouritesRes) => {
                        const apartmentsLiked = viewersFavouritesRes.data.results.filter((apartment) => apartment.apartment !== null)
                        // the units obtained
                        const propertyPromises = apartmentsLiked.map((apartment) =>
                            serverUrl
                                .get(`/landlord/alliance-specific-apartment?property_id=${apartment.apartment}`, config)
                                .then((res) => res.data.results)
                                .catch((error) => {
                                    console.log(error);
                                    return null;
                                })
                        );

                        Promise.all(propertyPromises)
                            .then((apartmentResults) => {
                                const filteredHouseResults = apartmentResults.filter((apartment) => apartment !== null);

                                // Now, fetch the like counts for each property
                                const propertyPromisesCount = filteredHouseResults
                                    .map((apartment) =>
                                        apartment.map((property) =>
                                            serverUrl
                                                .get(`/landlord/viewers-favourites/?apartment=${property.id}`, config)
                                                .then((res) => {
                                                    const latestLike = res.data.results.reduce((latest, like) => {
                                                        const likeTime = new Date(like.created_at).getTime();
                                                        return likeTime > latest ? likeTime : latest;
                                                    }, 0);

                                                    return {
                                                        apartmentName: property.name,
                                                        likeCount: res.data.results.length,
                                                        latestLikedTime: latestLike,
                                                    };
                                                })

                                                .catch((error) => {
                                                    console.error(`Error fetching like counts for ${apartment.name}:`, error);
                                                    return null;
                                                })
                                        )
                                    )
                                    .flat(); // Flatten the array of promises

                                return Promise.all(propertyPromisesCount);
                            })
                            .then((likedPropertiesWithLikes) => {
                                // Use a set or object to filter out duplicate apartments
                                const uniqueApartments = new Set();
                                const filteredLikedPropertiesWithLikes = likedPropertiesWithLikes.filter((property) => {
                                    if (!uniqueApartments.has(property.apartmentName)) {
                                        uniqueApartments.add(property.apartmentName);
                                        return true;
                                    }
                                    return false;
                                });

                                setLikedProperties(filteredLikedPropertiesWithLikes);
                                // Do whatever you need with the data, such as setting state or updating your UI
                            })
                            .catch((errors) => {
                                // Log errors for individual properties
                                console.error('Error fetching like counts:', errors);
                            });





                        setLikedApartments(apartmentsLiked.length)

                        const unitsLiked = viewersFavouritesRes.data.results.filter((house) => house.house !== null)
                        setLikedUnits(unitsLiked.length)

                        // units name and number of times liked
                        const unitPromises = unitsLiked.map((unit) =>
                            serverUrl.get(`/landlord/alliance-specific-house?unit_id=${unit.house}`, config)
                                .then((res) => res.data.results)
                                .catch((error) => {
                                    console.log(error);
                                    return null;
                                })
                        );

                        Promise.all(unitPromises)
                            .then((unitsResults) => {
                                // Now, fetch the like counts for each property
                                const unitPromisesCount = unitsResults.map((unit) =>
                                    unit.map((house) =>
                                        serverUrl.get(`/landlord/viewers-favourites/?house=${house.id}`, config)
                                            .then((res) => {
                                                const latestLike = res.data.results.reduce((latest, like) => {
                                                    const likeTime = new Date(like.created_at).getTime();
                                                    return likeTime > latest ? likeTime : latest;
                                                }, 0);

                                                return {
                                                    unitName: house.name,
                                                    unitId: house.id,
                                                    unitApartment: house.apartment,
                                                    likeCount: res.data.results.length,
                                                    latestLikedTime: latestLike,
                                                };
                                            })
                                            .catch((error) => {
                                                console.error(`Error fetching like counts for ${house.name}:`, error);
                                                return null;
                                            })
                                    )
                                )
                                    .flat(); // Flatten the array of promises

                                return Promise.all(unitPromisesCount);
                            })
                            .then((likedUnitsWithLikes) => {
                                // Use a set or object to filter out duplicate apartments
                                const uniqueUnits = new Set();
                                const filteredLikedUnitsWithLikes = likedUnitsWithLikes.filter((unit) => {
                                    if (!uniqueUnits.has(unit.unitId && unit.unitApartment)) {
                                        uniqueUnits.add(unit.unitId && unit.unitApartment);
                                        return true;
                                    }
                                    return false;
                                });

                                setLikedUnitsData(likedUnitsWithLikes);
                                // Do whatever you need with the data, such as setting state or updating your UI
                            })
                            .catch((errors) => {
                                // Log errors for individual properties
                                console.error('Error fetching like counts:', errors);
                            });



                        const viewersFavourites = viewersFavouritesRes.data.results;

                        // Process the data and filter favorites based on user ID
                        const updatedDisplayedItems = viewerLeads.map((lead) => {
                            const userId = lead.user;

                            const apartmentsLikedByUser = viewersFavourites.filter(
                                (favorite) => favorite.apartment !== null && favorite.user === userId
                            );

                            const unitsLikedByUser = viewersFavourites.filter(
                                (favorite) => favorite.house !== null && favorite.user === userId
                            );

                            return {
                                ...lead,
                                apartmentsLiked: apartmentsLikedByUser.length,
                                unitsLiked: unitsLikedByUser.length,
                            };
                        });


                        // Set the updated data in the state
                        setViewerLeads(updatedDisplayedItems);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    const uniqueUnits = new Map();

    const filteredLikedUnitsData = likedUnitsData.reduce((result, unit) => {
        const key = `${unit.unitId}-${unit.unitApartment}`;

        // If the unit is not in uniqueUnits or has a later liked time, update the entry
        if (!uniqueUnits.has(key) || unit.latestLikedTime > uniqueUnits.get(key).latestLikedTime) {
            uniqueUnits.set(key, unit);
        }

        return Array.from(uniqueUnits.values());
    }, []);

    // Now filteredLikedUnitsData should contain unique units with the latest liked time

    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = viewerLeads.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = viewerLeads.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const exportCSV = () => {
        // const csvData = [
        //     ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked', 'Unit Liked'],
        //     ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked, item.unitsLiked,]),
        // ];
        const csvData = [
            ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked'],
            ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked,]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Viewer Lead.csv');
    };

    // on search of the unit liked
    const [searchQueryUnit, setSearchQueryUnit] = useState("");
    const [selectedRowUnit, setSelectedRowUnit] = useState(null);

    const handleSearchUnit = (event) => {
        setSearchQueryUnit(event.target.value);
        setSelectedRowUnit(null); // Reset selected row when user starts a new search
    };

    const filteredDataUnit = filteredLikedUnitsData.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryUnit.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageUnit, setCurrentPageUnit] = useState(0);
    const itemsPerPageUnit = 10;

    const totalPagesUnit = Math.ceil(filteredDataUnit.length / itemsPerPageUnit);
    const startIndexUnit = currentPageUnit * itemsPerPageUnit;
    const endIndexUnit = currentPageUnit === totalPagesUnit - 1 ? filteredDataUnit.length : startIndexUnit + itemsPerPageUnit;
    const displayedItemsUnit = filteredDataUnit.slice(startIndexUnit, endIndexUnit);

    const exportCSVUnit = () => {
        const csvData = [
            ['Unit', 'Apartment', 'Likes', 'Liked Date'],
            ...filteredLikedUnitsData.map((item) => [item.unitName, item.unitApartment, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Likes.csv');
    };

    const handlePrevPageUnit = () => {
        setCurrentPageUnit(currentPageUnit - 1);
    };

    const handleNextPageUnit = () => {
        setCurrentPageUnit(currentPageUnit + 1);
    };


      // on search of the property liked
      const [searchQueryProperty, setSearchQueryProperty] = useState("");
      const [selectedRowProperty, setSelectedRowProperty] = useState(null);
  
      const handleSearchProperty = (event) => {
          setSearchQueryProperty(event.target.value);
          setSelectedRowProperty(null); // Reset selected row when user starts a new search
      };
  
      const filteredDataProperty = likedProperties.filter((item) => {
  
          const searchableFields = Object.values(item).join(" ").toLowerCase();
          return searchableFields.includes(searchQueryProperty.toLowerCase());
      });
      // design the table to display 10 each per table
      const [currentPageProperty, setCurrentPageProperty] = useState(0);
      const itemsPerPageProperty = 10;
  
      const totalPagesProperty = Math.ceil(filteredDataProperty.length / itemsPerPageProperty);
      const startIndexProperty = currentPageProperty * itemsPerPageProperty;
      const endIndexProperty = currentPageProperty === totalPagesProperty - 1 ? filteredDataProperty.length : startIndexProperty + itemsPerPageProperty;
      const displayedItemsProperty = filteredDataProperty.slice(startIndexProperty, endIndexProperty);
  
      const exportCSVProperty = () => {
          const csvData = [
              ['Apartment', 'Likes', 'Liked Date'],
              ...likedProperties.map((item) => [item.apartmentName,  item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
              }),]),
          ];
          const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
          saveAs(csvBlob, 'Property Likes.csv');
      };
  
      const handlePrevPageProperty = () => {
          setCurrentPageProperty(currentPageProperty - 1);
      };
  
      const handleNextPageProperty = () => {
          setCurrentPageProperty(currentPageProperty + 1);
      };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of viewerLeads) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [viewerLeads]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>LEADS FOR INTRESTED VIEWERS</p>

                </div>
                <div className='staticCards mt-2'>

                    <Card className='defaulters'>
                        <Card.Body className='d-flex'>

                            <Image src={Apartment} alt='property'></Image>

                            <div className='float-left'>
                                <span className="">Properties</span>
                                <div className='d-flex justify-content-between mt-2'>
                                    <p className='cost'>{likedApartments}</p>
                                    <Button className='btn btn-success btn-sm opacity-75 text-white propertViewers'onClick={handleOpenModal}>View Properties</Button>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>
                    {/* properties modal */}
                    <Modal show={showModal} onHide={handleCloseModal} size='xl'>
                        <Modal.Header closeButton>
                            <Modal.Title>Properties Liked</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='tasks ' >
                                <div className='d-flex justify-content-between'>

                                    <div>
                                        <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVProperty}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                                        {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                                    </div>
                                    <Form className="searchBar">
                                        <FormControl type="text" placeholder="Search" onChange={handleSearchProperty} />
                                    </Form>
                                </div>
                                <Table className='table mt-2'>
                                    <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                        <th>Apartment</th>
                                        <th>Likes</th>
                                        <th>Liked Date</th>

                                    </thead>
                                    <tbody>
                                        {displayedItemsProperty.map((apartment) => (
                                            <tr>

                                                <td>{apartment.apartmentName}</td>
                                                <td>{apartment.likeCount}</td>
                                                <td>{new Date(apartment.latestLikedTime).toLocaleDateString('en-US', {
                                                    month: 'long',
                                                    day: 'numeric',
                                                    year: 'numeric'
                                                })}</td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </Table>
                                <div className="pagination">
                                    <button onClick={handlePrevPageProperty} disabled={currentPageProperty === 0}>
                                        &lt;
                                    </button>
                                    <span>{`${startIndexProperty + 1}-${endIndexProperty} of ${likedProperties.length}`}</span>
                                    <button onClick={handleNextPageProperty} disabled={currentPageProperty === totalPagesProperty - 1}>
                                        &gt;
                                    </button>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    <Card className='occupacy d-none'>
                        <Card.Body className='d-flex'>

                            <Image src={Unit} alt='unit'></Image>

                            <div className='float-left'>
                                <span>Units</span>
                                <div className='d-flex justify-content-between mt-2'>
                                    <p className='cost' >{likedUnits}</p>
                                    <Button className='btn btn-warning btn-sm text-white' style={{ marginLeft: "1rem", height: "4vh" }} onClick={handleOpenModalUnit}>View Unit</Button>

                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    {/* units modal */}
                    <Modal show={showModalUnit} onHide={handleCloseModalUnit} size='xl'>
                        <Modal.Header closeButton>
                            <Modal.Title>Units Liked</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='tasks ' >
                                <div className='d-flex justify-content-between'>

                                    <div>
                                        <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVUnit}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                                        {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                                    </div>
                                    <Form className="searchBar">
                                        <FormControl type="text" placeholder="Search" onChange={handleSearchUnit} />
                                    </Form>
                                </div>
                                <Table className='table mt-2'>
                                    <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                        <th>Unit</th>
                                        <th>Unit Apartment</th>
                                        <th>Likes</th>
                                        <th>Liked Date</th>

                                    </thead>
                                    <tbody>

                                        {displayedItemsUnit.map((unit) => (
                                            <tr>
                                                <td>{unit.unitName}</td>
                                                <td>{unit.unitApartment}</td>

                                                <td>{unit.likeCount}</td>

                                                <td>{new Date(unit.latestLikedTime).toLocaleDateString('en-US', {
                                                    month: 'long',
                                                    day: 'numeric',
                                                    year: 'numeric'
                                                })}</td>

                                            </tr>

                                        ))}


                                    </tbody>
                                </Table>
                                <div className="pagination">
                                    <button onClick={handlePrevPageUnit} disabled={currentPageUnit === 0}>
                                        &lt;
                                    </button>
                                    <span>{`${startIndexProperty + 1}-${endIndexProperty} of ${filteredLikedUnitsData.length}`}</span>
                                    <button onClick={handleNextPageUnit} disabled={currentPageUnit === totalPagesUnit - 1}>
                                        &gt;
                                    </button>
                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModalUnit}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>

                        <div>
                            <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                            {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>
                    </div>
                    <Table className='table mt-2'>
                        <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Date Joined</th>
                            <th>Property Liked</th>
                            {/* <th>Unit Liked</th> */}
                            <th>View</th>
                        </thead>
                        <tbody>

                            {displayedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.user_name}</td>
                                    <td>{lead.user_email}</td>
                                    <td>{lead.user_phone}</td>
                                    <td>{new Date(lead.created_at).toLocaleDateString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: '2-digit'
                                    })}</td>
                                    <td>{lead.apartmentsLiked}</td>
                                    {/* <td>{lead.unitsLiked}</td> */}
                                    <td><Link to={`/viewer-favourites/${lead.user}`}>View</Link> </td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${viewerLeads.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default LeadViewer




