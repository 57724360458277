import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './AddMembers'
import { Button, Card, FloatingLabel, Container, Form, FormControl, Image, Modal, Table } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'
import Apartment from '../../assets/img/apartment.png'
import Unit from '../../assets/img/feedback.png'

function AddContact() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showModalUnit, setShowModalUnit] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // pasing the apartment Details to the modal
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModalUnit = () => {
        setShowModalUnit(true);
    };

    const handleCloseModalUnit = () => {
        setShowModalUnit(false);
    };

    // apartments of the landlord
    const [viewerLeads, setViewerLeads] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [likedProperties, setLikedProperties] = useState([])
    const [likedUnitsData, setLikedUnitsData] = useState([])

    // get the members for alliance
    const [alllianceMembers, setAllianceMembers] = useState([])
    const [nonAlllianceMembers, setNoneAllianceMembers] = useState([])

    const [propertiesManaged, setPropertiesManaged] = useState({});
    const [userRoles, setUserRoles] = useState({});
    const [selectedProperties, setSelectedProperties] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // get the users
                const userResponse = await serverUrl.get(`/landlord/contact-apartment-booking/`, config);
                setAllianceMembers(userResponse.data.results);


                // get the properties managed by the members
                const promises = userResponse.data.results.map((member) => {
                    return serverUrl.get(`/landlord/contact-apartment-booking/?landlord=${member.landlord}`, config)
                        .then((res) => {
                            return {
                                landlordId: member.landlord,
                                propertyCount: res.data.results.length // Count of properties managed by this user
                            };
                        })
                        .catch((error) => {
                            console.log(error);
                            return { landlordId: member.landlord, propertyCount: 0 }; // In case of error, return 0
                        });
                });

                Promise.all(promises)
                    .then((apartmentResults) => {
                        const propertiesManaged = {};
                        apartmentResults.forEach((result) => {
                            propertiesManaged[result.landlordId] = result.propertyCount;
                        });
                        setPropertiesManaged(propertiesManaged);
                    })
                    .catch((error) => {
                        console.log(error);
                    });



                // get the roles for the members
                const userRoles = await Promise.all(
                    userResponse.data.results.map(async (member) => {
                        try {
                            const response = await serverUrl.get(`users/list-user-groups/?user_id=${member.user.id}`,
                                config
                            );
                            return { id: member.user.id, data: response.data };
                        } catch (error) {
                            console.error(`Error fetching data for landlord ${member.landlord}:`, error);
                            return { id: member.user.id, data: [] };
                        }
                    })
                );

                // Update propertiesManaged state with the obtained data
                const newUserRoles = userRoles.reduce((acc, { id, data }) => {
                    acc[id] = data;
                    return acc;
                }, {});

                setUserRoles((prevProperties) => ({
                    ...prevProperties,
                    ...newUserRoles,
                }));
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    // the data of the clicked property
    const handlePropertyClick = (landlord) => {
        serverUrl.get(`/landlord/contact-apartment-booking/?landlord=${landlord}`, config)
            .then((res) => {
                const apartments = res.data.results.map(result => result.apartment);
                // Loop through each apartment and make individual requests
                const apartmentRequests = apartments.map(apartmentId => {
                    return serverUrl.get(`/landlord/apartment/${apartmentId}/`, config);
                });

                // Execute all requests simultaneously
                Promise.all(apartmentRequests)
                    .then(apartmentResponses => {
                        const selectedProperties = apartmentResponses.map(response => response.data);
                        setSelectedProperties(selectedProperties);
                        setShowModal(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };


    // modal for adding member
    const [addMemberModal, setAddMemberModal] = useState(false)

    const handleOpenAddMember = () => {
        setAddMemberModal(true);
    };
    const handleCloseAddMember = () => {
        setAddMemberModal(false);
    };

    const [formDataMember, setFormDataMember] = useState({
        name: '',
        email: '',
        phone: ''
    })

    const handleChangeMember = (event) => {
        const { name, value } = event.target;

        if (name === "phone") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormDataMember((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormDataMember((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

    };
    const digitCount = formDataMember.phone.replace(/[^0-9]/g, "").length;

    function handleAddMember(e) {
        e.preventDefault()

        const data = {
            contact_name: formDataMember.name,
            contact_email: formDataMember.email,
            contact_number: formDataMember.phone,
            apartment:null,
            landlord:null
        }
        // add the user in the user tables
        serverUrl.post('/landlord/contact-apartment-booking/', data,config)
            .then((res) => {

                toast.success('Succesfully added the contact')

                setAddMemberModal(false)
                serverUrl.get(`/landlord/contact-apartment-booking/`, config)
                    .then((res) => {
                        setAllianceMembers(res.data.results);

                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                toast.error('An error occured kindly try again ')
                console.log('Unexpected error: ' + error.message);

               
            })
    }


    const uniqueUnits = new Map();

    const filteredLikedUnitsData = likedUnitsData.reduce((result, unit) => {
        const key = `${unit.unitId}-${unit.unitApartment}`;

        // If the unit is not in uniqueUnits or has a later liked time, update the entry
        if (!uniqueUnits.has(key) || unit.latestLikedTime > uniqueUnits.get(key).latestLikedTime) {
            uniqueUnits.set(key, unit);
        }

        return Array.from(uniqueUnits.values());
    }, []);

    // Now filteredLikedUnitsData should contain unique units with the latest liked time

    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = viewerLeads.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };
    const filteredData = alllianceMembers.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;

    const displayedItems = filteredData.slice(startIndex, endIndex);


    const exportCSV = () => {
        // const csvData = [
        //     ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked', 'Unit Liked'],
        //     ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked, item.unitsLiked,]),
        // ];
        const csvData = [
            ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked'],
            ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked,]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Viewer Lead.csv');
    };

    // on search of the unit liked
    const [searchQueryUnit, setSearchQueryUnit] = useState("");
    const [selectedRowUnit, setSelectedRowUnit] = useState(null);

    const handleSearchUnit = (event) => {
        setSearchQueryUnit(event.target.value);
        setSelectedRowUnit(null); // Reset selected row when user starts a new search
    };

    const filteredDataUnit = nonAlllianceMembers.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryUnit.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageUnit, setCurrentPageUnit] = useState(0);
    const itemsPerPageUnit = 10;

    const totalPagesUnit = Math.ceil(filteredDataUnit.length / itemsPerPageUnit);
    const startIndexUnit = currentPageUnit * itemsPerPageUnit;
    const endIndexUnit = currentPageUnit === totalPagesUnit - 1 ? filteredDataUnit.length : startIndexUnit + itemsPerPageUnit;
    const displayedItemsUnit = filteredDataUnit.slice(startIndexUnit, endIndexUnit);

    const exportCSVUnit = () => {
        const csvData = [
            ['Unit', 'Apartment', 'Likes', 'Liked Date'],
            ...nonAlllianceMembers.map((item) => [item.unitName, item.unitApartment, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Likes.csv');
    };

    const handlePrevPageUnit = () => {
        setCurrentPageUnit(currentPageUnit - 1);
    };

    const handleNextPageUnit = () => {
        setCurrentPageUnit(currentPageUnit + 1);
    };


    // on search of the property liked
    const [searchQueryProperty, setSearchQueryProperty] = useState("");
    const [selectedRowProperty, setSelectedRowProperty] = useState(null);

    const handleSearchProperty = (event) => {
        setSearchQueryProperty(event.target.value);
        setSelectedRowProperty(null); // Reset selected row when user starts a new search
    };

    const filteredDataProperty = likedProperties.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryProperty.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageProperty, setCurrentPageProperty] = useState(0);
    const itemsPerPageProperty = 10;

    const totalPagesProperty = Math.ceil(filteredDataProperty.length / itemsPerPageProperty);
    const startIndexProperty = currentPageProperty * itemsPerPageProperty;
    const endIndexProperty = currentPageProperty === totalPagesProperty - 1 ? filteredDataProperty.length : startIndexProperty + itemsPerPageProperty;
    const displayedItemsProperty = filteredDataProperty.slice(startIndexProperty, endIndexProperty);

    const exportCSVProperty = () => {
        const csvData = [
            ['Apartment', 'Likes', 'Liked Date'],
            ...likedProperties.map((item) => [item.apartmentName, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Property Likes.csv');
    };

    const handlePrevPageProperty = () => {
        setCurrentPageProperty(currentPageProperty - 1);
    };

    const handleNextPageProperty = () => {
        setCurrentPageProperty(currentPageProperty + 1);
    };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of viewerLeads) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [viewerLeads]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const [replaceMemberModal, setReplaceMemberModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState('')
    const [replaceMember, setReplaceMember] = useState('')
    const [selectedMemberId, setSelectedMemberId] = useState('')

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    // delete the alliance member   
    const removeRole = (member) => {
        serverUrl.delete(`/landlord/contact-apartment-booking/${member}/`, config)
            .then((res) => {
                toast.success('Succesfully delete the contact')
                serverUrl.get(`landlord/contact-apartment-booking/`, config)
                    .then((res) => {
                        setAllianceMembers(res.data.results);

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    };


    const handleCloseReplace = () => {
        setReplaceMemberModal(false)
    }
    const handleOpenReplace = (user, member) => {
        setReplaceMemberModal(true)
        setSelectedMember(user)
        setSelectedMemberId(member)

    }


    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>ALLIANCE REALTORS CONTACTS</p>

                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>

                        <div>
                            {/* <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button> */}
                            <Button className='btn btn-warning btn-sm text-white' onClick={handleOpenAddMember}>Add Contact <i class="bi bi-person-plus"></i></Button>
                        </div>
                        <h5 className='text-info'>Exisiting Conatcts</h5>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>

                    </div>

                    <Table className='table mt-2'>
                        <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>

                            {displayedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.contact_name}</td>
                                    <td>{lead.contact_email}</td>
                                    <td>{lead.contact_number}</td>
                                    <td>
                                    <Button className='btn btn-danger text-white' onClick={() => removeRole(lead.id)} ><i className='bi bi-trash-fill'></i></Button>

                                    </td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                



                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${alllianceMembers.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>


                {/* modal to add member */}
                <Modal show={addMemberModal} onHide={handleCloseAddMember}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleAddMember}>
                            <FloatingLabel
                                controlId="Name"
                                label="Contact Full Names"
                                style={{ marginBottom: "1rem" }}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter contact's name"
                                    name='name'
                                    value={formDataMember.name}
                                    onChange={handleChangeMember}
                                    required
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="Email"
                                label="Email"
                                style={{ marginBottom: "1rem" }}
                            >
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    name='email'
                                    value={formDataMember.email}
                                    onChange={handleChangeMember}
                                    required
                                />
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="Phone"
                                label="Phone Number"
                                style={{ marginBottom: "1rem" }}
                            >
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter phone number"
                                    name='phone'
                                    value={formDataMember.phone}
                                    onChange={handleChangeMember}
                                    maxLength={10}
                                    required
                                />
                            </FloatingLabel>
                            <p
                                style={{
                                    fontSize: "12px",
                                    paddingRight: "20px",
                                    float: "right",
                                }}
                            >
                                {digitCount}/10
                            </p>
                            <br></br>
                            <br></br>

                            <Button className='btn btn-sm btn-success' type="submit">
                                Add Contact
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>





            </div>

        </div >
    )
}

export default AddContact




