import React, { useState, useEffect,useMemo } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './LeadViewer.scss'
import { Button, Card,  Form, FormControl, Image,  Table } from 'react-bootstrap';
import { saveAs } from 'file-saver';

// auth imports
import { serverUrl } from "../../serverUrl";
import {  useParams } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'
import Apartment from '../../assets/img/apartment.png'
import Unit from '../../assets/img/feedback.png'

function LeadViewerFavourites() {
    const { id } = useParams();

    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
       // apartments of the landlord
    const [viewerLeads, setViewerLeads] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = useMemo(() => {
        return {
            headers: { Authorization: `Bearer ${access}` }
        };  
    })

    const [likedApartments, setLikedApartments] = useState('')
    const [likedUnits, setLikedUnits] = useState('')
    const likes = []
    const [selectedViewer, setSelectedViewer] = useState({})

    useEffect(() => {
        // Get the data for the viewer lead of the specific user
        serverUrl.get(`/landlord/alliance-viewer-lead?user_id=${id}`, config)
            .then((viewerLeadsRes) => {
                const viewerLeads = viewerLeadsRes.data.results[0];
                setSelectedViewer(viewerLeadsRes.data.results[0])

                // Get the amount of liked apartments and liked units based on user ID
                serverUrl.get(`/landlord/viewers-favourites/?user=${id}`, config)
                    .then((viewersFavouritesRes) => {
                        const viewersFavourites = viewersFavouritesRes.data.results;
                        const apartmentsLiked = viewersFavourites.filter((favorite) => favorite.apartment !== null);
                        setLikedApartments(apartmentsLiked.length);

                        const unitsLiked = viewersFavourites.filter((favorite) => favorite.house !== null);
                        setLikedUnits(unitsLiked.length);

                        // Define promises to fetch additional data for houses and apartments
                        const housePromises = unitsLiked.map((favorite) => {
                            const houseId = favorite.house;
                            return serverUrl.get(`/landlord/house/${houseId}`, config)
                                .then((houseRes) => {
                                    return {
                                        ...favorite,
                                        houseNumber: houseRes.data.house_number,
                                    };
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return favorite;
                                });
                        });

                        const apartmentPromises = apartmentsLiked.map((favorite) => {
                            const apartmentId = favorite.apartment;
                            return serverUrl.get(`/landlord/apartment/${apartmentId}`, config)
                                .then((apartmentRes) => {
                                    return {
                                        ...favorite,
                                        apartmentName: apartmentRes.data.name,
                                    };
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return favorite;
                                });
                        });

                        // Wait for all promises to resolve
                        Promise.all([...housePromises, ...apartmentPromises])
                            .then((updatedFavorites) => {
                                setViewerLeads(updatedFavorites);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setIsLoading(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
                    
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [id]);





    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = viewerLeads.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = viewerLeads.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const exportCSV = () => {
        const csvData = [
            ['Unit', 'Apartment', 'Date Liked'],
            ...viewerLeads.map((item) => [item.houseNumber, item.apartmentName, item.updated_at,]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Viewers Favourite.csv');
    };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of viewerLeads) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [viewerLeads]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>{selectedViewer.user_name} FAVOURITE'S</p>
                    <span className='properties'>{selectedViewer.user_phone} </span>


                </div>
                <div className='staticCards mt-2'>

                    <Card className='defaulters'>
                        <Card.Body className='d-flex'>

                            <Image src={Apartment} alt='property'></Image>

                            <div className='float-left'>
                                <span className="">Properties</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>{likedApartments}</p>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>



                    <Card className='occupacy'>
                        <Card.Body className='d-flex'>

                            <Image src={Unit} alt='unit'></Image>

                            <div className='float-left'>
                                <span>Units</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost' >{likedUnits}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>
                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>

                        <div>
                            <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                            {/* <Button className='btn btn-danger text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>
                    </div>
                    <Table className='table mt-2'>
                        <thead className='underline'>
                            <th>Unit</th>
                            <th>Apartment</th>
                            <th>Date Liked</th>
                        </thead>
                        <tbody>

                            {displayedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.houseNumber}</td>
                                    <td>{lead.apartmentName}</td>
                                    <td>{new Date(lead.updated_at).toLocaleDateString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: '2-digit'
                                    })}</td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${viewerLeads.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default LeadViewerFavourites




