import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { Table, Button, Form, FormControl, Card, Modal, Image, Row, Col, Tab, FormSelect, InputGroup } from 'react-bootstrap';
import './Wallet.scss'
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import WithdrawR from '../../assets/img/money-withdrawal.png'
import Withdraw from '../../assets/img/purse.png'
import Deposit from '../../assets/img/deposit.png'
import Cost from '../../assets/img/withdraw.png'
import Wallet1 from '../../assets/img/wallet_1.png'
import WalletCheckers from './WalletCheckers';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
function Wallet() {
    // sidebar and header functions
 // getting the id and finding the apparment details
 const { id } = useParams();
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
     // selected Apartment
  const [apartmentSelected, setApartmentSelected] = useState([])


  // get the user who is logged in and the date related to them
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  useEffect(() => {
    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);

        // using the landlord id get the apartments linked to the landlord
        landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => {
              const selectedApartmentObj = res.data.results.find(utilitiesApartment => utilitiesApartment.id === parseInt(id));
              setApartmentSelected(selectedApartmentObj)
            })
            .catch((error) => {
              console.log(error);
            });

        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {

        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 7) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 7) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/reports/reports${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    // design for the transactions table
    const transactionTable = [
        {
            to: "Joy Kiragu",
            amount: "KES 100.00",
            date: "Aug 18,2023, 12:38:28 PM",
            status: "Pending",

        },
        {
            to: "Joy Kiragu",
            amount: "KES 100.00",
            date: "Aug 18,2023, 12:38:28 PM",
            status: "Pending",

        },
        {
            to: "Joy Kiragu",
            amount: "KES 100.00",
            date: "Aug 18,2023, 12:38:28 PM",
            status: "Pending",

        },
        {
            to: "Joy Kiragu",
            amount: "KES 100.00",
            date: "Aug 18,2023, 12:38:28 PM",
            status: "Pending",

        },
        {
            to: "Joy Kiragu",
            amount: "KES 100.00",
            date: "Aug 18,2023, 12:38:28 PM",
            status: "Pending",

        },
    ]

    // data for the transaction records
    const transactionRecords = [
        {
            date: "15-09-2023",
            to: "Joy Kiragu",
            amount: "200",
            cost: "20",
            balance: "4000",

        },
        {
            date: "15-09-2023",
            to: "Joy Kiragu",
            amount: "200",
            cost: "20",
            balance: "4000",

        },
        {
            date: "15-09-2023",
            to: "Joy Kiragu",
            amount: "200",
            cost: "20",
            balance: "4000",

        }
    ]
    const exportCSV = () => {
        const csvData = [
            ['Date', 'To/From', 'Amount', 'Transaction Cost', 'Balance'],
            ...transactionRecords.map((item) => [item.date, item.to, item.amount, item.cost, item.balance]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'transaction_records.csv');
    };

    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };
    const sortedData = transactionRecords.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // incomplete transactions
    const incompleteTransactions = [
        {
            date: "12-05-2023",
            to: "Joy Kiragu",
            amount: "200",
            status: "Pending"
        },
        {
            date: "12-05-2023",
            to: "Joy Kiragu",
            amount: "200",
            status: "Pending"
        },
        {
            date: "12-05-2023",
            to: "Joy Kiragu",
            amount: "200",
            status: "Pending"
        },
        {
            date: "12-05-2023",
            to: "Joy Kiragu",
            amount: "200",
            status: "Pending"
        },
        {
            date: "12-05-2023",
            to: "Joy Kiragu",
            amount: "200",
            status: "Pending"
        }
    ]

    const exportCSVIncomplete = () => {
        const csvData = [
            ['Date', 'To/From', 'Amount', 'Status'],
            ...incompleteTransactions.map((item) => [item.date, item.to, item.amount, item.status]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'incomplete_transaction_records.csv');
    };

    const [sortTypeIncomplete, setSortTypeIncomplete] = useState('name');
    const [sortOrderIncomplete, setSortOrderIncomplete] = useState('asc');
    const [searchQueryIncomplete, setSearchQueryIncomplete] = useState('');
    const [selectedRowIncomplete, setSelectedRowIncomplete] = useState(null);

    const handleSearchIncomplete = (event) => {
        setSearchQueryIncomplete(event.target.value);
        setSelectedRowIncomplete(null); // Reset selected row when user starts a new search
    };
    const sortedDataIncomplete = incompleteTransactions.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredDataIncomplete = sortedDataIncomplete.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    const [currentPageIncomplete, setCurrentPageIncomplete] = useState(0);
    const itemsPerPageIncomplete = 5;

    const totalPagesIncomplete = Math.ceil(filteredDataIncomplete.length / itemsPerPageIncomplete);
    const startIndexIncomplete = currentPageIncomplete * itemsPerPageIncomplete;
    const endIndexIncomplete = currentPageIncomplete === totalPagesIncomplete - 1 ? filteredDataIncomplete.length : startIndexIncomplete + itemsPerPageIncomplete;
    const displayedItemsIncomplete = filteredDataIncomplete.slice(startIndexIncomplete, endIndexIncomplete);

    // modal for the receipt view
    const [receiptModal, setShowReceiptModal] = useState(false)
    const handleOpenReceipt = () => {
        setShowReceiptModal(true)
    }
    const handleCloseReceipt = () => {
        setShowReceiptModal(false)
    }

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // show balance
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    //   show account
    const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalanace = () => {
        setShowBalance((prevShowBalance) => !prevShowBalance);
    };

    // the list of the widthraws
    const [showWithdrawal, setShowWithdrawal] = useState(false)

    const handleOpenWithdraw = () => {
        setShowWithdrawal(true)
    }
    const handleCloseWithdraw = () => {
        setShowWithdrawal(false)
        window.location.reload()
    }

    // the view withdrawal request
    const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)

    const handleOpenRequest = () => {
        setShowWithdrawalModal(true)
        setShowWithdrawal(false)

    }
    const handleCloseRequest = () => {
        setShowWithdrawalModal(false)
    }

    // the calculator modal
    const [showCalculator, setShowCalculator] = useState(false)

    const handleOpenCalculator = () => {
        setShowCalculator(true)

    }
    const handleCloseCalculator = () => {
        setShowCalculator(false)
        window.location.reload()

    }

    // the deposit calculator
    const [showDeposit, setShowDeposit] = useState(false)

    const handleOpenDeposit = () => {
        setShowDeposit(true)

    }
    const handleCloseDeposit = () => {
        setShowDeposit(false)
        window.location.reload()


    }

    // modal for the withdraw view
    const [makeWithdraw, setShowMakeWithdraw] = useState(false)
    const handleOpenMakeWithdraw = () => {
        setShowMakeWithdraw(true)
    }
    const handleCloseMakeWithdraw = () => {
        setShowMakeWithdraw(false)
    }
    // if the bank or mpesa is selected
    const [selectedChannel, setSelectedChannel] = useState('');


    const handleChannelChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedChannel(selectedValue);

    };

    // modal for the add bank account name view
    const [addAccount, setShowAddAccount] = useState(false)
    const handleOpenAddAccount = () => {
        setShowAddAccount(true)
        handleCloseMakeWithdraw()
    }
    const handleCloseAddAccount = () => {
        setShowAddAccount(false)
    }

    // modal for downloading the report
    const [downloadReport, setShowDownloadReport] = useState(false)
    const handleOpenDownloadReport = () => {
        setShowDownloadReport(true)
    }
    const handleCloseDownloadReport = () => {
        setShowDownloadReport(false)
    }

    // filtter date
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='wallet_dashboard'>

                {/* top display */}
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${Wallet1})` }}>
                    <p className='properties'>{apartmentSelected.name} WALLET</p>

                </div>

                {/* top cards */}
                <div className='staticCards'>


                    <Card className='rentDue' onClick={handleOpenWithdraw}>
                        <Card.Body className='d-flex'>

                            <Image src={WithdrawR} alt='water'></Image>

                            <div className='float-left'>
                                <span>Withdraw request</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm' onClick={handleOpenWithdraw}>View Request</Button>
                                    <span className='notificationSpan'>6</span>
                                </div>

                            </div>
                        </Card.Body>

                        {/* the list of the transactions */}
                        <Modal show={showWithdrawal} onHide={handleCloseWithdraw} className='modal-xl modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Outbound Transactions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table>
                                    <thead className="underline">
                                        <tr>
                                            <th >
                                                To<i className="bi bi-chevron-expand"></i>
                                            </th>

                                            <th>
                                                Amount<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            <th >
                                                Date<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            <th className='w-25' >
                                                Status<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            <th >
                                                Action<i className="bi bi-chevron-expand"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionTable.map((landlord) => (
                                            <tr>
                                                <td>{landlord.to}</td>
                                                <td>{landlord.amount}</td>
                                                <td>{landlord.date}</td>
                                                <td><Button className='btn btn-sm btn-warning text-white'>{landlord.status}</Button></td>
                                                <td><Button className='btn btn-sm' onClick={handleOpenRequest}>View</Button></td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </Table>

                            </Modal.Body>

                            {/* <Modal.Footer className="d-flex justify-content-between">
                                <Button variant='secondary' onClick={handleCloseWithdraw}>
                                    Close
                                </Button>
                              
                            </Modal.Footer> */}
                        </Modal>

                        {/* the withdrawal request modal */}
                        <Modal xl show={showWithdrawalModal} onHide={handleCloseRequest} className='modal-xl modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Transaction Request Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Row>
                                        <Col md={5}>
                                            <div>
                                                <label className='withLabel w-100'>Request Date:</label>
                                                <input type='text' className='inputWithdraw' placeholder='Aug 18,2023, 12:38:28 PM' />
                                            </div>

                                            <div>
                                                <label className='withLabel'>To:</label>
                                                <input type='text' className='inputWithdraw' placeholder='Joy Kiragu  0748646894' />
                                            </div>

                                            <div>
                                                <label className='withLabel'>Amount:</label>
                                                <input type='text' className='inputWithdraw' placeholder='KES 100.00' />
                                            </div>

                                            <div>
                                                <label className='withLabel'>Cost:</label>
                                                <input type='text' className='inputWithdraw' placeholder='KES 0.00' />
                                            </div>


                                            <div>
                                                <label className='withLabel'>Reason:</label>
                                                <input type='text' className='inputWithdraw' placeholder='Repairs' />
                                            </div>

                                            <div>
                                                <label className='withLabel'>Status:</label>
                                                <Button className='btn btn-sm btn-warning text-white'>Pending</Button>
                                            </div>

                                        </Col>

                                        <Col md={7}>
                                            <h4>Maker</h4>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>CONFIRMATION STATUS</th>
                                                        <th>TIME</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>

                                                        <td>Joy Kiragu</td>
                                                        <td><Button className='btn btn-sm btn-success'>Approved</Button></td>
                                                        <td>Aug 18,2023, 12:38:28 PM</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <h4>Checkers</h4>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>CONFIRMATION STATUS</th>
                                                        <th>TIME</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>

                                                        <td>Mary Wairimu</td>
                                                        <td><Button className='btn btn-sm btn-success'>Approved</Button></td>
                                                        <td>Aug 18,2023, 12:38:28 PM</td>
                                                    </tr>
                                                    <tr>

                                                        <td>Abel Kimani</td>
                                                        <td><Button className='btn btn-sm btn-warning text-white'>Pending</Button></td>
                                                        {/* <td>Aug 18,2023, 12:38:28 PM</td> */}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Button className='btn btn-sm btn-success text-white'>APPROVE</Button>
                                    <Button className='btn btn-sm btn-danger ms-5 text-white'>REJECT</Button>

                                </Form>


                            </Modal.Body>
                        </Modal>

                    </Card>

                    <Card className='defaulters' onClick={handleOpenCalculator}>
                        <Card.Body className='d-flex'>

                            <Image src={Cost} alt='security'></Image>

                            <div className='float-left'>
                                <span className="">Cost Calculator</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-warning text-white' onClick={handleOpenCalculator}>Calculate</Button>
                                </div>
                            </div>
                        </Card.Body>

                        {/* modal for calculator */}
                        <Modal show={showCalculator} onHide={handleCloseCalculator} className='modal-lg modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Cost Calculator</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Withdraw To</label>
                                    <FormSelect>
                                        <option selected disabled>Select a channel</option>

                                        <option>Bank</option>
                                        <option>MPESA</option>
                                    </FormSelect>
                                </div>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Amount</label>
                                    <FormControl placeholder='Enter Amount To Withdraw' />
                                </div>

                                <input type='text' className='calculatorInput' placeholder='Processing Fee:KES 0.00' />

                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='btn btn-sm' >Calculate</Button>
                                <Button className='btn btn-sm' variant='secondary' onClick={handleCloseCalculator}>Close</Button>

                            </Modal.Footer>
                        </Modal>

                    </Card>

                    <Card className='occupacy' onClick={handleOpenDeposit}>
                        <Card.Body className='d-flex'>

                            <Image src={Deposit} alt='internet'></Image>

                            <div className='float-left'>
                                <span>Deposit</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-success text-white' onClick={handleOpenDeposit}>Make Deposit</Button>
                                </div>

                            </div>
                        </Card.Body>

                        <Modal show={showDeposit} onHide={handleCloseDeposit}>
                            <Modal.Header className='' closeButton>
                                <Modal.Title>Deposit to wallet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Amount</label>
                                    <FormControl placeholder='Minimal Amount is 100' />

                                    {/* <FormSelect>
                                        <option selected disabled>Select a channel</option>

                                        <option>Bank</option>
                                        <option>MPESA</option>
                                    </FormSelect> */}
                                </div>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Phone Numbert</label>
                                    <FormControl placeholder='Enter Safaricom Phone Number' />
                                </div>


                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant='primary' onClick={handleCloseDeposit}>
                                    Send
                                </Button>
                                <Button variant='secondary' onClick={handleCloseDeposit}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </Card>

                    <Card className='income'>
                        <Card.Body className='d-flex'>

                            <Image src={Withdraw} alt='garbage'></Image>

                            <div className='float-left'>
                                <span className=''>Withdraw</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-info text-white' onClick={handleOpenMakeWithdraw} >Withdraw</Button>

                                </div>

                            </div>
                        </Card.Body>
                        {/* modal for the withdra */}
                        <Modal show={makeWithdraw} onHide={handleCloseMakeWithdraw}>
                            <Modal.Header closeButton>
                                <Modal.Title>Make Withdraw</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Amount</label>
                                    <FormControl placeholder='Enter Amount To Withdraw' />

                                </div>

                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Withdraw To</label>

                                    <FormSelect onChange={handleChannelChange}>
                                        <option value='' selected disabled>Select a channel</option>
                                        <option value='Bank'>Bank</option>
                                        <option value='MPESA'>MPESA</option>
                                    </FormSelect>

                                    {selectedChannel === 'Bank' && (
                                        <div className='mt-3 d-flex justify-content-between'>
                                            <FormSelect className='w-50'>
                                                <option selected disabled>Select Bank</option>

                                                <option>KCB</option>
                                                <option>Equity</option>
                                                <option>Co-operative Bank</option>
                                            </FormSelect>
                                            <Button className='btn btn-sm' onClick={handleOpenAddAccount}>Add Bank Account</Button>
                                        </div>
                                    )}
                                    {selectedChannel === '' && (
                                        <></>
                                    )}

                                    {selectedChannel === 'MPESA' && (
                                        <>
                                            <div className='mb-3'>
                                                <label className='withLabel w-100'>Select a classification</label>

                                                <FormSelect>
                                                    <option selected disabled>Non-Member</option>
                                                    <option>Joy Wairimu</option>
                                                    <option>Alex Mwendwa</option>
                                                </FormSelect>
                                            </div>

                                            <div className='mb-3'>
                                                <label className='withLabel w-100'>Phone Number</label>
                                                <FormControl placeholder='Enter Phone Number' />
                                            </div>

                                            <div className='mb-3'>
                                                <label className='withLabel w-100'>Receipient Name</label>
                                                <FormControl placeholder='Enter Receipient Name' />
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Expense Category</label>
                                    <FormControl placeholder='Select Category of Expenses/Reason' />
                                </div>

                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Reason for withdrawal</label>
                                    <FormControl placeholder='Enter Reason for withdrawal' />
                                </div>
                            </Modal.Body>

                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant='secondary' onClick={handleCloseMakeWithdraw}>
                                    <i className='bi bi-printer'></i> Print
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </Card>
                </div>

                {/* modal for adding account */}
                <Modal show={addAccount} onHide={handleCloseAddAccount} className='modal-dialog-scrollable'>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Bank Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3'>
                            <label className='withLabel w-100'>Bank Name</label>

                            <FormSelect>
                                <option selected disabled>Select a channel</option>
                                <option>Equity</option>
                                <option>Family Bank</option>
                            </FormSelect>
                        </div>
                        <div className='mb-3'>
                            <label className='withLabel w-100'>Account Number</label>
                            <FormControl placeholder='Enter Account Number' />

                        </div>

                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant='secondary' onClick={handleCloseAddAccount}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* accout details */}

                <div className='account_details'>
                    <div className='d-flex'>
                        <Card>
                            <Card.Body>
                                <p className='Balance text-uppercase fs-3 fw-bold'>Account Balance</p>
                                <div className="input-icons">
                                    <i style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                        {showPassword ? <i className="fa fa-eye icon"></i>
                                            : <i className="fa fa-eye-slash icon"></i>

                                        }
                                    </i>
                                    <input
                                        className="input-field"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        value="Ksh.2,201"
                                    />

                                </div>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='d-flex'>
                        <Card>
                            <Card.Body>
                                <p className='text-warning text-uppercase fs-3 fw-bold'>Account Number: </p>
                                <div className="input-icons">
                                    <i style={{ cursor: "pointer" }} onClick={handleToggleBalanace}>
                                        {showBalance ? <i className="fa fa-eye icon"></i>
                                            : <i className="fa fa-eye-slash icon"></i>

                                        }
                                    </i>
                                    <input
                                        className="input-field"
                                        type={showBalance ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        value="1523674415"
                                    />

                                </div>


                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className='d-flex mt-4' style={{ justifyContent: "right" }}>
                    <Button onClick={handleOpenDownloadReport}>Download Report <i class="bi bi-file-pdf"></i></Button>
                </div>

                {/* modal for downloading the report  */}
                <Modal show={downloadReport} onHide={handleCloseDownloadReport} className='modal-dialog-scrollable'>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter by Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex w-100 justify-content-center' >

                            <InputGroup className='justify-content-center'>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Rent' ? 'typeActive text-white' : 'text-dark bg-transparent'} p-3`}
                                    onClick={() => handleButtonClick('Today')}
                                >
                                    Today
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'RentToOwn' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('7D')}
                                >
                                    7D
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Purchase' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('30D')}
                                >
                                    30D
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Purchase' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('1Y')}
                                >
                                    1Y
                                </Button>
                            </InputGroup>
                        </div>

                        <div className='d-flex w-100 mt-2 justify-content-center' >

                        
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant='secondary' onClick={handleCloseDownloadReport}>
                            Download CSV <i className="bi bi-file-earmark-excel "></i>
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* transactions table */}
                <div className="tasks mt-4">
                    <h2>Transaction Records </h2>

                    <div className="d-flex justify-content-between">
                        <div>
                            <Button className="btn btn-success text-white me-3" onClick={exportCSV}>
                                {' '}
                                EXCEL <i className="bi bi-file-earmark-excel "></i>
                            </Button>

                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>
                    </div>

                    <Table className="table mt-3">
                        <thead className="underline">
                            <tr>
                                <th >
                                    Date<i className="bi bi-chevron-expand"></i>
                                </th>

                                <th>
                                    To/From<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Amount<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Transaction Cost<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Balance<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Receipt<i className="bi bi-chevron-expand"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedItems.map((landlord) => (
                                <tr>
                                    <td>{landlord.date}</td>
                                    <td>{landlord.to}</td>
                                    <td>{landlord.amount}</td>
                                    <td>{landlord.cost}</td>
                                    <td>{landlord.balance}</td>

                                    <td><Button className='btn btn-sm' onClick={handleOpenReceipt}>Print Receipt</Button></td>


                                </tr>

                            ))}

                        </tbody>
                        {/* the modal for the receipt */}
                        <Modal show={receiptModal} onHide={handleCloseReceipt} className='modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Payment Receipt</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='fw-bold'>Payment Details</p>
                                <p className='fw-bold'>Joy Kiragu</p>
                                <p className='fw-bold'>0748646894</p>
                                <p><b>Date:</b><span>Sep 18,2023, 11:12:33 AM</span></p>
                                <p><b>Amount:</b>KES 100.00</p>
                                <p><b>Transaction Cost:</b> KES 00.00</p>
                                <p className='fw-bold'>Payment Reason:</p>
                                <span>Repairs</span>
                                <p>Generated On Tuesday, October 3, 2023 10:19:11 AM</p>

                            </Modal.Body>

                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant='secondary' onClick={handleCloseReceipt}>
                                    <i className='bi bi-printer'></i> Print
                                </Button>

                            </Modal.Footer>
                        </Modal>
                    </Table>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${transactionRecords.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                </div>

                {/* incomplete transactions table */}
                <div className="tasks mt-4">
                    <h2 className='text-success'>Incomplete Transactions </h2>

                    <div className="d-flex justify-content-between">
                        <div>
                            <Button className="btn btn-success text-white me-3" onClick={exportCSVIncomplete}>
                                {' '}
                                EXCEL <i className="bi bi-file-earmark-excel "></i>
                            </Button>

                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearchIncomplete} />
                        </Form>
                    </div>
                    <Table className="table mt-3">
                        <thead className="underline">
                            <tr>
                                <th >
                                    Date<i className="bi bi-chevron-expand"></i>
                                </th>

                                <th>
                                    To/From<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Amount<i className="bi bi-chevron-expand"></i>
                                </th>
                                <th >
                                    Status<i className="bi bi-chevron-expand"></i>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {displayedItemsIncomplete.map((landlord) => (
                                <tr>
                                    <td>{landlord.date}</td>
                                    <td>{landlord.to}</td>
                                    <td>{landlord.amount}</td>
                                    <td><Button className='btn btn-sm btn-warning text-white'>{landlord.status}</Button></td>

                                </tr>

                            ))}

                        </tbody>

                    </Table>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndexIncomplete + 1}-${endIndexIncomplete} of ${incompleteTransactions.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPageIncomplete === totalPagesIncomplete - 1}>
                            &gt;
                        </button>
                    </div>

                </div>

                <WalletCheckers />

            </div >
        </>
    )
}

export default Wallet