import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
// import { PDFExport } from '@progress/kendo-react-pdf';

function PdfR() {
  const [data, setData] = useState([
    { name: 'John', age: 30, email: 'john@example.com' },
    { name: 'Mary', age: 25, email: 'mary@example.com' },
    { name: 'Bob', age: 35, email: 'bob@example.com' },
  ]);

  const exportCSV = () => {
    const csvData = [
      ['Name', 'Age', 'Email'],
      ...data.map(item => [item.name, item.age, item.email])
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'data.csv');
  };

  const pdfExportRef = React.useRef();

  // const exportPDF = () => {
  //   pdfExportRef.current.save();
  // };

  return (
    <>
   
      <button onClick={exportCSV}>Export CSV</button>
      {/* <PDFExport ref={pdfExportRef}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.age}</td>
                <td>{item.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </PDFExport> */}
    </>
  );
}

export default PdfR;
