import React, { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import Header from './Header'
import SideBar from './SideBar'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown } from 'react-bootstrap';
import { serverUrl } from '../../serverUrl';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

function SelectedRules() {
    const [showSidebar, setShowSidebar] = useState(true);
    const { id, house } = useParams();

    // loading status
    const [isLoading, setIsLoading] = useState(true)

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }


    const [rules, setRules] = useState([])
    useEffect(() => {
        serverUrl.get(`/landlord/rules/?category=${id}&apartment=${house}`, config)
            .then((res) => {
                setRules(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    // modal for add rule
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // modal for editing rule
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selectedRule, setselectedRule] = useState('')

    const handleOpenModalEdit = ruleId => {
        setselectedRule(ruleId);
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    };

    const [showModalDelete, setShowModalDelete] = useState(false);

    const handleOpenModalDelete = ruleId => {
        setselectedRule(ruleId);
        setShowModalDelete(true);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };
    // form data to be sent to backend on editing the apartment
    const [formData, setFormData] = useState({
        description: '',
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [formDataEdit, setFormDataEdit] = useState({
        description: '',
    });
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormDataEdit((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        serverUrl.get(`/landlord/rules/${selectedRule}/`,config)
        .then((res)=>{
            if (selectedRule) {
                setFormDataEdit({
                    description: res.data.description || '',
                });
            }
        })
        .catch((error)=>{
            console.log(error)
        })
       
    }, [selectedRule]);

    function handleSubmit(e) {
        e.preventDefault();
        const data = {
            description: formData.description,
            category: id,
            apartment: house,
        };
        try {
            serverUrl.post(`/landlord/rules/`, data, config)
                .then((res) => {
                    toast.success('Successfully added new rules!'); serverUrl.get(`/landlord/rules/?category=${id}&apartment=${house}`, config)
                        .then((res) => {
                            setRules(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    setShowModal(false);
                    setFormData({
                        description: '',
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        alert('Error while changing the details of the apartment' + error.message);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }

    }

    function handleSubmitEdit(e) {
        e.preventDefault();
        const data = {
            description: formDataEdit.description,
            category: id,
            apartment: house,
        };
        try {
            serverUrl.patch(`/landlord/rules/${selectedRule}/`, data, config)
                .then((res) => {
                    toast.success('Successfully update the rule!');
                    setShowModalEdit(false);

                    serverUrl.get(`/landlord/rules/?category=${id}&apartment=${house}`, config)
                        .then((res) => {
                            setRules(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    setShowModal(false);
                    setFormData({
                        description: '',
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        alert('Error while changing the details of the apartment' + error.message);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }

    }

    function handleSubmitDelete(e) {
        e.preventDefault();
        try {
            serverUrl.delete(`/landlord/rules/${selectedRule}/`, config)
                .then((res) => {
                    toast.success('Successfully deleted the  rule!');
                    setShowModalDelete(false);

                    serverUrl.get(`/landlord/rules/?category=${id}&apartment=${house}`, config)
                        .then((res) => {
                            setRules(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                    setShowModal(false);
                    setFormData({
                        description: '',
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        console.log('Error while changing the details of the apartment' + error.message);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });
        } catch (error) {
            console.log(error);
        }

    }
    // apartment background image
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);
    const backgroundImage = backgroundImageApartment;
    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}


            <div className='selected-property'>

                {/* check if the apartment has an image */}

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>

                    <div className='ImageHouseName'>
                        <Button className='btn btn-sm btn-success me-2' onClick={handleOpenModal}>Add Rule</Button>

                        <h2 className='pt-5'>{toCamelCase(id)}</h2>
                    </div>

                </div>

                <div className='mt-3'>
                    <h2 className='text-uppercase text-warning text-bold'>The rules under {toCamelCase(id)}</h2>

                    <ul>
                        {rules.map((rule) => (
                            <div className='d-flex'>
                                <li className='text-start'>{rule.description}</li>
                                <div className='d-flex ms-4'>
                                    <Button className='btn btn-sm btn-success me-3' style={{ height: "5vh" }} onClick={() => handleOpenModalEdit(rule.id)}><i className='bi bi-pen'></i></Button>
                                    <Button className='btn btn-sm btn-danger' style={{ height: "5vh" }} onClick={() => handleOpenModalDelete(rule.id)}><i className='bi bi-trash'></i></Button>
                                </div>
                            </div>
                        ))}
                    </ul>
                </div>

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add new rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Add Rule"
                                    required
                                />
                            </div>

                            <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                Save
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add new rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    name="description"
                                    value={formDataEdit.description}
                                    onChange={handleChangeEdit}
                                    placeholder={formDataEdit.description}
                                    required
                                />
                            </div>

                            <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                Save
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <p>Are you sure you want to delete this rule</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='success' onClick={handleSubmitDelete}>
                            Yes
                        </Button>
                        <Button variant='danger' onClick={handleCloseModalDelete}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    )
}

export default SelectedRules