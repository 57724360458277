import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Routes importing
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from "react-router-dom";
import { useEffect, useState } from "react";


// Importing Pages Auth
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";

// Importing main pages
import Home from "./pages/main/Home";
import Statutory from './pages/pages/Statutory';
// The navlink pages
import Landing from "./pages/main/Landing";
import Configure from './pages/main/Configure';
import Utility from './pages/main/Utilities';
import ProfileOld from './pages/main/Profile';
import ReportsOld from './pages/main/Reports';

// other pages
import AddHouse from './pages/pages/AddHouse'
import AddProperty from './pages/pages/AddProperty';
import ApartmentImage from './pages/pages/ApartmentImage';
import HouseImage from "./pages/pages/HouseImage";

// new dashboard
import Dashboard from "./pages/newDashboard/Dashboard";
import Property from './pages/newDashboard/Property';
import SelectedProperty from './pages/newDashboard/SelectedProperty';
import HomeAddProperty from './pages/pages/HomeAddProperty';
import SelectedHouse from './pages/newDashboard/SelectedHouse';
import Utilities from "./pages/newDashboard/Utilities";
import SelectedPropertyUtilities from "./pages/newDashboard/SelectedPropertyUtilities";
import Profile from "./pages/newDashboard/Profile";
import PdfR from './pages/newDashboard/PdfR';
import Account from './pages/newDashboard/Account';

// reports
import RentPaymentReport from './pages/newDashboard/reports/RentPaymentReport';
import UtilitiesReport from './pages/newDashboard/reports/UtilitiesReport';
import OccupancyReport from './pages/newDashboard/reports/OccupancyReport';
import IncomeReport from './pages/newDashboard/reports/IncomeReport';
import OwnersReport from './pages/newDashboard/reports/OwnersReport';
import Reports from './pages/newDashboard/reports/Reports';
import SelectedApartmentReport from './pages/newDashboard/reports/SelectedApartmentReport';
import TenantReports from './pages/newDashboard/reports/TenantReports';
import IssueReport from "./pages/newDashboard/reports/IssueReport";
import HistoryReport from "./pages/newDashboard/reports/HistoryReport";
import Table from "./pages/pages/Table";
import Repairs from './pages/newDashboard/Repairs';
import SelectedApartmentRepairs from './pages/newDashboard/SelectedApartmentRepairs';
import PageAddApartment from "./pages/pages/PageAddApartment";
import AddTenant from "./pages/newDashboard/AddTenant";
import SelectedHouseImage from "./pages/newDashboard/SelectedHouseImage";
import SelectedPropertyImage from "./pages/newDashboard/SelectedPropertyImage";
import ImageResize from "./pages/newDashboard/ImageResize";
import Messages from "./pages/newDashboard/Messages";
import BulkUploads from "./pages/newDashboard/BulkUploads";
import Wallet from "./pages/newDashboard/Wallet";
import WalletLogin from "./pages/newDashboard/WalletLogin";
import MainWallet from "./pages/newDashboard/MainWallet";
import WalletOtp from "./pages/newDashboard/WalletOtp";
import FeaturedProperties from "./pages/newDashboard/FeaturedProperties";
import LeadLandlord from "./pages/newDashboard/LeadLandlord";
import LeadViewer from "./pages/newDashboard/LeadViewer";
import ListingProperties from "./pages/newDashboard/ListingProperties";
import Blogs from "./pages/newDashboard/Blogs";
import SelectedBlog from "./pages/newDashboard/SelectedBlog";
import SelectedBlogImage from "./pages/newDashboard/SelectedBlogImage";
import AddRoomImage from "./pages/pages/AddRoomImage";
import BlogAdd from "./pages/newDashboard/BlogAdd";
import Roomadd from "./pages/newDashboard/Roomadd";
import SelectedRules from "./pages/newDashboard/SelectedRules";
import LeadViewerFavourites from "./pages/newDashboard/LeadViewerFavourites";
import Booking from "./pages/newDashboard/Booking";
import MainStepper from "./pages/newDashboard/MainStepper";
import Feedback from "./pages/newDashboard/Feedbacks";
import AssignRoles from "./pages/newDashboard/AssignRoles";
import AddMembers from "./pages/newDashboard/AddMembers";
import AddContact from "./pages/newDashboard/AddContact";
import ForgotPasword from "./pages/auth/ForgotPassword";
import ApproveProperty from "./pages/newDashboard/ApproveProperty";
import ApproveBlog from "./pages/newDashboard/ApproveBlog";
import PermisionComponent from "./pages/newDashboard/PermisionComponent";


function App() {
  const isAuthenticated = localStorage.getItem('authTokens') !== null;
  const [topics, setTopics] = useState([]);

  // Function to handle topics from PermissionComponent
  const handleTopics = (groupedTopics) => {
    const topics = Object.keys(groupedTopics);
    setTopics(topics);
    console.log(topics)
  };


  return (

    <div className="App">
      
      <ToastContainer />
      <HashRouter>
        <Routes>
          <Route exact path="/">
            {isAuthenticated ? <Route Component={Dashboard} path="/" /> : <Route Component={Home} exact path="/" />}
          </Route>

          <>
            {/* apartment only */}
            {topics.includes("apartment") && (
              <>

              </>
            )}

            <Route path="/dashboard" Component={Dashboard} />
            <Route path="/home" Component={Dashboard} />
            <Route path="/new-property" Component={MainStepper} />
            <Route path="/add-apartment" Component={PageAddApartment} />
            <Route path="/add-property" Component={AddProperty} />
            <Route path="/property" Component={Property} />
            <Route path="/selected-property/:id" Component={SelectedProperty} />
            <Route path="/selected-property-image/:id" Component={SelectedPropertyImage} />
            <Route path="/Apartment-image" Component={ApartmentImage} />
            <Route path="/add-house" Component={AddHouse} />
            <Route path="/add-property" Component={AddProperty} />
            <Route path="/add-house" Component={AddHouse} />
            <Route path="/Apartment-image" Component={ApartmentImage} />
            <Route path="/house-image" Component={HouseImage} />
            <Route path="/room-image/:id" Component={AddRoomImage} />
            <Route path="/add-room/:id" Component={Roomadd} />
            <Route path="/statutory" Component={Statutory} />
            <Route path="/house-image" Component={HouseImage} />
            {/* end of apartment */}

            <Route path="/assign-role" Component={AssignRoles} />


            <Route path="/forgot-password" Component={ForgotPasword} />

            {/* navlink pages */}
            <Route path="/bulk" Component={BulkUploads} />
            <Route path="/configure" Component={Configure} />
            <Route path="/utilities" Component={Utilities} />
            <Route path="/profile" Component={Profile} />
            <Route path="/reports" Component={Reports} />
            <Route path="/messages" Component={Messages} />
            <Route path="/booking" Component={Booking} />
            <Route path="/feedback" Component={Feedback} />
            <Route path="/approve-property" Component={ApproveProperty} />
            <Route path="/approve-blog" Component={ApproveBlog} />

            <Route path="/repairs" Component={Repairs} />
            <Route path="/featured-property" Component={FeaturedProperties} />
            <Route path="/listing-property" Component={ListingProperties} />
            <Route path="/selected-apartment-repairs/:id" Component={SelectedApartmentRepairs} />
            <Route path='/home-property' Component={HomeAddProperty} />
            <Route path='/selected-unit/:id' Component={SelectedHouse} />
            <Route path='/selected-house-image/:id' Component={SelectedHouseImage} />
            <Route path='/selected-property-utility/:id' Component={SelectedPropertyUtilities} />
            <Route path='/selected-property-report/:id' Component={SelectedApartmentReport} />
            <Route path='/blog' Component={Blogs} />
            <Route path='/selected-blog/:id' Component={SelectedBlog} />
            <Route path='/selected-blog-image/:id' Component={SelectedBlogImage} />


            <Route path='/permision' Component={PermisionComponent} />

            <Route path='/tenant-report/:id' Component={TenantReports} />
            <Route path='/issue-report/:id' Component={IssueReport} />
            <Route path='/history-report/:id' Component={HistoryReport} />
            <Route path='/wallet' Component={MainWallet} />
            <Route path='/property-wallet/:id' Component={Wallet} />
            <Route path='/wallet-otp' Component={WalletOtp} />

            {/* reports */}
            <Route path='/rent-report/:id' Component={RentPaymentReport} />
            <Route path='/utilities-report/:id' Component={UtilitiesReport} />
            <Route path='/occupancy-report/:id' Component={OccupancyReport} />
            <Route path='/income-report/:id' Component={IncomeReport} />
            <Route path='/owners-report/:id' Component={OwnersReport} />
            <Route path='/leads-landlord' Component={LeadLandlord} />
            <Route path='/leads-viewer' Component={LeadViewer} />

            <Route path='/wallet-login' Component={WalletLogin} />


            <Route path='/table' Component={Table} />
            <Route path='pdf' Component={PdfR} />
            <Route path='/update-profile' Component={ImageResize} />

            <Route path="add-tenant/:id/:housetenant" Component={AddTenant} />

            <Route path='/account/:id' Component={Account} />
            {/* auth pages */}
            <Route path="/login" Component={Login} />

            <Route path="/statutory" Component={Statutory} />
            <Route path="/add-member" Component={AddMembers} />
            <Route path="/add-contact" Component={AddContact} />

            <Route path="/add-blog" Component={BlogAdd} />
            <Route path="/rules/:id/:house" Component={SelectedRules} />
            <Route path="/viewer-favourites/:id" Component={LeadViewerFavourites} />



            <Route path="*" element={<Navigate to="/" />} />
          </>


        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
