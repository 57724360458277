import React, { useState, useEffect } from 'react';
import './AssignRoles.scss';
import { Card, Table, Modal, Button, Form, FormControl, Dropdown, Image, Tab, Tabs, CardHeader, CardBody, FloatingLabel, FormGroup, FormSelect, Row, Col } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Header from './Header';
import { Link } from "react-router-dom";
import SideBar from './SideBar';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import DefaultImage from '../../assets/img/user_1.png'

function AssignRoles() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState('roles');
  const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const [backgroundImage, setBackgroundImage] = useState('');
  useEffect(() => {
    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }

      let randomNumber = Math.floor(Math.random() * 10) + 1;
      while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 10) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
      setBackgroundImage(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
    };

    getRandomImage();
  }, []);

  // consume the roles endpoints
  const [allPermisions, setAllPermisions] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [groupPermissions, setGroupPermissions] = useState({});
  const [allChoosePermisions, setAllChoosePermissions] = useState([]);

  useEffect(() => {
    // Fetch all permissions and groups
    const fetchData = async () => {
      try {
        const permissionsResponse = await serverUrl.get(`/users/permissions/`, config);
        setAllPermisions(permissionsResponse.data);
        setAllChoosePermissions(permissionsResponse.data)

        const groupsResponse = await serverUrl.get(`/users/list-groups/`, config);
        setAllGroups(groupsResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch group permissions after the initial fetch of groups
    const fetchGroupPermissions = async () => {
      try {
        const groupPermissionsPromises = allGroups.map(async (group) => {
          const groupPermissionsResponse = await serverUrl.get(`/users/list-group-permission/?group_id=${group.id}`, config);
          return {
            groupId: group.id,
            permissions: groupPermissionsResponse.data,
          };
        });

        const groupPermissionsResults = await Promise.all(groupPermissionsPromises);

        // Update group permissions in state
        const updatedGroupPermissions = {};
        groupPermissionsResults.forEach((result) => {
          updatedGroupPermissions[result.groupId] = result.permissions;
        });

        setGroupPermissions(updatedGroupPermissions);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch group permissions only if there are groups
    if (allGroups.length > 0) {
      fetchGroupPermissions();
    }
  }, [allGroups]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null);
  };

  const totalPages = Math.ceil(allGroups.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? allGroups.length : startIndex + itemsPerPage;

  const sortedData = allGroups.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });
  const displayedItems = filteredData.slice(startIndex, endIndex);

  // display the modal for selected group
  const [showModal, setShowModal] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState(null);
  const [currentPageModal, setCurrentPageModal] = useState(0);
  const [searchPermission, setSearchPermission] = useState("");

  const itemsPerPageModal = 20;

  const handleViewGroup = (group) => {
    setSelectedGroupData(group);
    setShowModal(true);
    setCurrentPageModal(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGroupData(null);
  };

  const handlePrevPageModal = () => {
    setCurrentPageModal(currentPageModal - 1);
  };

  const handleNextPageModal = () => {
    setCurrentPageModal(currentPageModal + 1);
  };


  const handleSearchPermission = (event) => {
    setSearchPermission(event.target.value);
    setSelectedRow(null);
    setCurrentPageModal(0);
  };
  const displayedPermissions =
    selectedGroupData && groupPermissions[selectedGroupData.id]
      ? groupPermissions[selectedGroupData.id]
      : [];
  const filteredPermissions =
    searchPermission !== ""
      ? displayedPermissions.filter((permission) =>
        permission.name.toLowerCase().includes(searchPermission.toLowerCase())
      )
      : displayedPermissions;
  const filteredAndExcludedPermissions = filteredPermissions.filter(
    (permission) => !permission.name.toLowerCase().includes('log entry')
  );
  const startIndexModal = currentPageModal * itemsPerPageModal;
  const endIndexModal =
    (currentPageModal + 1) * itemsPerPageModal > filteredAndExcludedPermissions.length
      ? filteredAndExcludedPermissions.length
      : (currentPageModal + 1) * itemsPerPageModal;


  // for editing the permission
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedGroupDataEdit, setSelectedGroupDataEdit] = useState(null);
  const [currentPageModalEdit, setCurrentPageModalEdit] = useState(0);
  const [searchPermissionEdit, setSearchPermissionEdit] = useState("");

  const itemsPerPageModalEdit = 20;

  const handleViewGroupEdit = (group) => {
    setSelectedGroupDataEdit(group);
    setShowEditModal(true);
    setCurrentPageModalEdit(0);
  };

  const handleCloseModalEdit = () => {
    setShowEditModal(false);
    setSelectedGroupDataEdit(null);
  };

  const handlePrevPageModalEdit = () => {
    setCurrentPageModalEdit(currentPageModalEdit - 1);
  };

  const handleNextPageModalEdit = () => {
    setCurrentPageModalEdit(currentPageModalEdit + 1);
  };

  const handleSearchPermissionEdit = (event) => {
    setSearchPermissionEdit(event.target.value);
    setSelectedRow(null);
    setCurrentPageModalEdit(0);
  };

  const displayedPermissionsEdit =
    selectedGroupDataEdit && groupPermissions[selectedGroupDataEdit.id]
      ? groupPermissions[selectedGroupDataEdit.id]
      : [];

  const filteredPermissionsEdit =
    searchPermissionEdit !== ""
      ? displayedPermissionsEdit.filter((permission) =>
        permission.name.toLowerCase().includes(searchPermissionEdit.toLowerCase())
      )
      : displayedPermissionsEdit;



  const filteredAndExcludedPermissionsEdit = filteredPermissionsEdit.filter(
    (permission) => !permission.name.toLowerCase().includes('log entry')
  );


  const startIndexModalEdit = currentPageModalEdit * itemsPerPageModalEdit;
  const endIndexModalEdit =
    startIndexModalEdit + itemsPerPageModalEdit > filteredAndExcludedPermissionsEdit.length
      ? filteredAndExcludedPermissionsEdit.length
      : startIndexModalEdit + itemsPerPageModalEdit;


  // remove the selected permission
  const removeSelectedPermission = (permission, e, group) => {
    const data = {
      group_id: group,
      permission_id: permission
    }

    serverUrl.post(`/users/remove-permissions-from-groups/`, data, config)
      .then((res) => {
        toast.success('Successfully removed permission from group')
        const fetchData = async () => {
          try {
            const groupsResponse = await serverUrl.get(`/users/list-groups/`, config);
            setAllGroups(groupsResponse.data);
          } catch (error) {
            console.error(error);
          }
        };

        fetchData();
      })
      .catch((error) => {
        console.log(error)
      })
  };


  // delete the selcted group
  const [deleteGroup, setDeleteGroup] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteGroup = (group) => {
    setDeleteGroup(group);
    setShowDeleteModal(true)
  };

  const handleCloseModalDelete = () => {
    setShowDeleteModal(false);
    setDeleteGroup(null);
  };

  const handleGroupDelete = () => {
    serverUrl.delete(`/users/edit_group/?group_id=${deleteGroup.id}`, config)
      .then((res) => {
        toast.success("Successfully deleted group");
        setShowDeleteModal(false)
        setAllGroups((prevGroups) => prevGroups.filter(group => group.id !== deleteGroup.id));
      })
      .catch((error) => {
        console.log(error);
      });

  };

  // add a new role
  const [addGroup, setAddGroup] = useState(null)
  const [showAddModal, setShowAddModal] = useState(false);

  const handleAddGroup = () => {
    setShowAddModal(true)
  };

  const [searchPermissionQuery, setSearchPermissionQuery] = useState('');

  const filteredPermissionsSearched = allChoosePermisions
    .filter(permission =>
      permission.name.toLowerCase().includes(searchPermissionQuery.toLowerCase())
    )
    .filter(permission =>
      permission.name.toLowerCase().includes('group') ||
      permission.name.toLowerCase().includes('apartment') ||
      permission.name.toLowerCase().includes('house') ||
      permission.name.toLowerCase().includes('blog')
    )
    .sort((a, b) => a.id - b.id);


  const handleCloseModalAdd = () => {
    setShowAddModal(false);
    setAddGroup(null);
  };

  const [chosenPermissions, setChosenPermissions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);

  const handleCheckboxChange = (permission) => {
    setSelectedPermission((prevSelected) => {
      if (prevSelected.includes(permission.id)) {
        // If permission is already selected, remove it
        return prevSelected.filter((p) => p !== permission.id);
      } else {
        // If permission is not selected, add it
        return [...prevSelected, permission.id];
      }
    });
  };

  const handleAddPermission = () => {
    if (selectedPermission.length > 0) {
      setChosenPermissions((prevChosenPermissions) => [
        ...prevChosenPermissions,
        ...allChoosePermisions.filter((permission) =>
          selectedPermission.includes(permission.id)
        ),
      ]);
      setAllChoosePermissions((prevAllPermissions) =>
        prevAllPermissions.filter(
          (permission) => !selectedPermission.includes(permission.id)
        )
      );
      setSelectedPermission([]);
    }
  };

  const handleRemovePermission = () => {
    if (selectedPermission.length > 0) {
      setAllChoosePermissions((prevAllPermissions) => [
        ...prevAllPermissions,
        ...chosenPermissions.filter((permission) =>
          selectedPermission.includes(permission.id)
        ),
      ]);
      setChosenPermissions((prevChosenPermissions) => {
        const updatedPermissions = prevChosenPermissions.filter(
          (permission) => !selectedPermission.includes(permission.id)
        );
        return updatedPermissions;
      });

      setSelectedPermission([]);
    }
  };
  const handleAddAllPermissions = () => {
    setChosenPermissions((prevChosenPermissions) => [...prevChosenPermissions, ...filteredPermissionsSearched]);
    setAllChoosePermissions([]);
  };

  const handleRemoveAllPermissions = () => {
    setAllChoosePermissions((prevAllPermissions) => [...prevAllPermissions, ...chosenPermissions]);
    setChosenPermissions([]);
  };

  const [formData, setFormData] = useState({
    name: '',
    permissions: []
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleGroupAdd = async () => {
    try {
      const data = {
        group_name: formData.name,
      };

      // First, create the group
      const groupResponse = await serverUrl.post(`/users/create-group/`, data, config);
      const groupId = groupResponse.data.id;
      await Promise.all(
        chosenPermissions.map(async (permissions) => {
          // Data to be sent
          const dataPermission = {
            group_id: groupId,
            permission_id: permissions.id,
          };
          const permissionsResponse = await serverUrl.post(`/users/add-permissions-to-groups/`, dataPermission, config);
          console.log(permissionsResponse.data);
        })
      );

      toast.success('Group creation and permissions addition completed successfully.');
      setShowAddModal(false)
      setChosenPermissions([])
      setFormData([])
      const groupsResponse = await serverUrl.get(`/users/list-groups/`, config);
      setAllGroups(groupsResponse.data);

    } catch (error) {
      console.error('Error in handleGroupAdd:', error);
    }
  };

  // Assigned Roles functionalty
  const [userMembers, setUserMembers] = useState([])
  const [userRoles, setUserRoles] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // get the users
        const userResponse = await serverUrl.get(`landlord/alliance-members`, config);
        setUserMembers(userResponse.data.results.filter(member => member.is_visible === true));
        // get the roles for the members
        const userRoles = await Promise.all(
          userResponse.data.results.map(async (member) => {
            try {
              const response = await serverUrl.get(`users/list-user-groups/?user_id=${member.user.id}`,
                config
              );
              console.log(response.data)
              return { id: member.user.id, data: response.data };
            } catch (error) {
              console.error(`Error fetching data for landlord ${member.landlord}:`, error);
              return { id: member.user.id, data: [] };
            }
          })
        );

        // Update propertiesManaged state with the obtained data
        const newUserRoles = userRoles.reduce((acc, { id, data }) => {
          acc[id] = data;
          return acc;
        }, {});

        setUserRoles((prevProperties) => ({
          ...prevProperties,
          ...newUserRoles,
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);


  const [currentPageUser, setCurrentPageUser] = useState(0);
  const itemsPerPageUser = 10;
  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [selectedRowUser, setSelectedRowUser] = useState(null);
  const [sortTypeUser, setSortTypeUser] = useState("name");
  const [sortOrderUser, setSortOrderUser] = useState("asc");

  const handlePrevPageUser = () => {
    setCurrentPageUser(currentPageUser - 1);
  };

  const handleNextPageUser = () => {
    setCurrentPageUser(currentPageUser + 1);
  };

  const handleSearchUser = (event) => {
    setSearchQueryUser(event.target.value);
    setSelectedRowUser(null);
  };


  const totalPagesUsers = Math.ceil(userMembers.length / itemsPerPageUser);
  const startIndexUser = currentPageUser * itemsPerPageUser;
  const endIndexUser = currentPageUser === totalPagesUsers - 1 ? userMembers.length : startIndexUser + itemsPerPageUser;
  const sortedDataUser = userMembers.sort((a, b) => {
    const sortValueAUser = typeof a[sortTypeUser] === "string" ? a[sortTypeUser].toLowerCase() : a[sortTypeUser];
    const sortValueBUser = typeof b[sortTypeUser] === "string" ? b[sortTypeUser].toLowerCase() : b[sortTypeUser];
    if (sortOrderUser === "asc") {
      return sortValueAUser > sortValueBUser ? 1 : -1;
    } else {
      return sortValueAUser < sortValueBUser ? 1 : -1;
    }
  });

  const filteredDataUser = sortedDataUser.filter((item) => {
    const searchableFieldsUser = Object.values(item).join(" ").toLowerCase();
    return searchableFieldsUser.includes(searchQueryUser.toLowerCase());
  });
  const displayedItemsUser = filteredDataUser.slice(startIndexUser, endIndexUser);

  const [userGroupName, setUserGroupName] = useState([])
  // get the roles of each member
  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        const userGroups = userMembers.map(async (user) => {
          const res = await serverUrl.get(`/users/list-user-groups/?user_id=${user.user.id}`, config);
          return {
            userId: user.user.id,
            groupName: res.data,
          };
        })

        const userGroupsResults = await Promise.all(userGroups);
        setUserGroupName(userGroupsResults)
      }
      catch (error) {
        console.error(error);
      }
    }
    // Fetch group permissions only if there are groups
    if (userMembers.length > 0) {
      fetchUserGroup();
    }
  }, [userMembers])

  // give user role
  const [assingRoleModal, setAssingRoleModal] = useState(false)
  const [memberRole, setMemberRole] = useState({})
  const [memberSelectedRole, setMemberSelectedRole] = useState([])

  const handleOpenAssignRole = (user) => {
    setAssingRoleModal(true)
    setMemberRole(user)

    serverUrl.get(`/users/list-user-groups/?user_id=${user.id}`, config)
      .then((res) => {
        setMemberSelectedRole(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  };
  const handleCloseAssignRole = () => {
    setAssingRoleModal(false)
  };

  // select all groups
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleSelectAll = (event) => {
    const allGroupObjects = allGroups.map((group) => group);
    if (event.target.checked) {
      setSelectedGroups(allGroupObjects);
    } else {
      setSelectedGroups([]);
    }
  };

  const handleGroupSelection = (event, groupId) => {
    setSelectedGroups((prevSelected) => {
      const selectedGroupIndex = prevSelected.findIndex((group) => group.id === groupId);

      if (selectedGroupIndex !== -1) {
        const updatedSelected = [...prevSelected];
        updatedSelected.splice(selectedGroupIndex, 1);
        return updatedSelected;
      } else {
        const selectedGroup = allGroups.find((group) => group.id === groupId);
        return [...prevSelected, selectedGroup];
      }
    });
  };


  const transparentBackground = {
    backgroundColor: 'transparent',
    color: 'black',
    width: "30vw",
    display: "flex"
  }
  const removeRole = (roleToRemove) => {
    // remove the role 
    const data = {
      group_id: roleToRemove,
      user_id: memberRole.id
    }
    serverUrl.post(`/users/remove-user-from-groups/`, data, config)
      .then((res) => {
        serverUrl.get(`/users/list-user-groups/?user_id=${memberRole.id}`, config)
          .then((res) => {
            setMemberSelectedRole(res.data)
            // get the data 
            const fetchData = async () => {
              try {
                // get the users
                const userResponse = await serverUrl.get(`landlord/alliance-members`, config);
                setUserMembers(userResponse.data.results.filter(member => member.is_visibile === true));

                // get the roles for the members
                const userRoles = await Promise.all(
                  userResponse.data.results.map(async (member) => {
                    try {
                      const response = await serverUrl.get(`users/list-user-groups/?user_id=${member.user.id}`,
                        config
                      );
                      console.log(response.data)
                      return { id: member.user.id, data: response.data };
                    } catch (error) {
                      console.error(`Error fetching data for landlord ${member.landlord}:`, error);
                      return { id: member.user.id, data: [] };
                    }
                  })
                );

                const newUserRoles = userRoles.reduce((acc, { id, data }) => {
                  acc[id] = data;
                  return acc;
                }, {});

                setUserRoles((prevProperties) => ({
                  ...prevProperties,
                  ...newUserRoles,
                }));
              } catch (error) {
                console.error('Error fetching user data:', error);
              }
            };

            fetchData();
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  };




  // add the role 
  const handleAssignRole = () => {
    selectedGroups.forEach((group) => {
      const data = {
        group_id: group.id,
        user_id: memberRole.id
      };

      serverUrl.post(`/users/add-user-to-groups/`, data, config)
        .then((res) => {
          toast.success('You have succesfully assigned the roles')
          setAssingRoleModal(false)
          setSelectedGroups([])
          serverUrl.get(`/users/list-user-groups/?user_id=${memberRole.id}`, config)
            .then((res) => {
              setMemberSelectedRole(res.data)

              // get the data 
              const fetchData = async () => {
                try {
                  // get the users
                  const userResponse = await serverUrl.get(`landlord/alliance-members`, config);
                  setUserMembers(userResponse.data.results.filter(member => member.is_visibile === true));

                  // get the roles for the members
                  const userRoles = await Promise.all(
                    userResponse.data.results.map(async (member) => {
                      try {
                        const response = await serverUrl.get(`users/list-user-groups/?user_id=${member.user.id}`,
                          config
                        );
                        console.log(response.data)
                        return { id: member.user.id, data: response.data };
                      } catch (error) {
                        console.error(`Error fetching data for landlord ${member.landlord}:`, error);
                        return { id: member.user.id, data: [] };
                      }
                    })
                  );

                  const newUserRoles = userRoles.reduce((acc, { id, data }) => {
                    acc[id] = data;
                    return acc;
                  }, {});

                  setUserRoles((prevProperties) => ({
                    ...prevProperties,
                    ...newUserRoles,
                  }));
                } catch (error) {
                  console.error('Error fetching user data:', error);
                }
              };

              fetchData();
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };




  return (
    <div className='assignRole'>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}

      <div className='property'>
        <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
          <p className='properties'>Roles Management</p>

        </div>

        <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="nav-pills d-flex">
          <Tab eventKey="roles" title="Roles" >
            <div className='tasks w-100' >
              <h5 className='float-start'>My List of Roles</h5>
              <div className='d-flex justify-content-end'>

                <Form className="searchBar">
                  <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                </Form>
              </div>
              <div className='d-flex justify-content-end mt-2'>
                <Button className='btn btn-warning text-white' onClick={handleAddGroup}>Add Role</Button>
              </div>
              <Table className='table'>
                <thead className='underline'>
                  <th>Image</th>
                  <th>Group Name</th>
                  <th>Permisions</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {displayedItems.map((group, index) =>
                    <tr>
                      <td>
                        <Image src={DefaultImage} className="" style={{ width: "50px", height: "50px" }}></Image>

                      </td>
                      <td>
                        {group.name}
                      </td>
                      <td>
                        {groupPermissions[group.id] && (
                          <>
                            {groupPermissions[group.id].slice(-2).map((permission, permissionIndex) => (
                              <span key={permissionIndex}>{permission.name} </span>
                            ))}
                            {groupPermissions[group.id].length > 2 && (
                              <span>...</span>
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        <Button className='btn btn-sm btn-success me-2' onClick={() => handleViewGroup(group)}>
                          <i className='bi bi-eye'></i>
                        </Button>
                        <Button className='btn btn-sm btn-info text-white me-2' onClick={() => handleViewGroupEdit(group)}><i className='bi bi-pen'></i></Button>
                        <Button className='btn btn-sm btn-danger text-white' onClick={() => handleDeleteGroup(group)}><i className='bi bi-trash'></i></Button>
                      </td>

                    </tr>
                  )}

                </tbody>
              </Table>

              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 0}>
                  &lt;
                </button>
                <span>{`${startIndex + 1}-${endIndex} of ${allGroups.length}`}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                  &gt;
                </button>
              </div>

            </div>


            {/* modal for displaying the data for grpup  */}
            <Modal show={showModal} onHide={handleCloseModal} size='lg'>
              <Modal.Header closeButton>
                <Modal.Title>{selectedGroupData && selectedGroupData.name} Permisions</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {/* Search input for permissions */}
                <div className="searchBarModal">
                  <FormControl
                    type="text"
                    placeholder="Search Permissions"
                    value={searchPermission}
                    onChange={(e) => handleSearchPermission(e)}

                  />
                </div>

                {/* Display additional group data here */}
                {selectedGroupData && (
                  <div>
                    <ul>
                      {filteredAndExcludedPermissions
                        .slice(startIndexModal, endIndexModal)
                        .map((permission, permissionIndex) => (
                          <li key={permissionIndex}>{permission.name}</li>
                        ))}
                    </ul>

                    <div className="pagination">
                      <button onClick={handlePrevPageModal} disabled={currentPageModal === 0}>
                        &lt;
                      </button>
                      <span>
                        {`${startIndexModal + 1}-${Math.min(
                          endIndexModal,
                          startIndexModal + itemsPerPageModal
                        )} of ${filteredAndExcludedPermissions.length}`}
                      </span>
                      <button
                        onClick={handleNextPageModal}
                        disabled={endIndexModal >= filteredPermissions.length}
                      >
                        &gt;
                      </button>
                    </div>
                  </div>
                )}


              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* modal for editing data for group */}
            <Modal show={showEditModal} onHide={handleCloseModalEdit} size='lg'>
              <Modal.Header closeButton>
                <Modal.Title>Edit {selectedGroupDataEdit && selectedGroupDataEdit.name} Permisions</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {/* Search input for permissions */}
                <div className="searchBarModal">
                  <FormControl
                    type="text"
                    placeholder="Search Permissions"
                    value={searchPermissionEdit}
                    onChange={(e) => handleSearchPermissionEdit(e)}

                  />
                </div>

                {/* Display additional group data here */}
                {selectedGroupDataEdit && (
                  <div>
                    {filteredAndExcludedPermissionsEdit
                      .slice(startIndexModalEdit, endIndexModalEdit)
                      .map((permission, permissionIndex) => (
                        <div className='d-flex justify-content-between mb-2' key={permissionIndex}>
                          <li>{permission.name}</li>
                          <Button className='btn btn-sm btn-danger text-white' onClick={(e) => removeSelectedPermission(permission.id, e, selectedGroupDataEdit.id)}>
                            <i className='bi bi-trash'></i>
                          </Button>
                        </div>
                      ))}

                    <div className="pagination">
                      <button onClick={handlePrevPageModalEdit} disabled={currentPageModalEdit === 0}>
                        &lt;
                      </button>
                      <span>
                        {`${startIndexModalEdit + 1}-${Math.min(
                          endIndexModalEdit,
                          startIndexModalEdit + itemsPerPageModalEdit
                        )} of ${filteredAndExcludedPermissionsEdit.length}`}
                      </span>
                      <button
                        onClick={handleNextPageModalEdit}
                        disabled={endIndexModalEdit >= filteredAndExcludedPermissionsEdit.length}
                      >
                        &gt;
                      </button>
                    </div>
                  </div>
                )}


              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            {/* modal to delete the group */}
            <Modal show={showDeleteModal} onHide={handleCloseModalDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Delete {deleteGroup && deleteGroup.name} </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p>Are you sure you want to delete this group?</p>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalDelete}>
                  No
                </Button>
                <Button variant="danger" onClick={handleGroupDelete}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* modal to add group */}
            <Modal show={showAddModal} onHide={handleCloseModalAdd} size='xl'>
              <Modal.Header closeButton>
                <Modal.Title>Add new group </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form>
                  <div className='d-flex justify-content-end'>
                    <Button variant="primary" onClick={handleGroupAdd}>
                      Save Group
                    </Button>
                  </div>
                  <Form.Group className="mb-3" controlId="propertyName">
                    <FloatingLabel controlId="propertyName" label="Group Name">
                      <Form.Control
                        type='text'
                        className='mb-2 w-75'
                        name='name'
                        placeholder='Enter the name of the group'
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <h7 className='mt-2 fw-bold'>Permissions</h7>
                  <p>Select a permission from the left dialog and use the right arrow to move to the right dialog and click on save to assign the chosen permissions to the role</p>

                  <div className='d-flex justify-content-between '>
                    <Card className='cardDisplayPermission'>
                      <CardHeader className='bg-warning text-white'>
                        Permisions
                      </CardHeader>
                      {/* Search input */}
                      <Form className="searchBarPermissions" style={{ width: "100%", borderBottom: "1px solid lightgray" }}>

                        <FormControl
                          type="text"
                          placeholder="Search Permissions"
                          value={searchPermissionQuery}
                          onChange={(e) => setSearchPermissionQuery(e.target.value)}
                        />
                      </Form>

                      <CardBody>
                        {filteredPermissionsSearched.map((permission) => (
                          <div className='d-flex' key={permission.id}>
                            <input
                            className='me-2'
                              type="checkbox"
                              checked={selectedPermission.includes(permission.id)}
                              onChange={() => handleCheckboxChange(permission)}
                            />
                            {permission.name}
                          </div>
                        ))}

                      </CardBody>

                    </Card>
                    <div className="buttonChoose">
                      <Button className='buttonChoose btn btn-success' onClick={handleAddPermission}>
                        <i className="bi bi-arrow-right-circle"></i><br></br>
                      </Button>

                      <Button className='buttonChoose btn btn-success' onClick={handleRemovePermission}>
                        <i className="bi bi-arrow-left-circle"></i><br></br>
                      </Button>
                    </div>

                    <Card className='cardDisplayPermission'>
                      <CardHeader className='bg-warning text-white'>
                        Chosen Permisions
                      </CardHeader>
                      <CardBody>
                        {chosenPermissions.map((permission) => (
                          <div className='d-flex' key={permission.id}>
                            <input
                              type="checkbox"
                              checked={selectedPermission.includes(permission.id)}
                              onChange={() => handleCheckboxChange(permission)}
                            />
                            {permission.name}
                          </div>
                        ))}
                      </CardBody>
                    </Card>

                  </div>
                  {/* Add All and Remove All buttons */}
                  <div className='d-flex justify-content-between' style={{ padding: "10px" }}>
                    <Button className='btn btn-secondary btn-sm' onClick={handleAddAllPermissions}>
                      <i className="bi bi-arrow-right-circle me-2"></i>                      Add All
                    </Button>
                    <Button className='btn btn-secondary btn-sm' onClick={handleRemoveAllPermissions}>
                      <i className="bi bi-arrow-left-circle me-2"></i>
                      Remove All
                    </Button>
                  </div>

                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModalAdd}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Tab>


          <Tab eventKey="assignedRoles" title="Assigned Roles">
            {/* Replace this with your 'Assigned Roles' content */}
            <div className='tasks w-100' >
              <h5 className='float-start'>List of assigned roles</h5>
              <div className='d-flex justify-content-end'>
                <Form className="searchBar">
                  <FormControl type="text" placeholder="Search" onChange={handleSearchUser} />
                </Form>
              </div>

              <Table className='table'>
                <thead className='underline'>
                  <th>Image</th>
                  <th>User</th>
                  <th>Role</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {displayedItemsUser.map((user) => (
                    <tr key={user.user.id}>
                      <td><Image src={user.user.image} style={{ width: "50px", height: "50px" }} /></td>
                      <td>{user.user.full_name}</td>

                      <td style={{ color: userRoles[user.user.id] && userRoles[user.user.id].length === 0 ? 'red' : 'black' }}>
                        {Array.isArray(userRoles[user.user.id])
                          ? userRoles[user.user.id].length > 0
                            ? userRoles[user.user.id].map((role) => role.name).join(', ')
                            : 'No role added'
                          : userRoles[user.user.id]}
                      </td>


                      <td>
                        <Button className='btn btn-sm btn-warning text-white' onClick={() => handleOpenAssignRole(user.user)}>Assign Role <i className='bi bi-plus'></i></Button>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>

              {/* modal for assigning roles */}
              <Modal show={assingRoleModal} onHide={handleCloseAssignRole} size='xl'>
                <Modal.Header closeButton>
                  <Modal.Title>Assign User Role</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <h2>{memberRole.full_name}</h2>
                  <div className='d-flex mb-5'>
                    {memberSelectedRole.map((memberRoleSelected) => (
                      <span key={memberRoleSelected.id} className='btn me-3 btn-sm btn-success opacity-75 text-white'>
                        {memberRoleSelected.name}
                        <span className="ms-2" onClick={() => removeRole(memberRoleSelected.id)}>x</span>
                      </span>
                    ))}

                  </div>
                  <Row>
                    <Col md={6}>
                      <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <FloatingLabel>
                          Roles :
                        </FloatingLabel>

                        <Dropdown style={{ width: '30vw' }}>
                          <Dropdown.Toggle variant="success" id="dropdown-basic" style={transparentBackground}>
                            {/* {memberSelectedRole.length === 0 ? (
                              <span>Available Roles</span>
                            ) : (
                              <>
                                {memberSelectedRole.map((role) => (
                                  <button className='btn btn-warning text-white btn-sm' key={role.name} style={{ marginRight: '5px' }}>
                                    {role.name}
                                    <span
                                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                                      onClick={() => removeRole(role.id)}
                                    >
                                      &#x2716;
                                    </span>
                                  </button>
                                ))}
                                {selectedGroups.map((group) => (
                                  <button className='btn btn-warning text-white btn-sm' key={group.id} style={{ marginRight: '5px' }}>
                                    {group.name}
                                    <span
                                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                                      onClick={() => removeGroup(group.id)}
                                    >
                                      &#x2716;
                                    </span>
                                  </button>
                                ))
                                }
                              </>
                            )} */}

                            <span>Available Roles</span>


                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ width: '30vw', height: '30vh', overflow: "auto" }}>
                            <div>
                              <label className='border-0'>
                                <input type="checkbox" onChange={handleSelectAll} />
                                <span className='ms-2'>Select All</span>
                              </label>
                              <div>
                                {allGroups.map((group) => (
                                  <div key={group.id} style={{ marginBottom: '5px' }}>
                                    <label className='border-0'>
                                      <input
                                        type="checkbox"
                                        value={group.id}
                                        onChange={(event) => handleGroupSelection(event, group.id)}
                                        checked={selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)}
                                      />
                                      <span className='ms-2'> {group.name}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Dropdown.Menu>


                        </Dropdown>


                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <Card style={{ height: "50vh", overflow: "auto" }}>
                        <CardHeader>
                          Chosen Roles
                        </CardHeader>
                        <CardBody>
                          <ul>
                            {selectedGroups.map((selected) => (
                              <li>{selected.name}</li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>

                </Modal.Body>

                <Modal.Footer>

                  <Button variant="warning" className='text-white' onClick={handleAssignRole}>
                    Assign
                  </Button>
                  <Button variant="secondary" onClick={handleCloseAssignRole}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <div className="pagination">
                <button onClick={handlePrevPageUser} disabled={currentPageUser === 0}>
                  &lt;
                </button>
                <span>{`${startIndexUser + 1}-${endIndexUser} of ${userMembers.length}`}</span>
                <button onClick={handleNextPageUser} disabled={currentPageUser === totalPagesUsers - 1}>
                  &gt;
                </button>
              </div>

            </div>

          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default AssignRoles;
