import React, { useState, useEffect } from 'react'
import Header from './Header'
import './Profile.scss'
import SideBar from './SideBar'
import { Card, Button, Image, Table, Row, Col, Modal, Form, FormControl } from 'react-bootstrap'
import ImageResize from './ImageResize';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import loaderImage from '../../assets/img/logo.png'
import TermsConditions from './TermsConditions'
import Privacy from "./Privacy";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import FileInput from './FileInput'

function Profile() {
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // loading status
  const [isLoading, setIsLoading] = useState(true)
  // modal settings for the editing of the user's details
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModalReload = () => {
    setShowModal(false);
    window.location.reload()
  };

  // modal to display the terms sand conditions
  const [showModalTerms, setShowModalTerms] = useState(false);

  const handleOpenModalTerms = () => {
    setShowModalTerms(true);
  };

  const handleCloseModalTerms = () => {
    setShowModalTerms(false);
  };

  // modal to display the terms sand conditions
  const [showModalPassword, setShowModalPassword] = useState(false);

  const handleOpenModalPassword = () => {
    setShowModalPassword(true);
  };

  const handleCloseModalPassword = () => {
    setShowModalPassword(false);
  };
  // for logout purposes
  const navigate = useNavigate();

  // get the user details the username
  const [fullName, setFullName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [imageUser, setImageUser] = useState("")


  // apartment items
  const [apartmentCount, setApartmentCount] = useState("")
  const [landlordApartment, setLandlordApartment] = useState([])
  const [apartmentId, setApartmentId] = useState("")
  const [houseCount, setHouseCount] = useState({});
  const [occupiedCount, setOccupiedCount] = useState({});
  const [incomeCount, setIncomeCount] = useState({});

  const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

  const [displayUploaded, setDisplayUploaded] = useState(false);
  const [uploadedImagePreview, setUploadedImagePreview] = useState('');

  // set the landlord type
  const [landlordId, setLandlordId] = useState("")
  const [landlordType, setLandlordType] = useState([])
  const [landlordTypeMain, setLandlordTypeMain] = useState([])
  const [accountNumbers, setAccountNumber] = useState([])

  // Crop state
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 }); // Initial crop settings
  const [croppedImageUrl, setCroppedImageUrl] = useState(null); // To store the cropped image URL
  const accessToken = localStorage.getItem("authTokens");

  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  const currentYear = new Date().getFullYear();

   // get the current month so that i can filter out the total income
   const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];
  
  const [rentPercentageApartment, setRentPercentageApartment] = useState('')

  // from the localstorage get the logged in user
  useEffect(() => {


    // if not logged in redirect to login automatically
    if (!accessToken) {
      navigate("/login");
      return;
    }


    // hitting the endpoint of getting the user's details
    // set the loading status
    setIsLoading(true);
    serverUrl.get(`/users/users-details/${userId}/`, config)
      .then((res) => {
        // get the fullname of the user

        setFullName(res.data.full_name);
        setPhoneNumber(res.data.phone_number);
        setEmail(res.data.email);
        setImageUser(res.data.image);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    // get the landlord details of the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        {
          res.data.results.map((LandlordId, index) =>
            // get the apartments owned by the landlord
            serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${LandlordId.id}`, config)
              .then((res) => {

                setApartmentCount(res.data.count)
                setLandlordApartment(res.data.results)

                // get the occupancy percentage of the apartments in current month and in the current year
                const apartmentPromises = res.data.results.map((apartment) => {
                  return serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${apartment.id}&month=${currentMonth}&year=${currentYear}`, config)
                    .then((res) => ({ apartmentId: apartment.id, dataSummary: res.data }))
                    .catch((error) => {
                      console.log(error);
                      return { apartmentId: apartment.id, dataSummary: res.data };
                    });

                })

                // set count of the number of houses that are in that apartment
                Promise.all(apartmentPromises)
                  .then((results) => {

                    // object that gets the number of apartments
                    const apartmentRentPaid = {};
                    const apartmentOccupancyPercentage = {};
                    const apartmentRentPaidExpected = {};

                    results.forEach(({ apartmentId, dataSummary }) => {
                      apartmentRentPaid[apartmentId] = dataSummary.total_income;
                      apartmentOccupancyPercentage[apartmentId] = dataSummary.occupancy_percentage;
                      apartmentRentPaidExpected[apartmentId] = dataSummary.total_rent;


                    });
                    // setRentPaidApartment(apartmentRentPaid);
                    // setRentPercentageApartment(apartmentOccupancyPercentage)
                    setIncomeCount(apartmentRentPaidExpected)
                    setRentPercentageApartment(apartmentOccupancyPercentage)
                    // setIsLoading(false)

                  })
                  .catch((error) => {
                    console.log(error);
                    setIsLoading(false)

                  });

                {
                  const landlordRoleApartment = {}
                  // get the landlord relationship with the apartment
                  res.data.results.map((apartment, index) =>
                    serverUrl.get(`/landlord/landlord-apartment/?apartment_id=${apartment.id}&landlord_id=${LandlordId.id}`, config)
                      .then((res) => {
                        // setLandlordType(res.data)
                        landlordRoleApartment[apartment.id] = res.data.results[0].landlord_type

                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  )
                  setLandlordType(landlordRoleApartment)
                  const accountCount = {}

                  // get the landlord roles and set to give priority
                  res.data.results.map((apartment, index) =>
                    serverUrl.get(`/landlord/landlord-apartment/?landlord_id=${LandlordId.id}`, config)
                      .then((res) => {
                        // console.log(res.data)
                        // setLandlordType(res.data)
                        const landlordMain = res.data.results.find((type) => {
                          if (type.landlord_type === "landlord") {
                            return "landlord";
                          } else if (type.landlord_type === "agent") {
                            return "agent";
                          } else {
                            return "caretaker";
                          }
                        });

                        setLandlordTypeMain(landlordMain)
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  )
                  // get the total number of accounts in the apartments
                  res.data.results.map((apartment, index) =>
                    serverUrl.get(`/landlord/landlord-name-in-apartment/?apartment_id=${apartment.id}`, config)
                      .then((res) => {
                        accountCount[apartment.id] = res.data.count

                      }, [])
                  )

                  setAccountNumber(accountCount)
                  setIsLoading(false);
                }

                // get the total numbers of houses in each apartment
                const houseCountPromises = res.data.results.map((apartment) => {
                  return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                    .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                    .catch((error) => {
                      console.log(error);
                      return { apartmentId: apartment.id, count: 0 };
                    });
                  setIsLoading(false);
                });
                // set count of the number of houses that are in that apartment
                Promise.all(houseCountPromises)
                  .then((results) => {

                    // object that gets the number of apartments
                    const houseCountObj = {};
                    results.forEach(({ apartmentId, count }) => {
                      houseCountObj[apartmentId] = count;
                    });
                    setHouseCount(houseCountObj);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // get the occupancy percentage of the apartments
                const houseOccupancyPromises = res.data.results.map((apartment) => {
                  return serverUrl.get(`/landlord/display-occupied-houses-in-expenses/?apartment_id=${apartment.id}`, config)
                    .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                    .catch((error) => {
                      console.log(error);
                      return { apartmentId: apartment.id, count: 0 };
                    });
                });
                // set count of the number of houses that are occupied in that apartment
                Promise.all(houseOccupancyPromises)
                  .then((results) => {

                    // object that gets the number of apartments
                    const houseOccupiedObj = {};
                    results.forEach(({ apartmentId, count }) => {
                      houseOccupiedObj[apartmentId] = count;
                    });
                    setOccupiedCount(houseOccupiedObj);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                // get the income monthly
                const houseIncomePromises = res.data.results.map((apartment) => {
                  return serverUrl.get(`/tenant/expected-rental-income-by-month/?apartment_id=${apartment.id}`, config)
                    .then((res) => ({ apartmentId: apartment.id, count: res.data.total_rental_income }))
                    .catch((error) => {
                      console.log(error);
                      return { apartmentId: apartment.id, count: 0 };
                    });
                });
                // set count of the number of houses that are occupied in that apartment
                Promise.all(houseIncomePromises)
                  .then((results) => {
                    // object that gets the number of apartments
                    const houseIncomeObj = {};
                    results.forEach(({ apartmentId, count }) => {
                      houseIncomeObj[apartmentId] = count;
                    });
                    // setIncomeCount(houseIncomeObj);
                    setIsLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              })

          )

        }

      }, [])
      .catch((error) => {
        console.log(error);
      });


  }, []);

  // randomise for each apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
  useEffect(() => {
    const getRandomImage = () => {

      const usedImages = Object.values(backgroundImageApartment);
      const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
      const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
      const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      setIsLoading(true);

      const updatedImages = { ...backgroundImageApartment };

      // Loop through each house and update the image if not set
      for (const unitDetail of landlordApartment) {
        const apartmentId = unitDetail.id;
        if (!updatedImages[apartmentId]) {
          updatedImages[apartmentId] = getRandomImage();
        }
      }
      setBackgroundImageApartment(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [landlordApartment]);

  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    full_name: "",
    fullnameError: "",
    phone_number: "",
    email: "",
  });
  const digitCount = formData.phone_number.replace(/[^0-9]/g, "").length; // Count only digits
  // onchange functionality
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // limit phone number input to 10 digits
    if (name === "phone_number") {

      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // check if the user has entered the 5 characters
    if (formData.full_name.length < 5)
      return setFormData((prevFormData) => ({
        ...prevFormData,
        full_nameError: "Full Name must be at least 5 characters",
      }));
    else
      setFormData((prevFormData) => ({
        ...prevFormData,
        full_nameError: "",
      }));
  }


  // when submitting the form it's functionallities
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      full_name: formData.full_name,
      phone_number: formData.phone_number,
      email: formData.email
    };

    //  now post the data
    try {
      serverUrl

        //send the Apartment details using the following endpoint
        .put(`/users/users-details/${userId}/`, data, config)
        .then((res) => {
          toast.success('Successfully Changed the details of the user!');
        })

        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            alert('Error while changing the details of the user ' + error.message);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
    }

  }

  // button that does the uploading to while the input is hidden
  const handleButtonClick = () => {
    // document.getElementById('imageUpload').click();
    navigate('/update-profile')
  };

  const [pickedImage, setPickedImage] = useState("")



  // onchange functionality and submit automatically when user finishes selecting the image
  const handleChangeImage = (event) => {
    // handling the selected image
    const selectedImage = event.target.files[0];
    // set the selected image in a picked image state to prevent the whole browser from reloading so as to display the selected image
    setPickedImage(selectedImage)
    setDisplayUploaded(true); // Set the state to display the uploaded image and hide other components
    setUploadedImagePreview(URL.createObjectURL(selectedImage)); // Set the preview of the uploaded image

    const imageData = new FormData();
    imageData.append('image', selectedImage);
    // imageData.append('full_name', fullName);
    // imageData.append('phone_number', phoneNumber);
    // now send the image to the backend
    try {
      serverUrl

        //send the  image using the following endpoint
        .patch(`/users/users-details/${userId}/`, imageData, config)
        .then((res) => {
          console.log(res)
          toast.success('Successfully Updated User Image!')
        })
        .catch((error) => {
          console.log(error);
          toast.error('Image not updated!')
        });

    } catch (error) {
      toast.error(error);
    }


  }

  // Function to handle the selected image from the FileInput component
  const [currentPage, setCurrentPage] = useState("choose-img");

  const [selectedImageProp, setSelectedImageProp] = useState(uploadedImagePreview);

  const handleImageSelected = (uploadedImagePreview) => {
    setSelectedImageProp(uploadedImagePreview);
    setCurrentPage("crop-img");
  };
  // modal to display the privacy
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);

  const handleOpenModalPrivacy = () => {
    setShowModalPrivacy(true);
  };

  const handleCloseModalPrivacy = () => {
    setShowModalPrivacy(false);
  };

  // properties used in the change of password
  const [emailPassword, setEmailPassword] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = () => {
    const emailRegex = /@gmail\.com$|@yahoo\.com$|@outlook\.com$/i;

    if (emailRegex.test(emailPassword)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    validateEmail();

    // setEmailPassword('')
    // post the email and get the message
    const emailSend = {
      email: emailPassword,
    }
    serverUrl.post(`/users/request-reset-email/`, emailSend, config)
      .then((res) => {
        toast.success("We have sent you a link to reset your password")
        setEmailPassword('')
        setShowModalPassword(false)
      })

      .catch((err) => {
        console.error(err)
        toast.error('Sorry the email address was not found!')
      })
  };

  
  
  const [currentPageData, setCurrentPageData] = useState(0);
  const itemsPerPage = 5;

  const handlePrevPage = () => {
    setCurrentPageData(currentPageData - 1);
  };

  const handleNextPage = () => {
    setCurrentPageData(currentPageData + 1);
  };

  const totalPages = Math.ceil(landlordApartment.length / itemsPerPage);
  const startIndex = currentPageData * itemsPerPage;
  const endIndex = currentPageData === totalPages - 1 ? landlordApartment.length : startIndex + itemsPerPage;
  const displayedItems = landlordApartment.slice(startIndex, endIndex);
 

  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} alt="Loading..." />
      </div>
    );
  }
  
  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className={`profile_dashboard ${displayUploaded ? 'display-none' : ''}`}>
        <h2>My Profile</h2>
        <Row>

          <Col lg={6} md={6} sm={12}>

            <Card className='landlord_profile'>
              <Card.Body>
                <Button className='float-end btn btn-sm' onClick={handleOpenModal}>Edit<i className='bi bi-pen ms-2 '></i></Button>

                {pickedImage !== "" ? (
                  <Image src={URL.createObjectURL(pickedImage)} onClick={handleButtonClick} alt="profilePicture" ></Image>

                ) : (

                  <Image src={imageUser} onClick={handleButtonClick} alt="profilePicture"></Image>

                )

                }
                <span className='editImage' onClick={handleButtonClick}><i className="bi bi-camera fs-5 text-primary fw-bold"></i></span>
                {/* form that submits the image */}

                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleChangeImage}
                />

                {/* edit modal edit details of the user */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit user details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <Form onSubmit={handleSubmit} >
                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className="input-field"
                          type="text"
                          placeholder={fullName}
                          name="full_name"
                          value={formData.full_name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      {formData.fullnameError && (
                        <div className="fullnameerror">
                          {formData.fullnameError}
                        </div>
                      )}

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className="input-field"
                          type="number"
                          placeholder={phoneNumber}
                          name="phone_number"
                          value={formData.phone_number}
                          onChange={handleChange}
                          maxLength={10} // set maximum input length to 10 digits
                          required
                        />
                      </div>
                      <p
                        style={{
                          fontSize: "12px",
                          paddingRight: "20px",
                          float: "right",
                        }}
                      >
                        {digitCount}/10
                      </p>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className="input-field"
                          type="email"
                          placeholder={email}
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <Link onClick={handleButtonClick} className='ms-4'>change profile image</Link>
                      <Button variant="primary" type="submit" className="nextBtn mt-3">
                        Save
                      </Button>
                    </Form>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModalReload}>
                      Done
                    </Button>
                  </Modal.Footer>
                </Modal>


                <p className='mt-2  fw-bold fs-4' style={{ color: "#27ae60" }}>{fullName}</p>

                {/* <div className='d-flex justify-content-center landlord_type'>
                  {landlordTypeMain && landlordTypeMain.landlord_type ? (
                    <>
                      {landlordTypeMain?.landlord_type?.toLowerCase() === 'landlord' ? (
                        <p className='landlord p-2 rounded-3 text-white'>{landlordTypeMain.landlord_type}</p>
                      ) : landlordTypeMain?.landlord_type?.toLowerCase() === 'caretaker' ? (
                        <p className='caretaker p-2 rounded-3 text-white'>{landlordTypeMain.landlord_type}</p>
                      ) : landlordTypeMain?.landlord_type?.toLowerCase() === 'agent' ? (
                        <p className='agent p-2 rounded-3 text-white'>{landlordTypeMain.landlord_type}</p>
                      ) : (
                        <p className='p-2 rounded-3 text-white'>null</p>
                      )}
                    </>
                  ) : null}
                </div> */}


                <span>{phoneNumber} | {email}</span>
                <p className='text-muted'>Number Of Apartments : {apartmentCount} </p>
                <Link className='' onClick={handleOpenModalPassword}>Change Password</Link><br></br>
                <br></br>

                {/* Change password*/}
                <Modal show={showModalPassword} onHide={handleCloseModalPassword}>
                  <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleChangePassword}>
                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className={`input-field ${isValidEmail ? '' : 'invalid'}`}
                          type="email"
                          placeholder="Enter Your Registered Email"
                          name="emailPassword"
                          value={emailPassword}
                          onChange={(e) => setEmailPassword(e.target.value)}
                          required
                        />
                      </div>
                      {!isValidEmail && (
                        <p className="error-message text-danger">Enter Valid Email</p>
                      )}
                      <Button type='submit' className='save-btn'> Send</Button>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModalTerms}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Link className="terms" onClick={handleOpenModalTerms}>
                  Terms & Conditions
                </Link>{" "}
                {/* Terms and conditions modal*/}
                <Modal show={showModalTerms} onHide={handleCloseModalTerms}>
                  <Modal.Header closeButton>
                    <Modal.Title>Terms And Conditions</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <TermsConditions />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModalTerms}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                and
                <Link className="privatePolicy" onClick={handleOpenModalPrivacy}>
                  {" "}
                  Privacy Policy
                </Link>
                <Modal show={showModalPrivacy} onHide={handleCloseModalPrivacy}>
                  <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Privacy />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={handleCloseModalPrivacy}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>

            <div className='tasks d-none' >
              <h5 className='float-start'>My List of Properties</h5>
              <Table className='table'>
                <thead className='underline'>
                  <th>Image</th>
                  <th>Property</th>
                  <th>Role</th>
                  <th>Accounts</th>
                  <th>Units</th>
                  <th>Occupancy</th>
                  <th>Income Expected</th>

                </thead>
                <tbody>
                  {displayedItems.map((apartment, index) =>
                    <tr key={index.id}>
                      <td>
                        {selectedApartmentImage[apartment.id] && selectedApartmentImage[apartment.id] ?
                          <Image src={selectedApartmentImage[apartment.id]} className="imageTableProfile" alt={index} style={{ width: "70px", height: "47px" }}></Image> :
                          <Image src={backgroundImageApartment[apartment.id]} className="imageTableProfile" style={{ width: "70px", height: "47px" }}></Image>
                        }
                      </td>

                      <td>
                        <Link to={`/selected-property/${apartment.id}`}>
                          {apartment.name}
                        </Link>
                      </td>
                      {landlordType[apartment.id]?.toLowerCase() === 'landlord' ?
                        (<td className='landlord'>{landlordType[apartment.id]?.toLowerCase()}</td>)
                        : landlordType[apartment.id]?.toLowerCase() === 'agent' ?
                          (<td className='agent'>{landlordType[apartment.id]?.toLowerCase()}</td>)
                          :
                          (<td className='caretaker'>{landlordType[apartment.id]?.toLowerCase()}</td>)
                      }


                      <td><Link to={`/account/${apartment.id}`}>
                        {accountNumbers[apartment.id]}
                      </Link></td>
                      <td>{houseCount[apartment.id]}</td>

                      {rentPercentageApartment[apartment.id] > 0 ?
                        <>
                          {(rentPercentageApartment[apartment.id]) < 33 ? (
                            <td><span className={`badge bg-danger p-2`}>{(rentPercentageApartment[apartment.id]) } %</span></td>

                          ) : (rentPercentageApartment[apartment.id]) < 67 ? (

                            <td><span className={`badge bg-warning p-2`}>{(rentPercentageApartment[apartment.id]) }%</span></td>
                          ) : (
                            <td><span className={`badge bg-success p-2`}>{(rentPercentageApartment[apartment.id]) }%</span></td>
                          )}
                        </>
                        :
                        <td><span className={`badge bg-danger p-2`}>0%</span></td>
                      }

                      <td>Ksh. {incomeCount[apartment.id]?.toLocaleString()}</td>


                    </tr>
                  )}




                </tbody>
              </Table>
              <div className="pagination">
                  <button onClick={handlePrevPage} disabled={currentPage === 0}>
                    &lt;
                  </button>
                  <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                    &gt;
                  </button>
                </div>

            </div>

          </Col>
        </Row>
      </div>


    </>
  )
}

export default Profile