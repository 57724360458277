import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Col, Image, NavItem } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './SideBar.scss';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router";
import Reports from './../main/Reports';
import { toast } from "react-toastify";
import Logo from '../../assets/img/logo.png'
import { useParams, Link } from 'react-router-dom'
import PermissionComponent from './PermisionComponent';

function SideBar() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth <= 768) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [windowWidth]);

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  // for logout purposes
  const navigate = useNavigate();
  const handleLogout = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem("authTokens");
    toast.success('Successfull logged out !')
    navigate("/login");
  }
  const [showLeads, setShowLeads] = useState(false)
  const handleShowLead = () => {
    setShowLeads(!showLeads)
  }


  const [showRoles, setShowRoles] = useState(false)
  const handleShowRoles = () => {
    setShowRoles(!showRoles)
  }


  const [showUploads, setShowUploads] = useState(false)
  const handleShowUploads = () => {
    setShowUploads(!showUploads)
  }

  // Function to handle topics from PermissionComponent
  const handleTopics = (groupedTopics) => {
    const topics = Object.keys(groupedTopics);
    setTopics(topics);
  };


  return (
    <div fluid className="p-0 SideBar">
      <Navbar className="Logo">
        <button className="btn sidebar-toggle navbar-toggler-icon me-3 ms-3"
          onClick={handleToggleSidebar}

        ></button>
        <Navbar.Brand href="/dashboard">
          
          <Image src={Logo} className='' style={{height:"5vh",marginTop:"-0.2rem",marginLeft:"0.5rem"}} /> 
          <span style={{marginLeft:'0.5rem'}}>Alliance Realtors Limited</span>
        </Navbar.Brand>
      </Navbar>

      <PermissionComponent setGroupedPermissions={handleTopics} />
      <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
        <Nav defaultActiveKey="/" className="flex-column">

          {topics.includes("apartment") && (
            <>
              <Nav.Link as={NavLink} to="/dashboard" exact>
                <i className="bi bi-speedometer2"></i>
                Dashboard
              </Nav.Link>
              <Nav.Link as={NavLink} to="/property">
                <i className="bi bi-houses"></i>
                Properties
              </Nav.Link>


              <Nav.Link onClick={handleShowLead}>
                <i className="bi bi-bar-chart-line"></i>
                Leads
                <i className="bi bi-chevron-down" style={{ right: "0%", position: "absolute" }}></i>

              </Nav.Link>
              {showLeads &&
                <Nav as="ul" className="nested-nav d-block" style={{ marginLeft: '2.5rem' }}>
                  <NavItem as="li">
                    <NavLink as={Link} to="/leads-landlord" className='text-decoration-none text-dark'>
                      <i class="bi bi-people me-2"></i>
                      Landlords
                    </NavLink>
                  </NavItem>
                  <NavItem as="li">
                    <NavLink as={Link} to="/leads-viewer" className='text-decoration-none text-dark'>
                      <i class="bi bi-eye me-2"></i>
                      Viewers
                    </NavLink>
                  </NavItem>
                  <NavItem as="li">
                    <NavLink as={Link} to="/feedback" className='text-decoration-none text-dark'>
                      {/* <i class="bi bi-calendar-check me-2"></i> */}
                      <i class="bi bi-chat-left-dots me-2"></i>
                      Feedback
                    </NavLink>
                  </NavItem>
                </Nav>
              }

              <Nav.Link as={NavLink} to="/bulk">
                <i className="bi bi-cloud-upload"></i>
                Bulk Uploads
              </Nav.Link>
            </>
          )}


          {topics.includes("group") && (
            <>

              <Nav.Link onClick={handleShowRoles}>
                <i class="bi bi-key"></i>
                Roles
                <i className="bi bi-chevron-down" style={{ right: "0%", position: "absolute" }}></i>
              </Nav.Link>
              {showRoles &&
                <Nav as="ul" className="nested-nav d-block" style={{ marginLeft: '0.5rem' }}>
                  <NavItem as="li">
                    <NavLink as={Link} to="/assign-role" className='text-decoration-none text-dark'>
                      <i class="bi bi-person-lock me-2"></i>
                      Roles Management
                    </NavLink>
                  </NavItem>
                  <NavItem as="li">
                    <NavLink as={Link} to="/add-member" className='text-decoration-none text-dark'>
                      <i class="bi bi-person-plus me-2"></i>
                      Add Members
                    </NavLink>
                  </NavItem>
                  <NavItem as="li">
                    <NavLink as={Link} to="/add-contact" className='text-decoration-none text-dark'>
                      <i class="bi bi-headset me-2"></i>
                      Add Contacts
                    </NavLink>
                  </NavItem>

                  <NavItem as="li">
                    <NavLink className='text-decoration-none text-dark' onClick={handleShowUploads}>
                      {/* <i class="bi bi-calendar-check me-2"></i> */}
                      <i class="bi bi-check2-circle me-2"></i>
                      Approve Uploads
                      <i className="bi bi-chevron-down" style={{ right: "5%", position: "absolute" }}></i>

                    </NavLink>
                    {showUploads &&
                      <Nav as="ul" className="nested-nav d-block" style={{ marginLeft: '-9.5rem', marginTop: "2rem" }}>
                        <NavItem as="li">
                          <NavLink as={Link} to="/approve-property" className='text-decoration-none text-dark'>
                            <i className="bi bi-houses me-2"></i>
                            Properties
                          </NavLink>
                        </NavItem>
                        <NavItem as="li">
                          <NavLink as={Link} to="/approve-blog" className='text-decoration-none text-dark'>
                            <i class="bi bi-blockquote-right me-2"></i>
                            Blogs
                          </NavLink>
                        </NavItem>
                      </Nav>
                    }
                  </NavItem>
                </Nav>
              }

            </>
          )}





          {topics.includes("blog") && (
            <>
              <Nav.Link as={NavLink} to="/blog">
                <i class="bi bi-blockquote-right"></i>
                Blogs
              </Nav.Link>
            </>
          )}



          <Nav.Link as={NavLink} to="/profile">
            <i className="bi bi-person"></i>
            Profile
          </Nav.Link>


          <Nav.Link onClick={handleLogout}>
            <i className="bi bi-box-arrow-left"></i>
            SignOut
          </Nav.Link>


        </Nav>
      </div>
    </div>
  );
}

export default SideBar;
