import React, { useState } from 'react'
import '../pages/ApartmentImage.css'
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'
import { useParams, Link } from "react-router-dom";

function SelectedBlogImage() {
    const navigate = useNavigate()
    const { id } = useParams();

    // the image declaration
    const [image, setImage] = useState(null);

    //   button that does the uploading to while the input is hidden
    const handleButtonClick = () => {
        document.getElementById('imageUpload').click();
    };

    // the data to be sent in the backend
    const [formData, setFormData] = useState({
        Apartmentimg: null
    });

    // onchange functionality

    const handleChange = (event) => {
        const { name, value } = event.target;
        // handling the selected image 

        const selectedImage = event.target.files[0];
        if (selectedImage && selectedImage.type.includes('image')) {
            setImage(selectedImage);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Apartmentimg: selectedImage,
            }));
        } else {
            setImage(null);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Apartmentimg: null,
            }));
            toast.error('Kindly select an image file!');
        }
    }
    // handling the form and how the data will be sent to the backend
    async function handleSubmit(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can link with the Apartment from the access tokens
        const accessToken = localStorage.getItem("authTokens");


        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the Apartment id to be used
        const Apartment = localStorage.getItem('Apartment')
        // the data that will be posted to the API is as follows
        var statusCover = false

        if (document.querySelector('input[type="checkbox"]').checked) {
            // when the user decides to click the checkbox, then check if that blog has a cover image
            const response = await serverUrl.get(`/landlord/blog-image/?blog_id=${id}`, config)

            const foundBlog = response.data.results.find(blog => blog.is_main === true);
            if (foundBlog) {
                // when the blog is found, update it and make is_main to false
                const updateData = {
                    is_main: false
                };
                serverUrl.patch(`/landlord/blog-image/${foundBlog.id}/`, updateData, config)
                    .then((res) => {
                    })
                    .catch((error) => {
                        console.log(error);
                    });


            } else {
                // Append is_main to the FormData
                statusCover = true
            }
        }

        const dataBlog = new FormData();
        dataBlog.append('blog', id);
        dataBlog.append('image', formData.Apartmentimg);
        dataBlog.append('is_main', statusCover)

        //  now post the data
        try {

            serverUrl                //send the Apartment image using the following endpoint
                .post("/landlord/blog-image/", dataBlog, config)
                .then((res) => {
                    toast.success('Successfully Added Blog Image!');
                    navigate(`/selected-blog/${id}`)

                })

        } catch (error) {
            toast.error(error);
        }

    }

    return (
       <>
       <div className='d-flex justify-content-'>
                <Link to={`/selected-blog/${id}`} className='addHouse'>
                <i
                        className="bi bi-arrow-left-circle-fill"
                        style={{ fontSize: '2.5rem', color: '#006132',padding:"1rem" }}
                    ></i>    
                </Link>
                <h2></h2>

            </div>
       
       <div className="imageProperty">
            <h2>Blog Image(s)</h2>

            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="input-icons">
                    <Button className='imageButton' onClick={handleButtonClick}>
                        Select Image For Blog
                    </Button>
                    <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleChange}
                    />
                    {image && (
                        <>
                            <div>
                                <img src={URL.createObjectURL(image)} alt="preview" className='imageUploaded' />
                            </div>

                            <div>
                                <input type='checkbox' />
                                <span>Is this the cover image for the blog?</span>
                            </div>
                        </>
                    )}

                    <div className="bottomButtons">
                        <Button type="button" className="skipButton">
                            Cancel
                        </Button>

                        <Button type="submit" className="nextButton">
                            Add
                        </Button>
                    </div>
                </div>


            </Form>

        </div>
       </>
    )
}

export default SelectedBlogImage
