import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './ApproveBlog.scss'
import { Button, Card, FloatingLabel, Container, Form, FormControl, Image, Modal, Table } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'
import Apartment from '../../assets/img/apartment.png'
import Unit from '../../assets/img/feedback.png'

function ApproveBlog() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showModalUnit, setShowModalUnit] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // pasing the apartment Details to the modal
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModalUnit = () => {
        setShowModalUnit(true);
    };

    const handleCloseModalUnit = () => {
        setShowModalUnit(false);
    };

    // apartments of the landlord
    const [viewerLeads, setViewerLeads] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [likedProperties, setLikedProperties] = useState([])
    const [likedUnitsData, setLikedUnitsData] = useState([])

    // get the members for alliance
    const [blogs, setBlogs] = useState([])
    const [propertiesManaged, setPropertiesManaged] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Get the blogs
                const blogsResponse = await serverUrl.get(`landlord/blog/`, config);
                const blogsData = blogsResponse.data.results;

                // Loop through the blogs and get the user id
                for (const blog of blogsData) {
                    const userId = blog.user; // Assuming the user id is stored in the 'user' field
                    // Fetch user details using the user id to get full_name
                    const userResponse = await serverUrl.get(`users/users-details/${userId}/`, config);
                    const user = userResponse.data; // Assuming user data is returned in a format that suits your needs

                    // Update the blogs array to include the full_name
                    blog.full_name = user.full_name;
                }

                // Update the state with the modified blogs array
                setBlogs(blogsData);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);


    // modal for adding member
    const [addMemberModal, setAddMemberModal] = useState(false)

    const handleOpenAddMember = () => {
        setAddMemberModal(true);
    };
    const handleCloseAddMember = () => {
        setAddMemberModal(false);
    };

    const [formDataMember, setFormDataMember] = useState({
        name: '',
        email: '',
        phone: ''
    })

    const handleChangeMember = (event) => {
        const { name, value } = event.target;

        if (name === "phone") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormDataMember((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormDataMember((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

    };
    const digitCount = formDataMember.phone.replace(/[^0-9]/g, "").length;

    function handleAddMember(e) {
        e.preventDefault()

        const data = {
            full_name: formDataMember.name,
            email: formDataMember.email,
            phone_number: formDataMember.phone,
            password: 'asdfhjkl',
            gender: formDataMember.gender
        }
        // add the user in the user tables
        serverUrl.post('/users/register/', data)
            .then((res) => {
                // add the user in the landlord table
                const userId = res.data.data.id
                const landlordData = {
                    user: userId,
                    kra_pin: null,
                    id_number: null,
                }
                serverUrl.post('/landlord/landlord/', landlordData, config)
                    .then((res) => {
                        console.log(res.data.id)
                        // add the member in the alliance member table
                        const alliance = {
                            user: parseInt(userId),
                            landlord: parseInt(res.data.id)
                        }
                        serverUrl.post('/landlord/alliance-members/', alliance, config)
                            .then((res) => {
                                toast.success('Succesfully added the member')
                                setAddMemberModal(false)
                                serverUrl.get(`landlord/alliance-members`, config)
                                    .then((res) => {
                                        setBlogs(res.data.results)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    toast.error('Registration failed user with similar credentials is already registered')

                } else {
                    console.log('Unexpected error: ' + error.message);
                }
            })
    }


    const uniqueUnits = new Map();

    const filteredLikedUnitsData = likedUnitsData.reduce((result, unit) => {
        const key = `${unit.unitId}-${unit.unitApartment}`;

        // If the unit is not in uniqueUnits or has a later liked time, update the entry
        if (!uniqueUnits.has(key) || unit.latestLikedTime > uniqueUnits.get(key).latestLikedTime) {
            uniqueUnits.set(key, unit);
        }

        return Array.from(uniqueUnits.values());
    }, []);

    // Now filteredLikedUnitsData should contain unique units with the latest liked time

    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = viewerLeads.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = blogs.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    // const displayedItems = allianceMembers.map((lead) => ({
    //     ...lead,
    //     propertiesManaged: propertiesManaged[lead.id] || 0,
    // }));
    const displayedItems = filteredData.slice(startIndex, endIndex);


    const exportCSV = () => {
        // const csvData = [
        //     ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked', 'Unit Liked'],
        //     ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked, item.unitsLiked,]),
        // ];
        const csvData = [
            ['Name', 'Email', 'Phone Number', 'Date Joined', 'Property Liked'],
            ...viewerLeads.map((item) => [item.user_name, item.user_email, item.user_phone, item.created_at, item.apartmentsLiked,]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Viewer Lead.csv');
    };

    // on search of the unit liked
    const [searchQueryUnit, setSearchQueryUnit] = useState("");
    const [selectedRowUnit, setSelectedRowUnit] = useState(null);

    const handleSearchUnit = (event) => {
        setSearchQueryUnit(event.target.value);
        setSelectedRowUnit(null); // Reset selected row when user starts a new search
    };

    const filteredDataUnit = filteredLikedUnitsData.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryUnit.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageUnit, setCurrentPageUnit] = useState(0);
    const itemsPerPageUnit = 10;

    const totalPagesUnit = Math.ceil(filteredDataUnit.length / itemsPerPageUnit);
    const startIndexUnit = currentPageUnit * itemsPerPageUnit;
    const endIndexUnit = currentPageUnit === totalPagesUnit - 1 ? filteredDataUnit.length : startIndexUnit + itemsPerPageUnit;
    const displayedItemsUnit = filteredDataUnit.slice(startIndexUnit, endIndexUnit);

    const exportCSVUnit = () => {
        const csvData = [
            ['Unit', 'Apartment', 'Likes', 'Liked Date'],
            ...filteredLikedUnitsData.map((item) => [item.unitName, item.unitApartment, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Likes.csv');
    };

    const handlePrevPageUnit = () => {
        setCurrentPageUnit(currentPageUnit - 1);
    };

    const handleNextPageUnit = () => {
        setCurrentPageUnit(currentPageUnit + 1);
    };


    // on search of the property liked
    const [searchQueryProperty, setSearchQueryProperty] = useState("");
    const [selectedRowProperty, setSelectedRowProperty] = useState(null);

    const handleSearchProperty = (event) => {
        setSearchQueryProperty(event.target.value);
        setSelectedRowProperty(null); // Reset selected row when user starts a new search
    };

    const filteredDataProperty = likedProperties.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryProperty.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageProperty, setCurrentPageProperty] = useState(0);
    const itemsPerPageProperty = 10;

    const totalPagesProperty = Math.ceil(filteredDataProperty.length / itemsPerPageProperty);
    const startIndexProperty = currentPageProperty * itemsPerPageProperty;
    const endIndexProperty = currentPageProperty === totalPagesProperty - 1 ? filteredDataProperty.length : startIndexProperty + itemsPerPageProperty;
    const displayedItemsProperty = filteredDataProperty.slice(startIndexProperty, endIndexProperty);

    const exportCSVProperty = () => {
        const csvData = [
            ['Apartment', 'Likes', 'Liked Date'],
            ...likedProperties.map((item) => [item.apartmentName, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Property Likes.csv');
    };

    const handlePrevPageProperty = () => {
        setCurrentPageProperty(currentPageProperty - 1);
    };

    const handleNextPageProperty = () => {
        setCurrentPageProperty(currentPageProperty + 1);
    };
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of viewerLeads) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [viewerLeads]);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    const [selectedBlog, setSelectedBlog] = useState([])
    const viewBlog = (member) => {
        setAddMemberModal(true);

        serverUrl.get(`/landlord/blog/${member}/`, config)
            .then((res) => {
                setSelectedBlog(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const unapproveblog = (member) => {
        const data = {
            is_public: false
        }

        serverUrl.patch(`/landlord/blog/${member}/`, data, config)
            .then((res) => {
                toast.success('Succesfully unaproved the blog')
                const fetchData = async () => {
                    try {
                        // Get the blogs
                        const blogsResponse = await serverUrl.get(`landlord/blog/`, config);
                        const blogsData = blogsResponse.data.results;

                        // Loop through the blogs and get the user id
                        for (const blog of blogsData) {
                            const userId = blog.user; // Assuming the user id is stored in the 'user' field
                            // Fetch user details using the user id to get full_name
                            const userResponse = await serverUrl.get(`users/users-details/${userId}/`, config);
                            const user = userResponse.data; // Assuming user data is returned in a format that suits your needs

                            // Update the blogs array to include the full_name
                            blog.full_name = user.full_name;
                        }

                        // Update the state with the modified blogs array
                        setBlogs(blogsData);
                    } catch (error) {
                        console.error('Error fetching blog data:', error);
                    }
                };

                fetchData();
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const approveblog = (member) => {
        const data = {
            is_public: true
        }

        serverUrl.patch(`/landlord/blog/${member}/`, data, config)
            .then((res) => {
                toast.success('Succesfully approved the blog')
                const fetchData = async () => {
                    try {
                        // Get the blogs
                        const blogsResponse = await serverUrl.get(`landlord/blog/`, config);
                        const blogsData = blogsResponse.data.results;

                        // Loop through the blogs and get the user id
                        for (const blog of blogsData) {
                            const userId = blog.user; // Assuming the user id is stored in the 'user' field
                            // Fetch user details using the user id to get full_name
                            const userResponse = await serverUrl.get(`users/users-details/${userId}/`, config);
                            const user = userResponse.data; // Assuming user data is returned in a format that suits your needs

                            // Update the blogs array to include the full_name
                            blog.full_name = user.full_name;
                        }

                        // Update the state with the modified blogs array
                        setBlogs(blogsData);
                    } catch (error) {
                        console.error('Error fetching blog data:', error);
                    }
                };

                fetchData();
            })
            .catch((error) => {
                console.log(error)
            })
    };

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }



    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property leadLanlord'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>APPROVE BLOGS POSTED</p>

                </div>

                <div className='tasks ' >
                    <div className='d-flex justify-content-between'>

                        <div>
                            {/* <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button> */}
                            {/* <Button className='btn btn-warning btn-sm text-white' onClick={handleOpenAddMember}>Add Member <i class="bi bi-person-plus"></i></Button> */}
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>

                    </div>

                    <Table className='table mt-2'>
                        <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                            <th>Blog Name</th>
                            <th>Category</th>
                            <th>Posted By</th>
                            <th>Date Posted</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>

                            {displayedItems.map((lead) => (
                                <tr key={lead.id}>
                                    <td>{lead.name}</td>
                                    <td>{lead.category}</td>
                                    <td>{lead.full_name}</td>
                                    <td>{formatDate(lead.created_at)}</td>
                                    <td><Button className='btn btn-success text-white btn-sm' onClick={() => viewBlog(lead.id)} ><i className='bi bi-eye'></i> View</Button></td>

                                    <td>
                                        {lead.is_public === true ?
                                            <Button className='btn btn-warning text-white btn-sm' onClick={() => unapproveblog(lead.id)} ><i className='bi bi-check'></i> Unapprove</Button>
                                            :
                                            <Button className='btn btn-success text-white btn-sm' onClick={() => approveblog(lead.id)} ><i className='bi bi-check'></i> Approve</Button>
                                        }
                                    </td>

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${blogs.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </div>


                {/* modal to add member */}
                <Modal show={addMemberModal} onHide={handleCloseAddMember} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedBlog.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "80vh", overflow: "auto" }}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: selectedBlog.long_description,
                            }}
                        ></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddMember}>Close</Button>
                    </Modal.Footer>
                </Modal>


            </div>

        </div >
    )
}

export default ApproveBlog




