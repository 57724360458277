import React, { useReducer, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Form, FloatingLabel, Image } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";
import { Card, Accordion } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const animatedComponents = makeAnimated();

const steps = [
  "Property Details",
  "Units",
  "Units Details",
  "Gallery",
  "About Status",
];

const typeOfHouseChoices = [
  { value: "apartment_units", label: "Apartment Units" },
  { value: "studio", label: "Studio" },
  { value: "bungalow", label: "Bungalow" },
  { value: "bedsitter", label: "Bedsitter" },
  { value: "servant_quarters", label: "Servant Quarters(SQ)" },
  { value: "mansionette", label: "Mansionette" },
  { value: "office", label: "Office" },
  { value: "stall", label: "Stall" },
  { value: "shop", label: "Shop" },
  { value: "1_bedroom", label: "1 Bedroom" },
  { value: "2_bedroom", label: "2 Bedroom" },
  { value: "3_bedroom", label: "3 Bedroom" },
  { value: "4_bedroom", label: "4 Bedroom" },
  { value: "5_bedroom", label: "5 Bedroom" },
  { value: "6_bedroom", label: "6 Bedroom" },
  { value: "7_bedroom", label: "7 Bedroom" },
  { value: "8_bedroom", label: "8 Bedroom" },
];

const initialState = {
  step: 0,
  form1: {
    name: "",
    location: "",
    county: "",
    latitude: "",
    longitude: "",
  },
  form2: {
    selectedOptions: [],
    apartmentId: "",
    apartmentName: "",
  },
  // ... (other form states)
};

const reducer = (state, action) => {
  switch (action.type) {
    case "next":
      return { ...state, step: state.step + 1 };
    case "back":
      return { ...state, step: state.step - 1 };
    case "reset":
      return { ...state, step: 0 };
    default:
      return state;
  }
};

const MainStepper = ({ title }) => {
  const accessToken = localStorage.getItem("authTokens");
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;
  function toCamelCase(str) {
    return str.replace(/_/g, " ");
  }
  const [state, dispatch] = useReducer(reducer, initialState);
  const { step } = state;

  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  // the first step functionalities
  const [counties, setCounties] = useState([]);
  const [landlord, setLandlord] = useState([]);
  const [members, setMembers] = useState([]);


  useEffect(() => {
    // Fetch counties from /lanlord/county endpoint
    serverUrl
      .get(`/landlord/county`)
      .then((res) => {
        setCounties(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    // get the members of alliance
    serverUrl
      .get(`/landlord/alliance-members`, config)
      .then((res) => {
        setMembers(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });


    serverUrl
      .get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        setLandlord(res.data.results[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [addedApartments, setAddedApartments] = useState({});
  const [memberApartment, setMemberApartment] = useState({});

  function handleMemberChange(event) {
    const selectedMember = JSON.parse(event.target.value);
    setMemberApartment(selectedMember);
  }

  //   add the apartment data
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // get the data
    const propertyData = {
      name: formData.get("name"),
      location: formData.get("location"),
      county: formData.get("county"),
      latitude: formData.get("latitude"),
      longitude: formData.get("longitude"),
      for_kodi: false,
      about:null
    };



    // post the data of the property
    serverUrl
      .post(`/landlord/apartment/`, propertyData, config)
      .then((res) => {
        setAddedApartments(res.data);
        // post the contact data 
        const contactData = {
          contact_name: memberApartment.user.full_name,
          contact_email: memberApartment.user.email,
          contact_number: memberApartment.user.phone_number,
          landlord: memberApartment.landlord,
          apartment: res.data.id
        };
        serverUrl.post("/landlord/contact-apartment-booking/", contactData, config)
          .then((res) => {

          })
          .catch((error) => {
            console.log(error)
          })
        // post the apartment in the landlord apartment table
        const data = {
          landlord: landlord.id,
          apartment: res.data.id,
        };
        serverUrl
          .post("/landlord/landlord-apartment/", data, config)
          .then((res) => {
            dispatch({ type: "next" });
            toast.success("Succesfull added property details");
          })
          .catch((error) => {
            console.log(error);
            toast.error("Kindly try again an error occured");
          });


      })
      .catch((error) => {
        console.log(error);
        toast.error("Kindly try again an error occured");
      });
  };
  // end of step one execution

  // step two execution process
  const [unitsAdded, setAddedUnits] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleDone = () => {
    setIsSelectOpen(false); // Close the select options
  };

  const handleHouseTypeChange = (selectedOptions) => {
    // Filter out options with the word "bedroom" and handle them separately
    const nonBedroomOptions = selectedOptions.filter(
      (option) => !option.label.toLowerCase().includes("bedroom")
    );
    const bedroomOptions = selectedOptions.filter((option) =>
      option.label.toLowerCase().includes("bedroom")
    );

    // Remove the word "bedroom" from bedroom options
    const cleanedBedroomOptions = bedroomOptions.map((option) => ({
      ...option,
      label: option.label.replace(" Bedroom", ""),
    }));

    // Update state with all selected options
    setSelectedOptions(selectedOptions);
  };

  const handleSubmitAddUnit = async (event) => {
    event.preventDefault();
    let unitsArray = [];

    try {
      await Promise.all(
        selectedOptions.map(async (option) => {
          let type_of_house = option.value;
          let bedrooms = null;
          let rent_type = "residential";

          if (type_of_house.toLowerCase().includes("bedroom")) {
            bedrooms = parseInt(type_of_house.match(/\d+/)[0]);
            type_of_house = "apartment_units";
          }
          // Check for commercial properties
          if (["office", "shop"].includes(type_of_house.toLowerCase())) {
            rent_type = "commercial";
          }
          // Check for standalone properties
          else if (
            ["bungalow", "mansionette"].includes(type_of_house.toLowerCase())
          ) {
            rent_type = "stand_alone";
          }
          const currentTime = new Date();
          const formattedDate = currentTime.toISOString().split("T")[0];

          const data = {
            house_number: addedApartments.name,
            apartment: parseInt(addedApartments.id),
            bedroom: null,
            bathroom: null,
            token_prepaid_account_number: null,
            about: null,
            due_date: formattedDate,
            type_of_house: type_of_house,
            rent_type: rent_type,
            ...(bedrooms !== null && { bedroom: bedrooms }),
          };

          const res = await serverUrl.post(`/landlord/house/`, data, config);
          unitsArray.push(res.data);
        })
      );
      toast.success("Succesfull added units for the property");
      setAddedUnits(unitsArray);
      dispatch({ type: "next" });
    } catch (error) {
      console.log(error);
    }
  };
  // end of step two

  //   step three execution process
  const [formDataUnits, setFormDataUnits] = useState({
    bedroom: "",
    bathroom: "none",
    rent: "",
    rent_range:"",
    deposit_description:"",
    deposit: "",
  });

  // Handle form field changes
  const handleChange = (event, unitId) => {
    const { name, value } = event.target;
    setFormDataUnits((prevFormData) => ({
      ...prevFormData,
      [unitId]: {
        ...prevFormData[unitId],
        [name]: value,
      },
    }));
  };

  const [unitsAddedUpdate, setUnitsAddedUpdate] = useState([]);
  const handleRemoveUnit = (unitId) => {
    // remove the unit from the list
    serverUrl
      .delete(`/landlord/house/${unitId}`, config)
      .then((res) => {
        toast.success("Successfully removed the unit from the property");
        // Update the state to exclude the removed unit
        setAddedUnits((prevUnits) =>
          prevUnits.filter((unit) => unit.id !== unitId)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Handle form submission
  const [hasRange, setHasRange] = useState(false)
  const [hasMonths, setHasMonths] = useState(false)

  const handleUpdate = (e, unitId) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // Map bathroom values to specific numerical values
    const bathroomMapping = {
      ensuite: 1001,
      master: 1111,
      none: 0,
      ...[...Array(8).keys()].reduce((acc, number) => {
        acc[number + 1] = number + 1;
        return acc;
      }, {}),
    };
    const updatedUnit = {
      id: unitId,
      bedroom: formData.get("bedroom"),
      bathroom: bathroomMapping[formData.get("bathroom")],
      rent: formData.get("rent") !== null ? formData.get("rent") : 0,
      rent_range: formData.get("max_rent") + '-' + formData.get("min_rent"),
      deposit: formData.get("deposit") !== null ? formData.get("deposit") : 0,
      deposit_description: formData.get("deposit_description"),
    };
    
    const updatedUnits = unitsAddedUpdate.map((unit) =>
      unit.id === unitId ? updatedUnit : unit
    );

    // If the unit is not in the array, add it
    if (!updatedUnits.find((unit) => unit.id === unitId)) {
      updatedUnits.push(updatedUnit);
    }

    // Set the updated units array in the state
    toast.success("Succesfully updated the unit");
    setUnitsAddedUpdate(updatedUnits);
  };

  const updateUnitsOnNext = async () => {
    try {
      const unitsToBeUpdated = await Promise.all(
        unitsAddedUpdate.map(async (unit) => {
          const data = {
            bedroom: parseInt(unit.bedroom),
            bathroom: unit.bathroom,
            rent: parseInt(unit.rent),
            rent_range: unit.rent_range,
            deposit: parseInt(unit.deposit),
            deposit_description: unit.deposit_description,
          };
          const response = await serverUrl.patch(
            `/landlord/house/${unit.id}/`,
            data,
            config
          );
        })
      );
      toast.success("Succesfully update the units details");
      dispatch({ type: "next" });
    } catch (error) {
      console.error("Error updating units:", error);
      toast.error("Kindly try again an error occured");
    }
  };
  // end of step three

  // step four execution
  const [selectedImages, setSelectedImages] = useState([]);
  const onDrop = (acceptedFiles) => {
    // Update selectedImages state with the accepted files
    setSelectedImages((prevImages) => [...prevImages, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Allow only image files
    multiple: true, // Allow multiple files
  });
  const handleRemoveImage = (index) => {
    // Remove the selected image from the state
    setSelectedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  // function to upload the images
  async function handleSubmitImage(event) {
    event.preventDefault();

    if (selectedImages.length < 5) {
      toast.error("Please upload a minimum of 5 images.");
      return;
    }

    try {
      toast.info("Uploading images. Please be patient...", {
        autoClose: 60000, // 1 minute
      });
      const uploadPromises = [
        ...selectedImages.map(async (image) => {
          const formDataApartment = new FormData();
          formDataApartment.append("image", image, image.name);
          formDataApartment.append("apartment", parseInt(addedApartments.id));

          return serverUrl.post(
            "/landlord/apartment-images/",
            formDataApartment,
            config
          );
        }),
        ...unitsAddedUpdate.flatMap((unit) =>
          selectedImages.map(async (image) => {
            const formDataHouse = new FormData();
            formDataHouse.append("image", image, image.name);
            formDataHouse.append("house", parseInt(unit.id));

            return serverUrl.post(
              "/landlord/house-images/",
              formDataHouse,
              config
            );
          })
        ),
      ];

      // Parallelize requests using Promise.all
      const results = await Promise.all(uploadPromises);

      console.log(results);
      toast.success("Successfully uploaded the images");
      dispatch({ type: "next" });
    } catch (error) {
      console.error(error);
      toast.error(error.message || "Error uploading images");
    }
  }


  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  };

  const removeButtonStyles = {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "red",
    border: "none",
    borderRadius: "0",
    padding: "0px",
    cursor: "pointer",
  };

  // end of step four execution

  // step five execution
  const [description, setDescription] = useState("");
  const handleSubmitDescription = async (event) => {
    event.preventDefault();
    const data = {
      about: description,
    };

    try {
      // Update the apartment details
      await serverUrl.patch(
        `landlord/apartment/${addedApartments.id}/`,
        data,
        config
      );

      // Update the units using Promise.all
      await Promise.all(
        unitsAddedUpdate.map(async (unit) => {
          await serverUrl.patch(`/landlord/house/${unit.id}/`, data, config);
        })
      );

      // All steps completed successfully
      toast.success("Finished all the steps and updated the data");
      dispatch({ type: "reset" });
      Navigate('/property')

      // Reset or clear data for the next steps
      setAddedApartments(null);
      setUnitsAddedUpdate([]);
      setDescription("");


    } catch (error) {
      console.log(error);
      // Handle errors if needed
    }
  };


  // end of step five execution

  const handleBack = () => {
    dispatch({ type: "back" });
  };
  const handleNext = () => {
    dispatch({ type: "next" });
  };

  return (
    <Box sx={{ width: "100%", paddingTop: "5rem" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {step === 0 && (
        <Form
          onSubmit={handleSubmit}
          style={{ width: "75%", margin: "0 auto" }}
        >
          <h2 className="mt-2">Kindly enter property the details</h2>
          <Form.Group className="mb-3" controlId="propertyName">
            <FloatingLabel controlId="propertyName" label="Property Name">
              <Form.Control
                type="text"
                placeholder="Enter property name"
                name="name"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="county">
            <FloatingLabel controlId="location" label="County">
              <Form.Select name="county">
                <option selected disabled>
                  Select County
                </option>
                {counties.map((county) => (
                  <option key={county.id} value={county.name}>
                    {county.county}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="area">
            <FloatingLabel controlId="area" label="Area">
              <Form.Control
                type="text"
                placeholder="Enter Area"
                name="location"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="latitude">
            <FloatingLabel controlId="latitude" label="Latitude">
              <Form.Control
                type="text"
                placeholder="Enter latitude"
                name="latitude"
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group className="mb-3" controlId="longitude">
            <FloatingLabel controlId="longitude" label="Longitude">
              <Form.Control
                type="text"
                placeholder="Enter Longitude"
                name="longitude"
              />
            </FloatingLabel>
          </Form.Group>



          <Form.Group className="mb-3" controlId="member">
            <FloatingLabel controlId="member" label="Member">
              <Form.Select name="member" onChange={handleMemberChange}>
                <option selected disabled>
                  Select contact Member
                </option>
                {members.map((member) => (
                  <option key={member.id} value={JSON.stringify(member)}>
                    {member.user.full_name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>





          <Button
            type="submit"
            sx={{ mt: 2 }}
            variant="contained"
            color="success"
          >
            Next
          </Button>
        </Form>
      )}

      {step === 1 && (
        <div>
          <Form
            onSubmit={handleSubmitAddUnit}
            style={{ width: "75%", margin: "0 auto" }}
          >
            <h2 className="mt-5">
              Kindly create the units in {addedApartments.name}
            </h2>
            <Button
              onClick={handleDone}
              sx={{ mt: 2, mb: 2 }}
              variant="contained"
              color="warning"
            >
              Done
            </Button>
            <Select
              closeMenuOnSelect={!isSelectOpen}
              components={animatedComponents}
              isMulti
              defaultValue={`Select Unit Type`}
              options={typeOfHouseChoices}
              value={selectedOptions}
              onChange={handleHouseTypeChange}
              onFocus={() => setIsSelectOpen(true)}
              onBlur={() => setIsSelectOpen(false)}
            />
            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="warning"

            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}

      {step === 2 && (
        <div>
          <h2 className="mt-5">
            Kindly add more details for the units created
          </h2>
          <div style={{ width: "75%", margin: "0 auto" }}>
            <Accordion
              defaultActiveKey={
                unitsAdded.length > 0 ? unitsAdded[0].id.toString() : null
              }
            >
              {unitsAdded.map((units) => (
                <Accordion.Item key={units.id} eventKey={units.id.toString()}>
                  <Accordion.Header>
                    Click this unit to add its details.
                    {units.bedroom && units.bedroom > 0
                      ? ` It is a ${units.bedroom} Bedroom(s).`
                      : ` It is a ${toCamelCase(units.type_of_house)}.`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form onSubmit={(e) => handleUpdate(e, units.id)}>
                      <div className="text-start mb-3">
                        <input type="checkbox"
                          onChange={(e) => setHasRange(e.target.checked)}
                        />
                        <span>Has Rent Range</span>
                      </div>
                      {hasRange ?
                        <Form.Group className="mb-3 d-flex justify-content-between" controlId="unitRent">
                          <FloatingLabel
                            controlId="max_rent"
                            label="Maximum rent of Unit"
                            style={{ width: "80vw", marginRight: "4rem" }}
                            className="d-flex justify-content-betwee"
                          >

                            <Form.Control
                              type="number"
                              placeholder="Enter maximum rent of unit"
                              name="max_rent"
                              value={formDataUnits[units.id]?.max_rent || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>

                          <FloatingLabel
                            controlId="min_rent"
                            label="Minimum rent of Unit"
                            style={{ width: "80vw" }}
                          >

                            <Form.Control
                              type="number"
                              placeholder="Enter minimum rent of unit"
                              name="min_rent"
                              value={formDataUnits[units.id]?.min_rent || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>

                        </Form.Group>

                        :
                        <Form.Group className="mb-3" controlId="unitRent">
                          <FloatingLabel
                            controlId="rent"
                            label="Rent of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter rent of unit"
                              name="rent"
                              value={formDataUnits[units.id]?.rent || null}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      }
                      <div className="text-start mb-3 mt-2">
                        <input type="checkbox"
                          onChange={(e) => setHasMonths(e.target.checked)}
                        />
                        <span>Deposit in months</span>
                      </div>

                      {hasMonths ?
                        <Form.Group className="mb-3" controlId="unitDeposit">
                          <FloatingLabel
                            controlId="deposit_description"
                            label="Deposit in months"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="deposit_description"
                              value={formDataUnits[units.id]?.deposit_description || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>

                        :
                        <Form.Group className="mb-3" controlId="unitDeposit">
                          <FloatingLabel
                            controlId="deposit"
                            label="Deposit of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="deposit"
                              value={formDataUnits[units.id]?.deposit || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      }



                      <Form.Group className="mb-3" controlId="unitBathroom">
                        <FloatingLabel
                          controlId="bathroom"
                          label="Bathroom of Unit"
                          style={{ width: "80vw" }}
                        >
                          <Form.Control
                            as="select"
                            value={formDataUnits[units.id]?.bathroom || "none"}
                            onChange={(e) => handleChange(e, units.id)}
                            name="bathroom"
                          >
                            <option value="none">None</option>
                            <option value="ensuite">Ensuite</option>
                            <option value="master">Master Ensuite</option>
                            {[...Array(8).keys()].map((number) => (
                              <option key={number + 1} value={number + 1}>
                                {number + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </FloatingLabel>
                      </Form.Group>

                      {units.bedroom && units.bedroom > 0 ? (
                        // If bedrooms are greater than 0, display the value but no input field
                        <Form.Group
                          className="mb-3 d-none"
                          controlId="unitBedroom"
                        >
                          <FloatingLabel
                            controlId="bedroom"
                            label="Bedroom of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="bedroom"
                              className="d-none"
                              value={units.bedroom}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        // If bedrooms are 0 or not specified, display the input field
                        <Form.Group className="mb-3" controlId="unitBedroom">
                          <FloatingLabel
                            controlId="bedroom"
                            label="Bedroom of Unit"
                            style={{ width: "80vw" }}
                          >
                            <Form.Control
                              type="number"
                              placeholder="Enter deposit of unit"
                              name="bedroom"
                              value={formDataUnits[units.id]?.bedroom || ""}
                              onChange={(e) => handleChange(e, units.id)}
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}

                      <div className="d-flex justify-content-between">
                        <Button type="submit">Update</Button>
                        <Button
                          type="button"
                          onClick={() => handleRemoveUnit(units.id)}
                        >
                          Remove Unit
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <Button
            onClick={handleBack}
            sx={{ mt: 2, mr: 2 }}
            variant="contained"
            color="warning"

          >
            Back
          </Button>
          <Button
            onClick={updateUnitsOnNext}
            type="submit"
            sx={{ mt: 2 }}
            variant="contained"
            color="success"
          >
            Next
          </Button>
        </div>
      )}

      {step === 3 && (
        <div>
          <Form onSubmit={handleSubmitImage}>
            <h2 className="mt-5">Upload Multiple Images (minimum is 5)</h2>

            <div {...getRootProps()} style={dropzoneStyles}>
              <input {...getInputProps()} />
              <p>Drag & drop some images here, or click to select files</p>
            </div>

            {selectedImages.length > 0 && (
              <div>
                <h4 className="mt-3">Selected Images:</h4>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selectedImages.map((file, index) => (
                    <div
                      key={index}
                      style={{ margin: "10px", position: "relative" }}
                    >
                      <Image
                        src={URL.createObjectURL(file)}
                        alt={`Image ${index + 1}`}
                        rounded
                        thumbnail
                        style={{
                          width: "250px",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="mt-1"
                        onClick={() => handleRemoveImage(index)}
                        style={removeButtonStyles}
                      >
                        <i className="bi bi-trash-fill text-white"></i>
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}

      {step === 4 && (
        <div>
          <Form onSubmit={handleSubmitDescription}>
            <h2 className="mt-5">Fill in the About Status</h2>

            <FloatingLabel
              controlId="About"
              label="About"
              style={{ width: "80vw", margin: "0 auto" }}
            >
              <Form.Control
                type="text"
                placeholder="Enter About"
                name="about"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                rows={4}
                style={{ width: "100%" }}
              />
            </FloatingLabel>

            <Button
              onClick={handleBack}
              sx={{ mt: 2, mr: 2 }}
              variant="contained"
              color="warning"
            >
              Back
            </Button>
            <Button
              type="submit"
              sx={{ mt: 2 }}
              variant="contained"
              color="success"
            >
              Next
            </Button>
          </Form>
        </div>
      )}
    </Box>
  );
};

export default MainStepper;
