import React, { useEffect, useState } from 'react';
import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";

function PermissionComponent({ setGroupedPermissions }) {
    const [userPermissions, setUserPermissions] = useState([]);
    const [grouped, setGrouped] = useState({});

    useEffect(() => {
        const accessToken = localStorage.getItem("authTokens");
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        };
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        serverUrl.get(`/users/list-user-groups/?user_id=${userId}`, config)
            .then((res) => {
                const userGroups = res.data;
                const promises = userGroups.map((group) => {
                    return serverUrl.get(`/users/list-group-permission/?group_id=${group.id}`, config);
                });
                Promise.all(promises)
                    .then((permissionsResponses) => {
                        const allPermissions = permissionsResponses.flatMap((res) => res.data);
                        const groupedPermissions = groupPermissions(allPermissions);
                        setUserPermissions(allPermissions);
                        setGrouped(groupedPermissions);
                        setGroupedPermissions(groupedPermissions);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const groupPermissions = (permissions) => {
        const grouped = {};

        permissions.forEach((permission) => {
            const topic = permission.codename.split('_')[1];
            if (!(topic in grouped)) {
                grouped[topic] = [];
            }
            grouped[topic].push(permission.name);
        });

        return grouped;
    };

    return (
        <div>
            <h2>Topics:</h2>
            {/* <ul>
                {Object.keys(grouped).map((topic, index) => (
                    <li key={index}>{topic}</li>
                ))}
            </ul> */}
        </div>
    );
}

export default PermissionComponent;
