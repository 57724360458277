import React, { useEffect, useState } from 'react'
import './AddProperty.css'
import { Form, Button, FormSelect } from "react-bootstrap";
import { toast } from 'react-toastify';
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function AddProperty() {
  const navigate = useNavigate()
  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    about: "",
  });

  // onchange functionality
  const MAX_DESCRIPTION_LENGTH = 1024;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'about' && value.length > MAX_DESCRIPTION_LENGTH) {
      // Truncate the input to the maximum allowed length
      const truncatedValue = value.substring(0, MAX_DESCRIPTION_LENGTH);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: truncatedValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const [counties, setCounties] = useState([])
  useEffect(() => {
    serverUrl.get(`/landlord/county`)
      .then((res) => {
        setCounties(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const [formDataCounty, setFormDataCounty] = useState({
    county: "",

  });
  const handleCounty = (name) => (event) => {
    const { value } = event.target;
    setFormDataCounty((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // when submitting the form it's functionallities
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can link with the Apartment from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // the data that will be posted to the api is as follows
    const data = {
      name: formData.name,
      location: formData.location,
      county: formDataCounty.county,
      about: formData.about
    };

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data
    try {
      serverUrl
        //send the Apartment details using the following endpoint
        .post("/landlord/apartment/", data, config)
        .then((res) => {
          // setting the Apartment details
          localStorage.setItem('Apartment', JSON.stringify(res.data.id))
          //  landlord details
          const landlord = localStorage.getItem('landlord');
          const Apartment = res.data.id

          const landlordApartment = {
            landlord: landlord,
            apartment: Apartment
          }

          serverUrl
            .post("/landlord/landlord-apartment/", landlordApartment, config)
            .then((res) => {
              console.log(res)
            })
          toast.success('Successfully Added Apartment!');
          navigate('/Apartment-image')

        })

        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            alert('Error while adding property' + error.message);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });
    } catch (error) {
      console.log(error);
    }

  }
  return (
    <div className="registerForm">
      <h2>Create Your Property</h2>

      <Form onSubmit={handleSubmit}>
        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="text"
            placeholder="Property Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <FormSelect
          className="formProperty input-icons CountyDiv"
          value={formDataCounty.county}
          onChange={handleCounty('county')}
        >
          <option disabled value="">
            Select County
          </option>
          {counties.map((county) => (
            <option
              key={county.name}
              value={county.name}
              className="input-field"

            >
              {county.county}
            </option>
          ))}
        </FormSelect>


        <div className="input-icons">
          <i className="fa fa-map-marker icon"></i>
          <input
            className="input-field"
            type="location"
            placeholder="Add Location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <textarea
            className="input-field"
            type="text"
            placeholder="About/ Description"
            name="about"
            value={formData.about}
            onChange={handleChange}

            style={{ height: "320px" }}
          />
        </div>
        
        <span className='charactersRemaining'>
          {MAX_DESCRIPTION_LENGTH - formData.about.length} characters remaining
        </span>

        <Button variant="primary" type="submit" className="nextBtn">
          Register
        </Button>
      </Form>

    </div>
  )
}

export default AddProperty