import React, { useState, useEffect } from 'react'
import Header from './Header'
import './Repairs.scss'
import SideBar from './SideBar'
import { Link, useParams } from 'react-router-dom';
import { Table, Button, Form, Card, Image } from 'react-bootstrap';
import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from "@react-pdf/renderer";

// images
import Apartment2 from '../../assets/img/apartment2.jpg'
import Apartment3 from '../../assets/img/apartment3.jpg'
import Apartment4 from '../../assets/img/apartment4.jpg'
import Apartment5 from '../../assets/img/apartment7.jpg'
import Apartment8 from '../../assets/img/apartment8.jpg'
import Apartment9 from '../../assets/img/apartment9.jpg'


function Repairs() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }


    // declaration of variables
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [landlordApartment, setLandlordApartment] = useState([])
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});
    const [houseCount, setHouseCount] = useState({});
    const [selectedApartmentRepairsTotal, setSelectedApartmentRepairsTotal] = useState({});
    const apartmentRepairTotal = {};


    // get the user logged in
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};

    // get the apartments and the details needed within the apartment

    useEffect(() => {
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);
                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });

                        // set count of the number of houses that are in that apartment
                        Promise.all(houseCountPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseCountObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseCountObj[apartmentId] = count;
                                });
                                setHouseCount(houseCountObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // get the images of the apartments based on the apartment id 
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    res.data.results.forEach((res) => {
                                        apartmentImage[res.apartment] = res.image;
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                        setSelectedApartmentImage(apartmentImage)

                        //get the total repairs of the houses in each apartment
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/react-repairs-total/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    apartmentRepairTotal[apartment.id] = res.data.total_repairs_amount;
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                        setSelectedApartmentRepairsTotal(apartmentRepairTotal)
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);



    //pdf stylings 
    // const { url } = pdf;

    const styles = StyleSheet.create({
        page: {
            flexDirection: "column",
            backgroundColor: "#E4E4E4",
            padding: 10,
        },
        section: {
            flexDirection: "row",
            alignItems: "center",
            margin: 10,
            padding: 10,
            borderBottom: "1 solid #000",
        },
        header: {
            backgroundColor: "#BFBFBF",
        },
        cell: {
            flexGrow: 1,
            textAlign: "center",
        },
    });

    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };
        
            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
              const apartmentId = unitDetail.id;
              if (!updatedImages[apartmentId]) {
                updatedImages[apartmentId] = getRandomImage();
              }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
          };
        
          updateBackgroundImages();
    }, [landlordApartment]);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 11) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
              randomNumber = Math.floor(Math.random() * 11) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/repairs/repairs${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='repairs'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>PROPERTIES REPAIRS</p>

                </div>
                {/* filtred calendar */}
                <div className='filterCalendar d-none'>
                    <div className='input-group'>
                        <div className='input-group-prepend'>
                            <span className='input-group-text'>
                                <i className="bi bi-calendar" />
                            </span>
                        </div>
                        <Form.Select size='sm' className='formCal d-none'>
                            <option>April</option>
                            <option>Date Range</option>
                            <option>Monthly</option>
                            <option>Yearly</option>
                        </Form.Select>
                    </div>
                </div>
                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {landlordApartment.map((apartmentDetail, index) =>
                        <Card className='house'>
                            <Link to={`/selected-apartment-repairs/${apartmentDetail.id}`} className='text-decoration-none'>
                            <Card.Body>
                                <div className="image-container">
                                    {selectedApartmentImage[apartmentDetail.id] && selectedApartmentImage[apartmentDetail.id] ?
                                        <Image src={selectedApartmentImage[apartmentDetail.id]} style={{ borderRadius: "0.5em" }} className="" alt={index}></Image> :
                                        <Image src={backgroundImageApartment[apartmentDetail.id]} style={{ borderRadius: "0.5em" }} className=""></Image>
                                    }
                                    <div className="badge">{houseCount[apartmentDetail.id]} Units</div>
                                    <p className='text-muted float-left'>{apartmentDetail.location}</p><br></br>

                                </div>
                                <p className='houseName'>{apartmentDetail.name}</p>
                                <p className='houseName'>Total Repairs: Ksh.{selectedApartmentRepairsTotal[apartmentDetail.id]?.toLocaleString() || 0}</p>
                                <div className='buttonsProperties'>
                                    <Link to={`/selected-apartment-repairs/${apartmentDetail.id}`} className='text-danger text-decoration-none'>View Repairs <i className='bi bi-arrow-right'></i></Link>


                                </div>
                            </Card.Body>

                            </Link>
                           
                        </Card>)}



                </div>

            </div>
        </>
    )
}

export default Repairs