import React, { useEffect, useState } from 'react'
import Header from '../Header';
import SideBar from '../SideBar';
import './SelectedApartmentReport.scss'
import { Link, useParams } from 'react-router-dom';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import OccupancyReport from './OccupancyReport';
import RentPaymentReport from './RentPaymentReport';
import IncomeReport from './IncomeReport';
import OwnersReport from './OwnersReport';
import UtilitiesReport from './UtilitiesReport';
import ApartmentImage from '../../../assets/img/apartment1.jpg'
import ReactApexChart from 'react-apexcharts';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import FailedReconciliation from './FailedReconciliation';
import OverflowReport from './OverflowReport';

function SelectedApartmentReport() {

  // getting the id and finding the apparment details
  const { id } = useParams();
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // selected Apartment
  const [apartmentSelected, setApartmentSelected] = useState([])


  // get the user who is logged in and the date related to them
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  useEffect(() => {
    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);

        // using the landlord id get the apartments linked to the landlord
        landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => {
              const selectedApartmentObj = res.data.results.find(utilitiesApartment => utilitiesApartment.id === parseInt(id));
              setApartmentSelected(selectedApartmentObj)
            })
            .catch((error) => {
              console.log(error);
            });

        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])


  // randomise for each apartment

  // charts for the reports
  // chart occupancy
  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'occupancy-chart',
      type: 'line',
      stacked: true,
      zoom: {
        enabled: false
      },
      animations: {
        enabled: true
      }
    },

    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],

    },

    tooltip: {
      enabled: true
    },

    grid: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#3498db'],
    },

  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Occupancy',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],


    },
  ]);

  // chart incomeReport
  const [chartOptionsIncome, setChartOptionsIncome] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true, // Enable tooltip
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false, // Set fixed property to false
      },

    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#8e44ad'],
    },

  });

  const [chartSeriesIncome, setChartSeriesIncome] = useState([
    {
      name: 'Income Report',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],

    },
  ]);

  // chart payment report
  const [chartOptionsPayment, setChartOptionsPayment] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: "2",
      // colors: ['#8e44ad', '#e74c3c', '#3498db'],
    },
  });

  const [chartSeriesPayment, setChartSeriesPayment] = useState([
    {
      name: 'Fully Paid',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],
    },
    {
      name: 'Partially Paid',
      data: [35, 45, 60, 75, 45, 50, 30, 15, 5, 35, 65, 80],
    },
    {
      name: 'Not Paid',
      data: [80, 75, 60, 45, 70, 65, 80, 90, 95, 70, 50, 30],
    },
  ]);

  // chart UtilitiesReport
  const [chartOptionsUtilities, setChartOptionsUtilities] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true, // Enable tooltip
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false, // Set fixed property to false
      },

    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#f39c12'],
    },

  });

  const [chartSeriesUtilities, setChartSeriesUtilities] = useState([
    {
      name: 'Utilities Report',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],

    },
  ]);

  // chart failled reconciliations
  const [chartOptionsFailed, setChartOptionsFailed] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true, // Enable tooltip
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false, // Set fixed property to false
      },

    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#1abc9c'],
    },

  });

  const [chartSeriesFailed, setChartSeriesFailed] = useState([
    {
      name: 'Failed Transactions',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],

    },
  ]);

  // chart overpayment
  const [chartOptionsOverpayment, setChartOptionsOverpayment] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true, // Enable tooltip
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false, // Set fixed property to false
      },

    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#2ecc71'],
    },

  });

  const [chartSeriesOverpayment, setChartSeriesOverpayment] = useState([
    {
      name: 'Failed Transactions',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],

    },
  ]);

  // administars
  const [chartOptionsAdmin, setChartOptionsAdmin] = useState({
    chart: {
      id: 'occupancy-chart',
      zoom: {
        enabled: false
      },
    },
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ],
    },
    tooltip: {
      enabled: true, // Enable tooltip
      style: {
        fontSize: '12px',
      },
      fixed: {
        enabled: false, // Set fixed property to false
      },

    },
    stroke: {
      curve: 'smooth',
      width: "2",
      colors: ['#ff6600'],
    },

  });

  const [chartSeriesAdmin, setChartSeriesAdmin] = useState([
    {
      name: 'Administators',
      data: [65, 59, 80, 81, 56, 55, 40, 20, 10, 45, 75, 90],

    },
  ]);

  const chartOptionsIssues = {
    chart: {
      id: 'issues-chart',
      toolbar: {
        show: false,
      },
    },
    labels: ['Complaints', 'Issues', 'Suggestions'],
    // Specify colors for each slice of the pie chart
    colors: ['#FF5733', '#FFCB33', '#33FF33'],
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
  };

  const chartSeriesIssues = [65, 59, 80];




  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='report_Apartment'>
        {/* top display of the report */}
        <div className='image_Apartment_report'>
          <img src={ApartmentImage} alt={apartmentSelected.name} />
          <p>{apartmentSelected.name} Report</p>
          <span>{apartmentSelected.location}</span>
        </div>

        {/* filtering based on the month or year  */}

        {/* the cards to be displayed for the reports */}
        <Row style={{ marginTop: "10rem" }}>
          {/* <Col md={3}>
      <div>
        <Card className='ReportCards occupancyCard'>
          <Card.Body>
            <h3>Occupancy Reports</h3>
            <div className='d-flex justify-content-between'>
            <p>92/104</p>
            <p>92% occupied</p>
            </div>
            <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={300} />
          
            <Button>View detailed report</Button>
          </Card.Body>
        </Card>
      </div>
    </Col> */}

          <Col md={4}>
            <div>
              <Card className='ReportCards occupancyCard'>
                <Card.Body>
                  <h3>Occupancy Reports</h3>
                  <div className='d-flex justify-content-between'>
                    <p>92/104</p>
                    <p>92% occupied<span className='ms-1' style={{ fontSize: '0.8rem', color: '#2ecc71' }}> +8%</span></p>
                  </div>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={250} />

                  <Button >
                    <Link to={`/occupancy_report/${id}`} className='text-decoration-none text-white'>
                      View detailed report
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={4}>
            <div>
              <Card className='ReportCards incomeCard'>
                <Card.Body>
                  <h3>Income Report</h3>
                  <div className='d-flex justify-content-between'>
                    <p>Ksh.97,900 /Ksh.1,004,201</p>
                    <p>50% paid</p>
                  </div>
                  <ReactApexChart options={chartOptionsIncome} series={chartSeriesIncome} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={4}>
            <div>
              <Card className='ReportCards paymentCard'>
                <Card.Body>
                  <h3>Payment Reports</h3>
                  <div className='paymentDetailsReport'>
                    <p>Fully paid:20</p>
                    <p>Partially paid:10</p>
                    <p>Unpaid:65</p>
                  </div>
                  <ReactApexChart options={chartOptionsPayment} series={chartSeriesPayment} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>



        </Row>


        <Row style={{ marginTop: "1rem" }}>
          <Col md={4}>
            <div>
              <Card className='ReportCards utilitiesCard'>
                <Card.Body>
                  <h3>Utilities Income</h3>
                  <div className='d-flex justify-content-between'>
                    <p>Ksh.97,900 /Ksh.1,004,201</p>
                    <p>50% paid</p>
                  </div>
                  <ReactApexChart options={chartOptionsUtilities} series={chartSeriesUtilities} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={4}>
            <div>
              <Card className='ReportCards failedCard'>
                <Card.Body>
                  <h3>Failed Reconciliation</h3>
                  <div className='d-flex justify-content-between'>
                    <p>30 transactions failed</p>
                    <p>Value : Ksh 70,000</p>
                  </div>
                  <ReactApexChart options={chartOptionsFailed} series={chartSeriesFailed} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={4}>
            <div>
              <Card className='ReportCards overpaymentCard'>
                <Card.Body>
                  <h3>Overpayment Report</h3>
                  <div className='d-flex justify-content-between'>
                    <p>30 transactions</p>
                    <p>Value : Ksh 70,000</p>
                  </div>
                  <ReactApexChart options={chartOptionsOverpayment} series={chartSeriesOverpayment} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

        </Row>

        <Row>

          <Col md={4}>
            <div>
              <Card className='ReportCards systemCard'>
                <Card.Body>
                  <h3>System Administrators</h3>
                  <p>3 Administrators</p>
                  <ReactApexChart options={chartOptionsAdmin} series={chartSeriesAdmin} type="line" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={4}>
            <div>
              <Card className='ReportCards issuesCard'>
                <Card.Body>
                  <h3>Tenants Feedback</h3>
                  <p>1,213 reported</p>
                  <ReactApexChart options={chartOptionsIssues} series={chartSeriesIssues} type="pie" height={250} />

                  <Button>View detailed report</Button>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>

        {/* list of the tenants of the apartment and payment details */}

        {/* occupancy report */}
        {/* <OccupancyReport Apartmentid={id}/>
                <IncomeReport Apartmentid={id}/>
                <RentPaymentReport Apartmentid={id} />
                <OwnersReport Apartmentid={id}/>
                <UtilitiesReport Apartmentid={id}/>
                <FailedReconciliation Apartmentid={id}/>
                <OverflowReport Apartmentid={id}/> */}



        {/* top cards that will have the report on the apartments */}


      </div>
    </>
  )
}

export default SelectedApartmentReport