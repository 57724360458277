import React, { useState, useEffect } from 'react'
import './IncomeReport.scss'
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl, Image, Modal, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";

function IncomeReport(Apartmentid) {
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // getting the id and finding the apparment details
  const { id } = useParams();


  // get the default tenants
  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  // Get the current month automatically
  const currentDate = new Date();
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // get the months till today
  const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
    const month = new Date(currentDate.getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;

  const years = Array.from({ length: 21 }, (_, i) => startYear - i);


  // display 12 churches per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);

  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };

  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);

  // for the year filtered then get the data as whole
  const [isLoading, setIsLoading] = useState(false);

  // houses within the apartment
  const [showModal, setShowModal] = useState(false)
  const [selectedHouses, setSelectedHouses] = useState([])
  const [incomeCount, setIncomeCount] = useState('');
  const [taxCount, setTaxPayable] = useState('');
  const [houseCount, setHouseCount] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [totalIncomeYear, setTotalIncome] = useState("")
  const [totalTaxIncomeYear, setTotalTaxIncome] = useState("")

  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("")
  const [yearFilterActive, setYearFilterActive] = useState("")

  const handleCloseModal = () => {

    setShowModal(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    if (yearFilterActive) {
      localStorage.removeItem('yearIncome');
    }
    localStorage.setItem("monthIncome", month);
    setShowModal(false);
    window.location.reload();

  };

  const handleYearButtonClick = (year) => {
    setSelectedYear(year);
    if (monthFilterActive) {
      localStorage.removeItem('monthIncome');
    }

    localStorage.setItem("yearIncome", year);
    setShowModal(false);
    window.location.reload();

  };
  const [yearMonthlyData, setYearMonthlyData] = useState([])
  // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
  useEffect(() => {

    // get if the filter is set
    const setMonthFilter = localStorage.getItem("monthIncome")
    setMonthFilterActive(setMonthFilter)

    // get if the filter is set for year
    const setYearFilter = localStorage.getItem("yearIncome")
    setYearFilterActive(setYearFilter)

    // check the filtered data
    let monthSelect = "";
    let yearSelect = "";

    if (setMonthFilter) {
      monthSelect = setMonthFilter
    }
    else {
      monthSelect = currentMonth
    }

    // check the filter if the year is filtered
    if (setYearFilter) {
      yearSelect = setYearFilter;
      const monthlyData = [];
      const TotalIncomeYearly = []
      const TotalTaxIncomeYearly = []

      Promise.all(
        months.map((month) =>
          serverUrl
            .get(`/tenant/generate_month_income_report/?apartment_id=${Apartmentid.Apartmentid}&month=${month}&year=${yearSelect}`, config)
            .then((res) => {
              const monthData = {
                month: month,
                income: res.data.total,
                taxPayable: res.data.tax_payable,
                selectedHouses: res.data.incomeList,
              };
              const houseTotalAmount = res.data.total
              const selectedHouses = res.data.incomeList;
              const houseTaxTotalAmount = res.data.tax_payable;


              TotalIncomeYearly.push(houseTotalAmount)
              TotalTaxIncomeYearly.push(houseTaxTotalAmount)
              monthlyData.push(selectedHouses);

            })
            .catch((error) => {
              console.log(error);
            })
        )
      )
        .then(() => {
          // All API calls have resolved, combine the arrays and calculate the total income
          const combinedData = [].concat.apply([], monthlyData); // Combine the arrays
          // Combine the arrays for the total amount of income in that year
          const combinedDataIncome = [].concat.apply([], TotalIncomeYearly);

          // Filter and extract the income obtained  from the array
          const incomeYear = combinedDataIncome.map(entry => parseInt(entry.replace(/[^0-9]/g, '')));

          // Calculate the sum of the income in total
          const totalIncome = incomeYear.reduce((acc, value) => acc + value, 0);

          //get the tax in the year filtered
          const combinedDataIncomeTax = [].concat.apply([], TotalTaxIncomeYearly);

          const taxYear = combinedDataIncomeTax.map(entry => parseInt(entry.replace(/[^0-9]/g, '')));

          const totalIncomeTax = taxYear.reduce((acc, value) => acc + value, 0);


          // Update the state with the combined data and total income
          setYearMonthlyData(combinedData);
          setTotalIncome(totalIncome);
          setTotalTaxIncome(totalIncomeTax)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      yearSelect = currentYear;
    }



    // get the total income of the apartment
    serverUrl.get(`/tenant/generate_month_income_report/?apartment_id=${Apartmentid.Apartmentid}&month=${monthSelect}&year=${yearSelect}`, config)
      .then((res) => {
        setIncomeCount(res.data.total)
        setTaxPayable(res.data.tax_payable)
        setSelectedHouses(res.data.incomeList)
      })
      .catch((error) => {
        console.log(error);

      })

  }, []);

  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };


  const sortedData = yearFilterActive ? yearMonthlyData : selectedHouses.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const exportCSV = () => {
    const csvData = [
        ['Name', 'House', 'Category', 'Rent Amount','Units'],
        ...selectedHouses.map((item) => [item.tenant_name, item.tenant_house, 'Rent Income', item.tenant_amount, item.unit]),
    ];

    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'rent_income_report.csv');
};

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('monthIncome');
    localStorage.removeItem('yearIncome');
    toast.success('Successfully Reset The Income Report Filter!');
    window.location.reload()
  };

   function handleDownload() {

      setIsLoading(true);
        // we will have to loop through all the months but with the selected year
            serverUrl.get(`/tenant/generate_annual_income_report/?apartment_id=${Apartmentid.Apartmentid}&year=${currentYear}&income_category=rent&download=true`, config)
              .then((res) => {
                // create a download url for the pdf and then automatically download the pdf
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'rent_income_report.pdf');
                document.body.appendChild(link);
                link.click();
                toast.success('Successfully Downloaded Your Rent Income Report');
                setIsLoading(false);

              })
              .catch((error) => {
                console.log(error);
              })


  }
 // if the data is loading show a message
 if (isLoading) {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

  return (
    <>

      <div className='incomeReport'>


        {/* list of the tenants of the apartment and payment details */}
        <div className='tasks ' >
          <h2>rent income</h2>

          <div className='d-flex justify-content-between'>
            <Form.Select size='sm' className='formCal' onChange={handleSelectChange} style={{ width: "20%" }}>
              <option selected disabled>Filter Report By:</option>
              <option>Monthly</option>
              <option>Yearly</option>
            </Form.Select>

            {monthFilterActive ?
              <p className='filterText'>Rent Income Report For {monthFilterActive} </p>
              : yearFilterActive ?
                <p className='filterText'>Rent Income Report For {yearFilterActive}</p>
                :
                <p className='filterText' style={{marginRight:"31rem"}}>Rent Income Report For {currentMonth}</p>

            }

            {monthFilterActive || yearFilterActive ?
              <Button className='text-white filterButton' onClick={handleReset}>Reset Filter</Button>

              :
              <></>}
          </div>

          {/* modal to be displayed */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='bg-primary text-white' closeButton>
              <Modal.Title>Filter By {selectedOption}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedOption === currentMonth ?
                (
                  <>
                    <p>{currentMonth}</p>

                  </>
                )
                : selectedOption === "Monthly" ?
                  (
                    <>
                      <Form>
                        <Row className="flex-wrap justify-content-between">
                          {months.map((month) => (
                            <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                              <Button className='btn btn-success text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                                {month}
                              </Button>
                            </Col>
                          ))}
                        </Row>

                      </Form>
                    </>

                  )
                  : selectedOption === "Yearly" ?
                    (
                      <>
                        <Form>
                          <Row className="flex-wrap justify-content-between">
                            {displayedYears.map((year) => (
                              <Col key={year} xs={6} sm={4} md={4} className="mt-3">
                                <Button className='btn btn-success text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleYearButtonClick(year)}>
                                  {year}
                                </Button>
                              </Col>
                            ))}
                          </Row>
                          <div className="mt-3 d-flex justify-content-center">
                            {currentYearPage > 0 && (
                              <Button variant="outline-primary" onClick={handlePrevYearPage}>
                                Previous
                              </Button>
                            )}
                            {currentYearPage < totalPagesYear - 1 && (
                              <Button variant="outline-primary" onClick={handleNextYearPage}>
                                Next
                              </Button>
                            )}
                          </div>
                        </Form>
                      </>
                    ) :
                    (
                      <>
                        <p>Date</p>
                      </>
                    )
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='d-flex justify-content-between'>

            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              <Button className='btn btn-danger text-white me-3' onClick={handleDownload} >PDF <i className='bi bi-file-pdf-fill'></i></Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>

          <Table className='table'>
            <thead className='underline'>

              {yearFilterActive ?
                <>
                  <th>Tenant Image</th>
                  <th onClick={() => handleSort("tenant_name")}>Name<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_house")}>House<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("type_of_house")}>Category<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_amount")}>Rent Amount<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("unit")}>Units<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_date_paid")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
                  <th>Month Paid</th>

                </>
                :
                <>
                  <th>Tenant Image</th>
                  <th onClick={() => handleSort("tenant_name")}>Name<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_house")}>House<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("type_of_house")}>Category<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_amount")}>Rent Amount<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("unit")}>Units<i className='bi bi-chevron-expand'></i></th>
                  <th onClick={() => handleSort("tenant_date_paid")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
                </>
              }



            </thead>
            <tbody>
              {displayedItems.map((occupancy_report, index) =>
                yearFilterActive ?
                  <tr>

                    <td style={{ width: "15%" }}>

                      <Image src={occupancy_report.tenant_image} style={{ width: "20%", borderRadius: "50%" }} />

                    </td>

                    <td>{occupancy_report.tenant_name}</td>

                    <td>{occupancy_report.tenant_house}</td>

                    <td>Rent Income</td>
                    <td>{occupancy_report.tenant_amount}</td>
                    <td>{occupancy_report.unit}</td>
                    <td>{occupancy_report.tenant_date_paid}</td>
                    <td>{new Date(occupancy_report.tenant_date_paid).toLocaleString('default', { month: 'long' })}</td>


                  </tr>
                  :

                  <tr>

                    <td style={{ width: "15%" }}>

                      <Image src={occupancy_report.tenant_image} style={{ width: "20%", borderRadius: "50%" }} />

                    </td>

                    <td>{occupancy_report.tenant_name}</td>

                    <td>{occupancy_report.tenant_house}</td>

                    <td>Rent Income</td>
                    <td>{occupancy_report.tenant_amount}</td>
                    <td>{occupancy_report.unit}</td>
                    <td>{occupancy_report.tenant_date_paid}</td>
                  </tr>

              )}

            </tbody>

          </Table>
          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            {yearFilterActive? 
            <span>{`${startIndex + 1}-${endIndex} of ${yearMonthlyData.length}`}</span>
            :
            <span>{`${startIndex + 1}-${endIndex} of ${selectedHouses.length}`}</span>

            }
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>

          </div>
          {/* get the total amount based on if the year is filtered or not  */}
          {yearFilterActive ?
            <>
              <h2 className='text-center text-primary'>Total amount :KES. {totalIncomeYear}</h2>
              {/* <p className='text-center bg-warning text-white text-uppercase'>Tax Payable : KES. {totalTaxIncomeYear}</p> */}

            </>

            :
            <>
              <h2 className='text-center text-primary'>Total amount :KES. {incomeCount}</h2>
              {/* <p className='text-center bg-warning text-white text-uppercase'>Tax Payable :KES. {taxCount}</p> */}

            </>
          }

        </div>

      </div>
    </>
  )
}

export default IncomeReport