import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Property.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'


function FeaturedProperties() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // counted house
    const [houseCount, setHouseCount] = useState({});

    // apartments of the landlord
    const [landlordApartment, setLandlordApartment] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};

    const [apartmentAll, setApartmentAll] = useState([])

    // from the localstorage get the logged in user
    useEffect(() => {
        setIsLoading(true)
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // get the apartments all
                serverUrl.get(`/landlord/alliance-listing-properties?landlord_id=${landlordIds}`, config)
                    .then((res) => {
                        // Filter the results where is_featured is true
                        const filteredApartments = res.data.results.filter(apartment => apartment.is_featured === false);
                        setApartmentAll(filteredApartments)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments.filter(item => item.is_featured === true));

                        // get the images of the apartments based on the apartment id 
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    res.data.results.forEach((res) => {
                                        apartmentImage[res.apartment] = res.image;
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                        setSelectedApartmentImage(apartmentImage)
                        setIsLoading(false)

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)

                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)

            });

    }, []);



    // pasing the apartment Details to the modal
    const handleOpenModal = apartmentDetail => {
        setSelectedApartment(apartmentDetail);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // delete apartment
    const handleDeleteApartment = () => {
        // the passed id from the modal
        const id = selectedApartment.id;

        const data = {
            is_featured: false,
        }
        serverUrl.patch(`/landlord/apartment/${id}/`, data, config)
            .then((res) => {
                toast.success('Succesfully removed the Property as featured properties!')
                setShowModal(false);
                // Filter the LandlordApartment state to remove the item with the updated ID
                const updatedLandlordApartments = landlordApartment.filter(apartment => apartment.id !== id);
                setLandlordApartment(updatedLandlordApartments);
                // get the apartments all immediately
                serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
                    .then((res) => {
                        // get the landlord Id
                        const landlordIds = res.data.results.map((landlord) => landlord.id);
                        // get the apartments all
                        serverUrl.get(`/landlord/alliance-listing-properties?landlord_id=${landlordIds}`, config)
                        .then((res) => {
                                // Filter the results where is_featured is true
                                const filteredApartments = res.data.results.filter(apartment => apartment.is_featured === false);

                                // Set the filtered results in state
                                setApartmentAll(filteredApartments);
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        // using the landlord id get the apartments linked to the landlord
                        const landlordApartmentPromises = landlordIds.map((landlordId) => {
                            return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                                .then((res) => res.data.results)
                                .catch((error) => {
                                    console.log(error);
                                    return [];
                                });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)

                    });

            })
            .catch((error) => {
                console.log(error)
            })

    };


    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((county) => {
        if (!uniquePropertyLocation.has(county.county)) {
            uniquePropertyLocation.add(county.county);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.county !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    // add the featured properties
    const [modalFeatured, setShowModalFeatured] = useState(false)

    const handleOpenFeatured = () => {
        setShowModalFeatured(true);
    };

    const handleCloseFeatured = () => {
        setShowModalFeatured(false);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // function to add the property to featured
    const [selectedApartmentId, setSelectedApartmentId] = useState('')

    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
    }
    function AddFeaturedProperty(e) {
        e.preventDefault();

        // update the status is featured to true
        const data = {
            is_featured: true,
        }

        serverUrl.patch(`/landlord/apartment/${selectedApartmentId}/`, data, config)
            .then((res) => {
                toast.success('Successfully added the Property as featured properties!');
                setShowModalFeatured(false);

                // Add the newly featured property to the state
                setLandlordApartment((prevProperties) => [...prevProperties, res.data]);

                // get the apartments all immediately
                serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
                    .then((res) => {
                        // get the landlord Id
                        const landlordIds = res.data.results.map((landlord) => landlord.id);
                        // get the apartments all
                        serverUrl.get(`/landlord/alliance-listing-properties?landlord_id=${landlordIds}`, config)
                        .then((res) => {
                                // Filter the results where is_featured is true
                                const filteredApartments = res.data.results.filter(apartment => apartment.is_featured === false);

                                // Set the filtered results in state
                                setApartmentAll(filteredApartments);
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                        // using the landlord id get the apartments linked to the landlord
                        const landlordApartmentPromises = landlordIds.map((landlordId) => {
                            return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                                .then((res) => res.data.results)
                                .catch((error) => {
                                    console.log(error);
                                    return [];
                                });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)

                    });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>OUR FEATURED PROPERTIES</p>

                </div>


                <Card className='cardProperty'>

                    <Form className="searchProperty">
                        <FormControl type="text" placeholder="Search Property" className="mr-sm-2 rounded-4" onChange={handleSearch} />
                    </Form>

                    <Form.Select size='sm' className='formProperty' value={selectedPropertyLocation} onChange={(e) => setPropertyLocation((e.target.value))} style={{ marginRight: "34rem", width: "40%" }}>
                        <option disabled selected>By Location</option>
                        <option value="">All Properties</option>
                        {uniqueLocation.map((apartment) => (
                            <option value={apartment.county}>{apartment.county}</option>
                        ))}

                    </Form.Select>
                    <Form.Select size='sm' className='formProperty d-none'>
                        <option disabled selected>Property Type</option>
                        <option value="">All Houses</option>
                        {landlordApartment.map((apartment) => (
                            <option>{apartment.id}</option>
                        ))}
                    </Form.Select>

                </Card>

                <div>
                    <Button className='propertyButton' onClick={handleOpenFeatured}>
                        Add Featured Property
                    </Button>
                </div>
                {/* modal for featured property */}
                <Modal show={modalFeatured} onHide={handleCloseFeatured}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Featured Property</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={AddFeaturedProperty}>
                            <Form.Select
                                size='sm'
                                className='formProperty'
                                onChange={(e) => handleApartmentSelect(e.target.value)}
                            >
                                <option value="">Select Apartment</option>
                                {apartmentAll.map((landlord) => (
                                    <option key={landlord.id} value={landlord.id}>
                                        {landlord.apartment_name}
                                    </option>
                                ))}
                            </Form.Select>

                            <Button className='loginBtn mt-3 ms-4' type='submit'>Add</Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFeatured}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>

                    {displayedItems.map((apartmentDetail, index) =>

                        <Card key={index.id} className='house'>
                            <Card.Body>
                                <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className='text-danger text-decoration-none'>
                                    <div className="image-container">
                                        {selectedApartmentImage[apartmentDetail.id] && selectedApartmentImage[apartmentDetail.id] ?
                                            <Image src={selectedApartmentImage[apartmentDetail.id]} className="property-grid" alt={index}></Image> :
                                            <Image
                                                src={backgroundImageApartment[apartmentDetail.id]}
                                                className="property-grid"
                                            />
                                        }

                                        <div className="badge">{apartmentDetail.num_houses} Units</div>

                                        <p className='text-muted float-left'>{apartmentDetail.location}</p>
                                        <br></br>
                                    </div>
                                    <p className='houseName' style={{ clear: "left" }}>{apartmentDetail.name}</p>
                                    <p></p>

                                </Link>
                                <div className='buttonsProperties'>
                                    <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className='text-danger text-decoration-none'>
                                        View Property <i className='bi bi-arrow-right'></i>
                                    </Link>
                                    <button className="icon-button btn btn-danger bg-danger text-white" onClick={() => handleOpenModal(apartmentDetail)}>
                                        <i className='bi bi-trash'></i>
                                    </button>
                                    <Modal show={showModal} onHide={handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Remove Property</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Do you want to remove the property from featured properties?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="success" onClick={handleCloseModal}>
                                                No
                                            </Button>
                                            <Button apartmentId={apartmentDetail.id} variant="danger" onClick={handleDeleteApartment}>
                                                Yes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>


                            </Card.Body>
                        </Card>
                    )}


                </div>
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginLeft: "14rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}


            </div>

        </div>
    )
}

export default FeaturedProperties




