import React, { useEffect, useState } from 'react'
import './AddTenant.scss'
import { Form, Button, Table, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';


// auth imports
import { Link, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function AddTenant() {
  const { id, housetenant } = useParams();
  // loading status
  const [isLoading, setIsLoading] = useState(true)
  const [tenantLength, setTenantLength] = useState(0)
  const [tenants, setTenant] = useState({})
  const [tenantsTable, setTenantTable] = useState()
  const [showModal, setShowModal] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const navigate = useNavigate();


  // get the tenants of that apartment
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  const TenantsApartment = {}
  useEffect(() => {
    setIsLoading(true);
    serverUrl.get(`/tenant/pending-tenant/?apartment_id=${id}`, config)
      .then((res) => {
        setTenantLength(res.data.count);
        setTenantTable(res.data.results);

        Promise.all(res.data.results.map((Tenant) =>
          serverUrl.get(`/users/users-details/?id=${Tenant.user}`, config)
            .then((response) => {
              const tenantData = {
                id: Tenant.id,
                fullName: response.data.results[0].full_name,
                phoneNumber: response.data.results[0].phone_number,
                user: Tenant.user,
              };

              setTenant((prevTenant) => ({
                ...prevTenant,
                [response.data.results[0].id]: tenantData,
              }));
            })
        )).then(() => {
          setIsLoading(false);
        });

      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  // add tenant modal 
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // form data for tenant details
  const [formDataTenant, setFormDataTenant] = useState({
    full_name: "",
    phone_number: ""
  });

  // done button reloading the page
  const handleCloseModalReload = () => {
    setShowModal(false);
    window.location.reload()
  };
  // counting the number of digits inserted on the phone number
  const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length;


  // onchange functionality on adding tenant
  const handleChangeTenant = (event) => {
    const { name, value } = event.target;
    // limit phone number input to 10 digits
    if (name === "phone_number") {
      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }
  // add the tenant to  database in the pending table 
  function handleSubmitTenanat(e) {
    e.preventDefault();
    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    // the data that will be posted to the api is as follows
    const dataTenant = {
      email: `${formDataTenant.phone_number}@gmail.com`,
      full_name: formDataTenant.full_name,
      phone_number: formDataTenant.phone_number,
      password: formDataTenant.phone_number,

    };
    try {
      serverUrl

        //send the tenant details using the following endpoint
        .post(`/users/register/`, dataTenant)
        .then((res) => {
          const pendingData = {
            user: res.data.data.id,
            apartment: id
          }
          serverUrl.post(`/tenant/pending-tenant/`, pendingData, config)
            .then((res) => {
              toast.success("Tenant Successfully added!");

              // Reset more form fields here
              setFormDataTenant({
                full_name: "",
                phone_number: ""

              });
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        })
        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.data.error);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }

  }

  // passsing the id of the tenant to the modal
  const [showModalSaveTenant, setShowModalSaveTenant] = useState(false);

  const handleOpenSaveTenant = tenant => {
    setSelectedTenant(tenant);
    setShowModalSaveTenant(true);
  };
  const handleCloseModalSaveTenant = () => {
    setShowModalSaveTenant(false);
  };
  const handleCloseModalReloadTenant = () => {
    setShowModalSaveTenant(false);
    window.location.reload()
  };

  // add the tenant to database as a tenant

  const handleAddTenanat = () => {

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const idTenant = selectedTenant.user

    const data = {
      user: idTenant
    }
    try {
      serverUrl
        //send the tenant details using the following endpoint
        .post(`/tenant/tenant/`, data, config)
        .then((res) => {
          console.log(res.data)
          const tenantHouse = {
            status: "active",
            tenant: res.data.id,
            house: housetenant,
          }
          console.log(tenantHouse)
          serverUrl.post(`/tenant/occupancy/`, tenantHouse, config)
          toast.success("Tenant Sucessfully Added to House.")
          navigate(`/selected-unit/${housetenant}`)
        })
        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.data.error);
          } else {
            toast.error('Unexpected error')
            console.log(error);
          }
        });

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }


  };


  // delete tenant from our database
  const [showModalDeleteTenant, setShowModalDeleteTenant] = useState(false);

  const handleOpenDeleteTenant = tenant => {
    setSelectedTenant(tenant);
    setShowModalDeleteTenant(true);
  };
  const handleCloseModalDeleteTenant = () => {
    setShowModalDeleteTenant(false);
  };
  const handleCloseModalReloadTenantDelete = () => {
    setShowModalDeleteTenant(false);
    window.location.reload()
  };

  const handleDeleteTenanat = () => {

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const idTenant = selectedTenant.user
    try {
      serverUrl
        //send the tenant details using the following endpoint
        .delete(`/users/users-details/${idTenant}/`, config)
        .then((res) => {
          toast.success("Tenant Sucessfully Deleted")
          handleCloseModalReloadTenantDelete()
        })
        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.data.error);
          } else {
            console.log(error)
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }


  };
  // if the data is loading show a message
  if (isLoading) {
    return <div>Loading.....</div>
  }
  return (

    <div className='addTenant'>
      <div className='d-flex justify-content-'>
        <Link to={`/`} className='addHouse'><i className='bi bi-arrow-left'></i></Link>
        <h2></h2>

      </div>
      {tenantLength === 0 ?
        <>
          <div className='d-flex justify-content-between'>
            <p></p>
            <h2>Add Tenant</h2>

            <Button className='btn btn-success border-0 text-white' onClick={handleOpenModal}>Add Tenant</Button>
            {/* modal for adding a new tenant */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Tenant</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <Form onSubmit={handleSubmitTenanat}>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="full_name"
                        value={formDataTenant.full_name}
                        onChange={handleChangeTenant}
                        placeholder={"Tenant Name"}
                        required
                      />
                    </div>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="phone_number"
                        value={formDataTenant.phone_number}
                        onChange={handleChangeTenant}
                        placeholder={"Tenant Phone Number"}
                        required
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        paddingRight: "20px",
                        float: "right",
                      }}
                    >
                      {digitCount}/10
                    </p>
                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form> </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalReload} >
                  DONE
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <p className='noTenantAlert' >No tenant kindly add one</p>
        </>

        :
        <>
          <div className='d-flex justify-content-between mb-2'>
            <p></p>
            <h2>TENANTS</h2>
            <Button className='btn btn-sm btn-success border-0 text-white' onClick={handleOpenModal}>Add Tenant</Button>

            {/* modal for adding a new tenant */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Tenant</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <Form onSubmit={handleSubmitTenanat}>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="full_name"
                        value={formDataTenant.full_name}
                        onChange={handleChangeTenant}
                        placeholder={"Tenant Name"}
                        required
                      />
                    </div>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="phone_number"
                        value={formDataTenant.phone_number}
                        onChange={handleChangeTenant}
                        placeholder={"Tenant Phone Number"}
                        required
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "12px",
                        paddingRight: "20px",
                        float: "right",
                      }}
                    >
                      {digitCount}/10
                    </p>
                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form> </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalReload} >
                  DONE
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <Table className='table'>
            <thead className='tableThead'>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Add Tenant</th>
              <th>Remove Tenant</th>
              <th>Call Tenant</th>
            </thead>
            <tbody>

              {tenantsTable.map((tenant, index) =>
                <tr key={tenant.id}>
                  <td>{tenants[tenant.user].fullName}</td>
                  <td>{tenants[tenant.user].phoneNumber}</td>
                  <td><Button tenantId={tenant.id} className='btn btn-warning border-0 text-white' onClick={() => handleOpenSaveTenant(tenant)}>Add</Button></td>
                  {/* add tenant modal */}
                  <Modal show={showModalSaveTenant} onHide={handleCloseModalSaveTenant}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Tenant To House.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to add the tenant to this house?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="success" onClick={handleCloseModalSaveTenant}>
                        No
                      </Button>
                      <Button tenantId={tenant.id} variant="danger" onClick={handleAddTenanat}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>


                  <td><Button className='btn btn-danger border-0 text-white' onClick={() => handleOpenDeleteTenant(tenant)}>Remove</Button></td>
                  {/* delete tenant */}
                  <Modal show={showModalDeleteTenant} onHide={handleCloseModalDeleteTenant}>
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Tenant.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to delete this?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="success" onClick={handleCloseModalDeleteTenant}>
                        No
                      </Button>
                      <Button tenantId={tenant.id} variant="danger" onClick={handleDeleteTenanat}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <td><Button className='btn btn-success border-0 text-white' >Call</Button></td>
                </tr>
              )}
            </tbody>
          </Table>
        </>

      }

    </div>

  )
}

export default AddTenant